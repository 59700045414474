import React, { PropsWithChildren, ReactElement, useState } from "react";
import styles from "./ATSPhoneInput.module.scss";
import { PhoneNumFormatter } from "./phonenumberinfo";
import ATSErrorMsg from "../ATSErrorMsg";

interface IPhoneNumberInputProps {
    name: string;
    label?: string;
    required?: boolean;
    value?: string;
    className?: string;
    placeholder?: string;
    error?: Array<string>;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange: (name: string, value: string) => void;
    maxLengthProps?: number;
}

const ATSPhoneInput = (props: PropsWithChildren<IPhoneNumberInputProps>): ReactElement => {
    const formatter = new PhoneNumFormatter();

    const { name, required, label, value, className, placeholder, handleChange, error, setErrorMessage, maxLengthProps } = props;
    const [inputValue, setinputValue] = useState<string>(value ? formatter.format(value) : "");

    let skipFormat = false;

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // -- Prevent formatting the phone number
        skipFormat = e.key === "Backspace";
    };

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skipFormat) {
            setinputValue(e.target.value);
            return;
        }

        let formattedValue = formatter.format(e.target.value);

        setinputValue(formattedValue);
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "" && required) {
            setErrorMessage && setErrorMessage(name, [`This field is mandatory and you did not provide a value.`]);
            return;
        }
        if (e.target.value.length !== 14 && required) setErrorMessage && setErrorMessage(name, [`Your phone number is too short.`]);
    };

    const handleInputChange = e => {
        matchHandler(e);
        setErrorMessage && setErrorMessage(name, []);
        let dataBaseValue = e.target.value.replaceAll(" ", "").replace("(", "").replace(")", "").replace("-", ""); // remove all non number values and push it to state
        if (!(dataBaseValue.length > 10)) handleChange(name, dataBaseValue);
    };

    return (
        <>
            <div className={`form_item ${styles.phoneNumberWrapper}  ${error && error?.length > 0 ? "danger" : ""} `}>
                {label && <label>{label}</label>}
                <input
                    type="text"
                    name={name}
                    id="phone"
                    className={`${className ? className : styles.phoneNumber}`}
                    onChange={e => handleInputChange(e)}
                    onKeyDown={e => onKeyPress(e)}
                    onBlur={e => blurHandler(e)}
                    placeholder={`${placeholder ? placeholder : "(xxx) xxx-xxxx"}`}
                    value={inputValue}
                    maxLength={maxLengthProps ?? 50}
                />
                {error && <ATSErrorMsg errors={error} />}
            </div>
        </>
    );
};

export default ATSPhoneInput;
