import { DatePicker, lightTheme, Provider } from "@adobe/react-spectrum";
import { DateValue, CalendarDate } from "@internationalized/date";
import { useEffect, useState } from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import styles from "./ATSDatePicker.module.scss";

interface IATSDatePickerProps {
    name: string;
    label?: string;
    placeholder?: string;
    value: string;
    handleChange: (name: string, value: string) => void;
    resetValues?: boolean;
    required?: boolean;
    error?: Array<string>;
    setErrorMessage?: (name: string, value: string[]) => void;
}

/**
 * Set new ATS DatePicker component.
 * @param name: string;
 * @param label: string; -> optional
 * @param placeholder: string; -> optional
 * @param handleChange: (name: string, value: string) => void;
 * @param value: string;
 * @param resetValues?: boolean; -> optional
 * @param required?: boolean; -> optional
 * @param error?: Array<string>; -> optional
   @param setErrorMessage?: (name: string, value: string[], _indexed?: string, _index?: number) => void; -> optional
 */

const ATSDatePicker = (atsDatePickerProps: IATSDatePickerProps) => {
    const { name, handleChange, value, resetValues, required, error, setErrorMessage } = atsDatePickerProps;
    const [valueState, setValueState] = useState<any | null>();

    const handleDateSelection = (value): void => {
        let formattedDate = "";
        if (value != null) formattedDate = `${value.year}/${value.month}/${value.day}`;
        handleChange(name, formattedDate);
        setValueState(value);
    };

    useEffect(() => {
        if (value.length > 0) {
            const date = value?.split("/");
            const newDate: DateValue = new CalendarDate(parseInt(date[0]), parseInt(date[1]), parseInt(date[2]));
            setValueState(newDate);
        }
    }, []);

    useEffect(() => {
        if (resetValues) {
            setValueState(null);
        }
    }, [resetValues]);

    useEffect(() => {
        if (required && (valueState === null || valueState === "")) {
            setErrorMessage && setErrorMessage(name, ["This field is mandatory, please select one option"]);
        } else if (required) setErrorMessage && setErrorMessage(name, []);
    }, [valueState]);

    return (
        <div className={`form_item ${styles.datepicker} ${error && error.length > 0 && error[0].length > 0 ? "danger" : ""}`}>
            <Provider theme={lightTheme} colorScheme="light" locale="en-US">
                <DatePicker aria-label={name} onChange={handleDateSelection} value={valueState} />
                {error && error.length > 0 && error[0].length > 0 && <ATSErrorMsg errors={error} />}
            </Provider>
        </div>
    );
};

export default ATSDatePicker;
