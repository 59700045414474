import { useEffect, useState } from "react";
import { DatePicker, lightTheme, Provider, TimeField } from "@adobe/react-spectrum";
import { today, getLocalTimeZone, DateValue, CalendarDate, Time } from "@internationalized/date";
import styles from "./InstanceDate.module.scss";
import ATSErrorMsg from "../../../../components/form/ATSErrorMsg";

export interface ICourseInstances {
    date?: string;
    startTime?: number;
    startTimeMinute?: number;
    endTime?: number;
    endTimeMinute?: number;
}

export interface ICourseInstancesProps {
    index: number;
    error?: Array<string>;
    required?: boolean;
    setErrorInstances?: (i: number, a: Array<string>) => void;
    handleData: (i: number, d: ICourseInstances) => void;
    parentInstance: ICourseInstances;
}

function InstanceDate(props: ICourseInstancesProps) {
    const { handleData, index, error, setErrorInstances, required } = props;
    const [date, setDate] = useState<any>(null);
    const [startHour, setStartHour] = useState<any>(null);
    const [endHour, setEndHour] = useState<any>(null);
    const [courseInstance, setCourseInstance] = useState<ICourseInstances | any>();

    useEffect(() => {
        if (props.parentInstance !== null && props.parentInstance.date !== null) {
            const date = props.parentInstance.date?.split("-");
            const { startTime, startTimeMinute, endTime, endTimeMinute } = props.parentInstance;
            if (date && date.length > 2) {
                const newDate: DateValue = new CalendarDate(parseInt(date[0]), parseInt(date[1]), parseInt(date[2]));
                setDate(newDate);
            }

            const newStartTime: Time = new Time(startTime, startTimeMinute);
            setStartHour(newStartTime);

            const newEndTime: Time = new Time(endTime, endTimeMinute);
            setEndHour(newEndTime);
        }
    }, [props.parentInstance]);

    useEffect(() => {
        if (!courseInstance) return;
        handleData(index, courseInstance);
        validateHourHandler();
    }, [courseInstance]);

    const todayDate = new Date();
    todayDate.setUTCHours(0, 0, 0, 0);

    const validateHourHandler = () => {
        if (!courseInstance || typeof courseInstance === "undefined") return;
        if (
            required &&
            (!courseInstance.date ||
                (!courseInstance.startTime && courseInstance.startTime !== 0) ||
                (!courseInstance.endTime && courseInstance.endTime !== 0) ||
                (!courseInstance.endTimeMinute && courseInstance.endTimeMinute !== 0) ||
                (!courseInstance.startTimeMinute && courseInstance.startTimeMinute !== 0))
        ) {
            setErrorInstances &&
                setErrorInstances(index, [
                    "These inputs are mandatory, please complete at least one. If you have more please complete it or delete unnecessary fields",
                ]);
        } else if (courseInstance.startTime > courseInstance.endTime) {
            setErrorInstances && setErrorInstances(index, ["The finish time must be later than start time"]);
        } else if (courseInstance.startTime === courseInstance.endTime && courseInstance.startTimeMinute >= courseInstance.endTimeMinute) {
            setErrorInstances && setErrorInstances(index, ["The 'finish hour' must be greater than 'start hour'"]);
        } else if (new Date(courseInstance.date).getTime() <= todayDate.getTime()) {
            setErrorInstances && setErrorInstances(index, ["The date must be grater than today"]);
        } else {
            setErrorInstances && setErrorInstances(index, []);
        }
    };

    const handleChange = (e, type) => {
        switch (type) {
            case "date":
                if (e === null) {
                    setCourseInstance(prev => {
                        const { date, ...rest } = prev;
                        return rest;
                    });
                } else {
                    setCourseInstance(prev => ({
                        ...prev,
                        date: `${e.year}-${e.month}-${e.day}`,
                    }));
                }
                break;
            case "start":
                if (e === null) {
                    setCourseInstance(prev => {
                        const { startTime, startTimeMinute, ...rest } = prev;
                        return rest;
                    });
                } else {
                    setCourseInstance(prev => ({
                        ...prev,
                        startTime: e.hour,
                        startTimeMinute: e.minute,
                    }));
                }

                break;
            case "finish":
                if (e === null) {
                    setCourseInstance(prev => {
                        const { endTime, endTimeMinute, ...rest } = prev;
                        return rest;
                    });
                } else {
                    setCourseInstance(prev => ({
                        ...prev,
                        endTime: e.hour,
                        endTimeMinute: e.minute,
                    }));
                }
                break;
        }
    };

    return (
        <div className={`form_item picker ${error && error?.length > 0 ? "danger" : ""} `}>
            <Provider theme={lightTheme} colorScheme="light" locale="en-US">
                <DatePicker
                    aria-label="Date"
                    value={date}
                    onChange={e => {
                        handleChange(e, "date");
                        setDate(e);
                    }}
                    minValue={today(getLocalTimeZone())}
                />
                <TimeField
                    aria-label="Start-date"
                    value={startHour}
                    onChange={e => {
                        handleChange(e, "start");
                        setStartHour(e);
                    }}
                />
                <TimeField
                    aria-label="End-date"
                    value={endHour}
                    onChange={e => {
                        handleChange(e, "finish");
                        setEndHour(e);
                    }}
                />

            </Provider>
            {error && <ATSErrorMsg errors={error} />}
        </div>
    );
}

export default InstanceDate;
