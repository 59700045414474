import { ReactElement } from "react";
import ATSButton, { IATSButtons } from "../ATSButton";
import styles from "./ATSInfo.module.scss";

interface IATSInfoProps {
    type: ATSInfoType;
    content: string;
    title?: string;
    buttons?: Array<IATSButtons>;
}

export enum ATSInfoType {
    DEFAULT = "cool",
    ERROR = "danger",
    SUCCESS = "success",
    WARNING = "warning",
}

const iconType = {
    cool: "info",
    danger: "error",
    success: "verified",
    warning: "warning",
};

/**
 * Create ATS Info component.
 * @param type: ATSInfoType;
 * @param content: string;
 * @param title?: string;
 */
function ATSInfo(props: IATSInfoProps) {
    const { type, content, title } = props;
    return (
        <article className={`${styles.info} ${type}`}>
            <i className={`icon-${iconType[type]}`}></i>
            <div className={styles.wrapper}>
                <div className={styles.textWrapper}>
                    <h4 className={styles.info_title}>{title}</h4>
                    <p className={styles.info_text}>{content}</p>
                </div>
                <div className={styles.buttonWrapper}>
                    {props.buttons && props.buttons.map(button => <ATSButton {...{ ...button }} key={button.cta} />)}
                </div>
            </div>
        </article>
    );
}

export default ATSInfo;
