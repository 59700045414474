import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { CourseDropdownCheckOutResponse, CourseInstanceDropdownCheckOutRequest, CourseInstanceDropdownCheckOutResponse, LocationDropdownCheckOutRequest, LocationDropdownCheckOutResponse } from "../interfaces/ICheckout";

class CheckoutService extends ApiBaseService {
    public static async getCourses(): Promise<IResponse<CourseDropdownCheckOutResponse>> {
        const response = await this.securedGet<CourseDropdownCheckOutResponse>(`checkout/dropdownadmincourses`);
        return response;

    }

    public static async getLocations(body: LocationDropdownCheckOutRequest): Promise<IResponse<LocationDropdownCheckOutResponse>> {
        const response = await this.securedPost<LocationDropdownCheckOutResponse>(`checkout/dropdownadminlocations`, body);
        return response;

    }

    public static async getCourseInstances(body: CourseInstanceDropdownCheckOutRequest): Promise<IResponse<CourseInstanceDropdownCheckOutResponse>> {
        const response = await this.securedPost<CourseInstanceDropdownCheckOutResponse>(`checkout/dropdownadmincourseinstances`, body);
        return response;

    }
}

export default CheckoutService;


