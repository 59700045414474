import { ReactElement, useEffect, useRef, useState } from "react";
import useEventListener from "../../../hooks/useEventListener";
import ATSErrorMsg from "../ATSErrorMsg";
import ATSWarningMsg from "../ATSWarningMsg";
import styles from "./ATSSelect.module.scss";

interface IATSSelectProps {
    name: string;
    label: string;
    options: Array<SelectOption>;
    placeholder: string;
    error?: Array<string>;
    selected?: string;
    required?: boolean;
    setErrorMessage?: (name: string, value: string[], _indexed?: string, _index?: number) => void;
    handleChange: (name: string, value: number | string) => void;
    resetValues?: boolean;
    disabled?: boolean;
    value?: string;
}

export interface SelectOption {
    label: string;
    value: number | string;
    default?: boolean;
}
/**
 * Create custom select, receive an array of options were also you can set a default option
 * @param name string ;
 * @param label string ;
 * @param options object -> { label : string , value: number, default : boolean};
 * @param placeholder string ;
 * @param required boolean; -> Activate error on click outside withot value
 * @param disabled boolean; -> Disable
 * @param error Array<string>; -> optional
 * @param resetValues boolean-> Reset ATS input
 * @param setErrorMessage ( )=> void -> setState function that recieves a name and value;
 * @param handleChange ( )=> void -> setState function that recieves a name and value;
 * If you have only ONE valor in your options, please set it on "default" because the select component set it and disable interactions
 */
const ATSSelect = (props: IATSSelectProps): ReactElement => {
    const { name, options, placeholder, label, handleChange, selected, required, error, setErrorMessage, disabled, resetValues, value } = props;
    const [selectedOption, setSelectedOption] = useState<string>(selected ?? "");
    const [optionsVisible, setOptionsVisibility] = useState<boolean>(false);
    const [warningMessage, setWarningMessage] = useState<string>();
    const prevVisibleRef = useRef<boolean>();
    const customSelect = useRef<HTMLDivElement>(null as any);

    useEffect(() => {
        if (resetValues && customSelect.current) {
            setSelectedOption("");
        }
    }, [resetValues]);

    useEffect(() => {
        if (value) {
            let option = options.find(x => x.value === +value);
            setSelectedOption(option ? option.label : "");
        }
    }, []);

    const handleSelection = (option: SelectOption): void => {
        setSelectedOption(option.label);
        setOptionsVisibility(!optionsVisible);
        handleChange(name, option.value);
    };

    const handleClickOutside = (e: PointerEvent): void => {
        if (customSelect?.current && customSelect?.current.contains(e.target as Node)) return;
        prevVisibleRef.current = optionsVisible;
        setOptionsVisibility(false);
    };

    useEffect(() => {
        if (optionsVisible) setErrorMessage && setErrorMessage(name, []);
        if (!optionsVisible && prevVisibleRef.current === true && selectedOption === "" && required) {
            setErrorMessage && setErrorMessage(name, ["This field is mandatory, please select one option"]);
        }
    }, [optionsVisible]);

    useEffect(() => {
        const currentOption = props.options.find(x => x.default);
        if (currentOption) {
            handleChange(name, currentOption.value);
            setSelectedOption(currentOption.label);
        }
        if (options.length === 1) setWarningMessage("Only option available");
    }, [options]);

    useEventListener("pointerdown", handleClickOutside);

    return (
        <div ref={customSelect} className={`form_item ${styles.customSelect}  ${error && error?.length > 0 ? "danger" : ""} `}>
            <label>{label}</label>
            <button
                type="button"
                className={`${disabled || options.length <= 1 ? styles.disabled : ""} ${optionsVisible ? styles.toggled : ""} `}
                onClick={() => setOptionsVisibility(!optionsVisible)}>
                <span>{selectedOption || placeholder}</span>

                {options.length !== 1 && <i className="icon-expand_more"></i>}
            </button>

            {optionsVisible && (
                <ul className={`${options?.length > 5 && styles.scroll} ${styles.customSelect_Options}`}>
                    {options.map((option: SelectOption, i: number) => (
                        <li key={i}>
                            <button type="button" className={""} onClick={() => handleSelection(option)}>
                                <span className={""}>{option.label}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
            {error && <ATSErrorMsg errors={error} />}
            {warningMessage && <ATSWarningMsg setWarnings={setWarningMessage} warnings={[`${warningMessage}`]} />}
        </div>
    );
};

export default ATSSelect;
