import ATSTooltip, { ATSTooltipAlignment, ATSTooltipBodyAlignment } from "../../../../components/Shared/ATSTooltip";
import { timeConvert } from "../../../../helpers/helpers";
import styles from "./UpcomingCard.module.scss";

function UpcomingCard(props: any) {
    const { courseName, date, location, minimumSeats, occupiedSeats, approvedStudents, pendingStudents, startTime, startTimeMinute } = props;

    //#region Time
    let time: string;

    if (startTime.toString().length > 1) {
        time = `${startTime}:`;
    } else time = `0${startTime}:`;

    if (typeof time !== "undefined" || time !== "") {
        if (startTimeMinute.toString().length > 1) time = time + startTimeMinute;
        else time = time + `0${startTimeMinute}`;
    }
    //#endregion

    //#region tooltip
    let tooltip: boolean = false;
    tooltip = minimumSeats > 0 && approvedStudents < minimumSeats;
    //#endregion
    return (
        <article className={styles.courseCard}>
            <div className={styles.courseCard_stats}>
                <h2 className={styles.courseCard_stats_pending}>
                    <strong>{pendingStudents}</strong>
                    <span>Pending students</span>
                </h2>
                <p className={styles.courseCard_stats_info}>
                    <strong> {approvedStudents} </strong>
                    approved students
                </p>
            </div>
            <div className={styles.courseCard_info}>
                <div>
                    <h6 className="form_label">Course</h6>
                    <p>{courseName}</p>
                </div>
                <div>
                    <h6 className="form_label">Location</h6>
                    <p>{location}</p>
                </div>
                <div>
                    <h6 className="form_label">Time</h6>
                    <p>{timeConvert(time)}</p>
                </div>
            </div>
            {tooltip && (
                <i className={`icon-info cool ${styles.courseCard_tooltip} hasTooltip withHover`}>
                    <ATSTooltip
                        alignment={ATSTooltipAlignment.BOTTOMLEFT}
                        body={[{ text: `Min of ${minimumSeats} seats not met`, alignment: ATSTooltipBodyAlignment.LEFT }]}
                    />
                </i>
            )}
        </article>
    );
}

export default UpcomingCard;
