import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { ILogin, ILoginRequest } from "../interfaces/ILogin";

class AuthService extends ApiBaseService {
    public static async login({ userName, password }: ILoginRequest): Promise<IResponse<ILogin>> {
        let res = await this.unsecuredPost<ILogin>(`user/login`, { userName, password });

        if (res.success) {
            localStorage.setItem("jwt-token", res.response.token);
        } else {
            localStorage.setItem("jwt-token", "");
        }

        return res;
    }
}

export default AuthService;