import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { IStudent } from "../../../services/interfaces/IStudent";
import { SelectOption } from "../../../components/form/ATSSelect";
import { IStudentsStatusResponseItem } from "../../../services/interfaces/serviceResponses/student/IStudentsStatusCountResponse";
import { IResponse } from "../../../services/apiServices/ApiBaseService";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import ATSFilter, { ATSFilterType, ATSSearchColumns } from "../../../components/Shared/ATSFilter";
import Layout from "../../../components/Layout";
import ATSVirtualList, { ATSVirtualTableHeight } from "../../../components/Shared/ATSVirtualList";
import StudentService from "../../../services/entitiesServices/StudentService";
import ATSRequestsStatus from "../../../components/Shared/ATSRequestsStatusModule";
import CourseService from "../../../services/entitiesServices/CourseService";
import useQueryParams from "../../../hooks/useQueryParams";

export default function StudentList(): ReactElement {
    const navigate = useNavigate();
    const columnsToIgnore = ["id", "startTimeMinute", "courseId"];
    const queryParams = useQueryParams();
    const location = useLocation();

    const sortColumns = ["studentName", "statusStudentName", "dealerName", "courseName", "date"];
    const ignoreRequests = ["Refunded"];

    const [students, setStudents] = useState<IStudent[]>([]);
    const [gridFilter, setGridFilter] = useState<any>({ limit: 100, offset: 0 });
    const [filterObject, setFilterObject] = useState<any>([]);
    const [noDataFound, setNoDataFound] = useState<boolean>(false);
    const [clearValues, setClearValues] = useState<boolean>(false);
    const [statusRequests, setStatusRequests] = useState<IStudentsStatusResponseItem[]>();
    const [checked, setChecked] = useState<number[]>([]);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [tableHeight, setTableHeight] = useState<number>(ATSVirtualTableHeight.NORMAL);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);

    const [errorDates, setErrorDates] = useState<any>([""]);

    const filtersInit = [
        {
            type: ATSFilterType.TEXT,
            columns: ATSSearchColumns.STUDENTNAME,
            placeholder: "Search by name",
            name: "studentName",
            value: "",
            column: "search",
        },
        {
            type: ATSFilterType.TEXT,
            columns: ATSSearchColumns.DEALERNAME,
            placeholder: "Search by dealer",
            name: "dealerName",
            value: "",
            column: "searchDealer",
        },
        {
            type: ATSFilterType.SELECT,
            options: [] as SelectOption[],
            placeholder: "Status",
            name: "studentStatusId",
            value: "",
        },
        {
            type: ATSFilterType.SELECT,
            options: [] as SelectOption[],
            placeholder: "Courses",
            name: "courseId",
            value: "",
        },
        {
            type: ATSFilterType.DATEPICKER,
            name: "startDateFrom",
            columns: [ATSSearchColumns.STARTDATE],
            placeholder: "mm/dd/yyyy",
            value: "",
            errorDates: errorDates,
        },
        {
            type: ATSFilterType.DATEPICKER,
            name: "startDateTo",
            columns: [ATSSearchColumns.STARTDATE],
            placeholder: "mm/dd/yyyy",
            value: "",
            errorDates: errorDates,
        },
    ];

    useEffect(() => {
        initFilters();
    }, [queryParams]);

    useEffect(() => {
        if (filterObject.length > 0) {
            if (checkFilterDates()) {
                setErrorDates([""]);
                fetchData();
                for (let filter in gridFilter) {
                    let filterValue = gridFilter[filter];
                    if (filter !== "offset" && filter !== "limit" && filterValue) {
                        queryParams.set(filter, filterValue);
                    } else if (filterValue === "") {
                        queryParams.delete(filter);
                    }
                }

                if (queryParams.toString().length > 0) {
                    window.history.replaceState({}, "", `${location.pathname}?${queryParams.toString()}`);
                } else {
                    window.history.replaceState({}, "", `${location.pathname}`);
                }
            } else {
                setErrorDates(["The start date must not be greater than the end date of the range"]);
                /* studentsListModals.dateError.bodyText = ["The start date must not be greater than the end date of the range"];
                setModalConfig(studentsListModals.dateError);
                setToggleModal(true); */
            }
        }
    }, [gridFilter]);

    function checkFilterDates() {
        const filterDates = Object.keys(gridFilter).filter(filter => filter === "startDateFrom" || filter === "startDateTo");
        if (filterDates.length > 1) {
            const dateFrom = new Date(gridFilter["startDateFrom"]);
            const dateTo = new Date(gridFilter["startDateTo"]);
            if (dateFrom > dateTo) {
                return false;
            }
        }
        return true;
    }

    async function fetchData() {
        try {
            console.log("Fetching... ");
            if (students.length === 0) setIsLoading(true);
            const res = await StudentService.getStudentsFiltered(gridFilter);
            if (res && res.success && res.response.students.length > 0) {
                setNoDataFound(false);
                setStudents([...students, ...res.response.students]);
            } else {
                res.httpStatusCode && console.error(res.httpStatusCode, res.message);
                if (res.httpStatusCode === 500) {
                    setModalConfig(studentsListModals.unexpectedError);
                    setToggleModal(true);
                }
                if (res.message.length > 0) {
                    studentsListModals.fetchError.bodyText = [res.message];
                    setModalConfig(studentsListModals.fetchError);
                    setToggleModal(true);
                }
                setNoDataFound(true);
            }
            setIsLoading(false);
            setClearValues(false);
            setChecked([]);
        } catch (err) {
            console.log(err);
        }
    }

    async function fetchMore() {
        try {
            if (students.length % 100 === 0) {
                setGridFilter({ ...gridFilter, offset: gridFilter.offset + gridFilter.limit });
            } else {
                console.log("Max elements reached.");
            }
        } catch (err) {

        }
    }

    async function initFilters() {
        let studentStatus: SelectOption[] = [];
        let course: SelectOption[] = [];

        setLayoutLoading(true);

        await Promise.allSettled([
            StudentService.getStudentsStatus().then(function (resp) {
                if (resp && resp.success) {
                    studentStatus = resp.response.items.map(status => ({ value: status.id, label: status.name }));
                }
            }),
            CourseService.getCourseTypes().then(function (resp) {
                if (resp && resp.success) {
                    course = resp.response.items.map(courseType => ({ value: courseType.id, label: courseType.name }));
                }
            }),
            StudentService.getStudentsStatusCount().then(parseStatusRequests),
        ]);

        filtersInit.forEach(e => {
            if (e.name === "studentStatusId") {
                e.options = studentStatus;
            }
            if (e.name === "courseId") {
                e.options = course;
            }
            if (e.name === "startDateFrom" && queryParams.has("startDateFrom")) {
                e.value = queryParams.get("startDateFrom") || "";
            }
            if (e.name === "startDateTo" && queryParams.has("startDateTo")) {
                e.value = queryParams.get("startDateTo") || "";
            }
            if (e.name === "studentStatusId" && queryParams.has("studentStatusId")) {
                e.value = queryParams.get("studentStatusId") || "";
            }
            if (e.name === "courseId" && queryParams.has("courseId")) {
                e.value = queryParams.get("courseId") || "";
            }
            if (e.column === "search" && queryParams.has("search")) {
                e.value = queryParams.get("search") || "";
            }
            if (e.column === "searchDealer" && queryParams.has("searchDealer")) {
                e.value = queryParams.get("searchDealer") || "";
            }
        });

        if (queryParams.has("orderBy") && queryParams.has("descending")) {
            const order = queryParams.get("descending");
            handleSort(queryParams.get("orderBy") ?? "", order === "true" ? true : false);
        }

        setLayoutLoading(false);
        setFilterObject(filtersInit);

        //Init Filter with params
        let filtersApplied = {};
        let allowedFilters = filtersInit.map(e => e["column"] || e["name"]);

        queryParams.forEach((value, key) => {
            if (allowedFilters.indexOf(key) > -1) {
                filtersApplied[key] = value;
            }
        });

        setGridFilter(prev => ({
            ...prev,
            ...filtersApplied,
        }));
    }

    function handleFilter(filtersApplied: any) {
        setStudents([]);
        setGridFilter(prev => ({
            ...prev,
            ...filtersApplied,
        }));
    }

    function handleSort(column: string, order: boolean) {

        queryParams.set("descending", order.toString());
        setStudents([]);
        setGridFilter(prev => ({
            ...prev,
            orderBy: column,
            descending: order,
        }));
        setChecked([]);
    }

    function handleClearFilter() {
        setGridFilter({ limit: 100, offset: 0 });

        let queryParamsKeys = new URLSearchParams(queryParams.toString()).keys();
        for (let ind of queryParamsKeys) {
            queryParams.delete(ind);
        }
        setStudents([]);

        setClearValues(true);
    }

    function onRowClickHandler(event, id) {
        navigate(`./${id}`);
    }

    function closeModalAndReload() {
        StudentService.getStudentsStatusCount().then(parseStatusRequests);
        setToggleModal(false);
        setIsLoading(true);
        setStudents([]);
        fetchData();
    }

    function parseStatusRequests(response: IResponse<IStudentsStatusResponseItem[]>) {
        let statusRequests: IStudentsStatusResponseItem[] = [];
        if (response && response.success) {
            let resp = response.response;
            resp.forEach(e => {
                if (ignoreRequests.indexOf(e.status)) {
                    statusRequests.push(e);
                }
            });
            setStatusRequests(statusRequests);
        } else {
            console.log(response.message);
        }
    }

    function preDownloading() {
        setModalConfig(studentsListModals.loading);
        setToggleModal(true);
    }

    const handleCheck = event => {
        let updatedList = [...checked];
        if (event.target.checked && updatedList.indexOf(+event.target.value) === -1) {
            updatedList = [...checked, +event.target.value];
        } else if (!event.target.checked) {
            updatedList.splice(checked.indexOf(+event.target.value), 1);
        }
        setChecked(updatedList);
    };

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrorDates(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const okButtonModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            action: closeModalAndReload,
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const buttons: Array<IATSButtons> = [
        {
            cta: "Enroll New",
            type: ATSTypeButton.OUTNEGATIVE,
            url: "enroll",
            iconClass: "icon-add",
        },
    ];

    const studentsListModals = {
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        loading: {
            tilte: "Loading...",
            type: ATSTypeModal.LOADING,
            bodyText: ["Please wait ..."],
            noClose: true,
        },
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: ["Sorry there was an unexpected error while fetching the payment.", "If the problem persist call an administrator."],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
            buttons: buttonsErrorModal,
        },
        fetchError: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        /* dateError: {
            title: "Filter dates error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            buttons: backButtonModal,
            handleClose: () => {
                setToggleModal(false);
            },
        }, */
    };

    function toggleTableRowHeight() {
        if (tableHeight === ATSVirtualTableHeight.NORMAL) {
            setTableHeight(ATSVirtualTableHeight.COLLAPSED);
        } else {
            setTableHeight(ATSVirtualTableHeight.NORMAL);
        }

        const prevStudents = [...students];
        setStudents([...students, {} as IStudent]);
        setTimeout(() => setStudents(prevStudents));
    }

    return (
        <Layout title={"Students"} backHistory={false} {...{ buttons }} isLoading={layoutLoading}>
            <section className="layout_full">
                <div className="layout_full_head">
                    {statusRequests && statusRequests.length > 0 && <ATSRequestsStatus studentStatusCounts={statusRequests} collapse={true} />}
                    <div className="grid_row">
                        {filterObject && filterObject.length > 0 && (
                            <ATSFilter
                                filtersApplied={filterObject}
                                filter={gridFilter}
                                handleFilter={handleFilter}
                                handleClearFilter={handleClearFilter}
                                resetValues={clearValues}
                                errorDates={errorDates}
                                setErrorMessage={handleErrorsChange}
                            />
                        )}
                    </div>
                </div>
                <div className="layout_full_body">
                    {students && (
                        <ATSVirtualList
                            isLoading={isLoading}
                            dataSet={students}
                            onRowClickHandler={onRowClickHandler}
                            ignoreColumns={columnsToIgnore}
                            entity={"students"}
                            sortBy={sortColumns}
                            sortFunc={handleSort}
                            noData={noDataFound}
                            resetFilter={handleClearFilter}
                            handleSelected={handleCheck}
                            rowHeight={tableHeight}
                            fetchMore={fetchMore}
                        />
                    )}
                </div>
                {toggleModal && <ATSModal {...{ ...modalConfig }} />}
            </section>
        </Layout>
    );
}
