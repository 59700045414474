import ApiBaseService, { IResponse, IResponseForm } from "../apiServices/ApiBaseService";
import { IStudentsResponse } from "../interfaces/serviceResponses/student/IStudentsResponse";
import { ISingleStudentResponse } from "../interfaces/serviceResponses/student/ISingleStudentResponse";
import { IStudentStatusResponse } from "../interfaces/serviceResponses/student/IStudentsStatusResponse";
import { IStudentsStatusResponseItem } from "../interfaces/serviceResponses/student/IStudentsStatusCountResponse";
import { IStudentApproveResponse } from "../interfaces/serviceResponses/student/IStudentApproveResponse";
import { IStudentRejectResponse } from "../interfaces/serviceResponses/student/IStudentRejectResponse";
import { IStatusRejectRequest } from "../interfaces/servicePostBody/student/IStatusRejectRequest";
import { ISetStatusBatchResponse } from "../interfaces/serviceResponses/student/ISetStatusBatchResponse";
import { IRejectAllRequest } from "../interfaces/servicePostBody/student/IRejectAllRequest";
import { IApproveAllRequest } from "../interfaces/servicePostBody/student/IApproveAllRequest";
import { IStatusApproveRequest } from "../interfaces/servicePostBody/student/IStatusApproveRequest";
import { ICommentUpdateRequest } from "../interfaces/servicePostBody/student/ICommentUpdateRequest";
import { IStudentCommentUpdateResponse } from "../interfaces/serviceResponses/student/IStudentCommentUpdateResponse";
import { AddPaymentRequest, AddPaymentResponse } from "../interfaces/IPayments";
import { IEditStudentRequest } from "../interfaces/servicePostBody/student/IEditStudentRequest";
import { IEditStudentResponse } from "../interfaces/serviceResponses/student/IEditStudentResponse";
import { IStudentMoveResponse } from "../interfaces/serviceResponses/student/IStudentMoveResponse";
import { IStudentMoveRequest } from "../interfaces/servicePostBody/student/IStudentMoveRequest";
import { IRefundStudentRequest } from "../interfaces/servicePostBody/student/IStudentRefundRequest";
import { IStudentRefundResponse } from "../interfaces/serviceResponses/student/IStudentRefundResponse";

class StudentService extends ApiBaseService {
    public static async getStudents(limit, offset): Promise<IResponse<IStudentsResponse>> {
        const response = await this.securedPost<IStudentsResponse>(`student`, { limit, offset });
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async getStudentsFiltered(filtersApplied): Promise<IResponse<IStudentsResponse>> {
        const response = await this.securedPost<IStudentsResponse>(`student/filtered`, filtersApplied);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async getStudent(id): Promise<IResponse<ISingleStudentResponse>> {
        const response = await this.securedGet<ISingleStudentResponse>(`student/single/${id}`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async getStudentsStatus(): Promise<IResponse<IStudentStatusResponse>> {
        const response = await this.securedGet<IStudentStatusResponse>(`student-status`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async getStudentsStatusCount(): Promise<IResponse<IStudentsStatusResponseItem[]>> {
        const response = await this.securedGet<IStudentsStatusResponseItem[]>(`student/statuscount`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async setStatusApprove(studentId: IStatusApproveRequest): Promise<IResponse<IStudentApproveResponse>> {
        const response = await this.securedPost<IResponse<IStudentApproveResponse>>(`student/approve`, studentId);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async setStatusReject(body: IStatusRejectRequest): Promise<IResponse<IStudentRejectResponse>> {
        const response = await this.securedPost<IResponse<IStudentRejectResponse>>(`student/reject`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async cancelStudent(body: IStatusRejectRequest): Promise<IResponse<IStudentRejectResponse>> {
        const response = await this.securedPost<IResponse<IStudentRejectResponse>>(`student/cancel`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async setStatusApproveAll(studentsId: IApproveAllRequest): Promise<IResponse<ISetStatusBatchResponse>> {
        const response = await this.securedPost<IResponse<ISetStatusBatchResponse>>(`student/approveAll`, studentsId);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async setStatusRejectAll(body: IRejectAllRequest): Promise<IResponse<ISetStatusBatchResponse>> {
        const response = await this.securedPost<IResponse<ISetStatusBatchResponse>>(`student/rejectAll`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async updateComment(body: ICommentUpdateRequest): Promise<IResponse<IStudentCommentUpdateResponse>> {
        const response = await this.securedPost<IStudentCommentUpdateResponse>(`student/comments`, body);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async enrollStudents(body: AddPaymentRequest): Promise<IResponse<AddPaymentResponse>> {
        const response = await this.securedPost<AddPaymentResponse>(`payment/pay`, body);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async editStudent(body: IEditStudentRequest): Promise<IResponseForm<IEditStudentResponse>> {
        const response = await this.securedPost<IEditStudentResponse>(`student/edit`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async moveStudent(body: IStudentMoveRequest): Promise<IResponseForm<IStudentMoveResponse>> {
        const response = await this.securedPost<IStudentMoveResponse>(`student/move`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async refundStudent(body: IRefundStudentRequest): Promise<IResponse<IStudentRefundResponse>> {
        const response = await this.securedPost<IRefundStudentRequest>(`student/refund`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }
}

export default StudentService;
