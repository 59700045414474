import { ReactElement, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ISingleStudentResponse } from "../../../services/interfaces/serviceResponses/student/ISingleStudentResponse";
import { currencyDollarUS, formatComment, formatDate, formatDateFull, getStartTime, timeConvert } from "../../../helpers/helpers";
import { IStatusRejectRequest } from "../../../services/interfaces/servicePostBody/student/IStatusRejectRequest";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import ATSBadge, { ATSTypeBadge } from "../../../components/Shared/ATSBadge";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import Layout from "../../../components/Layout";
import StudentService from "../../../services/entitiesServices/StudentService";
import styles from "./SingleStudent.module.scss";
import { IStatusApproveRequest } from "../../../services/interfaces/servicePostBody/student/IStatusApproveRequest";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import { PhoneNumFormatter } from "../../../components/form/ATSPhoneInput/phonenumberinfo";
import ATSSpinner from "../../../components/Shared/ATSSpinner";

export enum ATSStudentStatus {
    APPROVE = 2,
    REJECT = 3,
}

const MANUALPAYMENTMETHOD = 3;

export default function SingleStudent(): ReactElement {
    const studentInit: ISingleStudentResponse = {
        id: 0,
        studentName: "",
        statusName: "",
        email: "",
        phone: "",
        courseName: "",
        courseInstanceLocation: "",
        startDate: "",
        endDate: "",
        startTime: 0,
        startTimeMinute: 0,
        dealerName: "",
        paymentToken: "",
        paymentDate: "",
        methodId: 0,
        methodName: "",
        manualPaymentComment: "",
        amount: 0,
        pricePerUnit: 0,
        seatsBought: 0,
        comments: "",
        rejectComment: "",
        refundReason: "",
        paymentCourseName: "",
        courseId: 0,
        courseInstanceId: 0,
        courseInstanceLocationId: 0,
        paymentId: 0,
    };
    const { studentId } = useParams();

    const [response, setResponse] = useState<ISingleStudentResponse>(studentInit);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(true);

    const phoneFormatter = new PhoneNumFormatter();

    useEffect(() => {
        loadStudent();
    }, []);

    const loadStudent = async () => {
        setLayoutLoading(true);
        const response = await StudentService.getStudent(studentId);
        if (response) setResponse(response.response);
        setLayoutLoading(false);
    };

    const handleCommentChange = async (value: string) => {
        setLayoutLoading(true);
        const res = await StudentService.updateComment({ studentId: response.id, comments: value });
        if (res && res.success) {
            loadStudent();
        } else {
            setLayoutLoading(false);
            singleStudentModals.error.bodyText = [res.response[0].message];
            setModalConfig(singleStudentModals.error);
            setToggleModal(true);
        }
    };

    async function approveStudent(studentId: IStatusApproveRequest) {
        setLayoutLoading(true);
        const response = await StudentService.setStatusApprove(studentId);
        if (response && response.success) {
            loadStudent();
        } else {
            setLayoutLoading(false);
            singleStudentModals.error.bodyText = [response.message];
            setModalConfig(singleStudentModals.error);
            setToggleModal(true);
        }
    }

    async function rejectStudent(body: IStatusRejectRequest) {
        setLayoutLoading(true);
        const response = await StudentService.setStatusReject(body);
        if (response && response.success) {
            loadStudent();
        } else {
            setLayoutLoading(false);
            singleStudentModals.error.bodyText = [response.message];
            setModalConfig(singleStudentModals.error);
            setToggleModal(true);
        }
    }

    async function cancelStudent(body: IStatusRejectRequest) {
        setLayoutLoading(true);
        const response = await StudentService.cancelStudent(body);
        if (response && response.success) {
            loadStudent();
        } else {
            setLayoutLoading(false);
            singleStudentModals.error.bodyText = [response.message];
            setModalConfig(singleStudentModals.error);
            setToggleModal(true);
        }
    }

    function closeModalAndReload() {
        setToggleModal(false);
        loadStudent();
    }

    const buttons: Array<IATSButtons> = [
        {
            cta: "Edit",
            type: ATSTypeButton.NEGATIVE,
            url: `/students/edit/${studentId}`,
            disabled: response.statusName !== "Approved" && response.statusName !== "Pending",
        },
        {
            cta: "Move",
            type: ATSTypeButton.OUTNEGATIVE,
            url: `/students/move/${studentId}`,
            disabled: response.statusName !== "Approved",
        },
    ];

    const okButtonModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            action: closeModalAndReload,
        },
    ];

    const confirmRejectModalButtons: Array<IATSButtons> = [
        {
            cta: "No",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
        {
            cta: "Yes",
            type: ATSTypeButton.SUCCESS,
            action: () => {
                setModalConfig(singleStudentModals.rejectStudent);
                setToggleModal(true);
            },
        },
    ];

    const confirmCancelModalButtons: Array<IATSButtons> = [
        {
            cta: "No",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
        {
            cta: "Yes",
            type: ATSTypeButton.SUCCESS,
            action: () => {
                setModalConfig(singleStudentModals.cancelStudent);
                setToggleModal(true);
            },
        },
    ];

    const singleStudentModals = {
        comment: {
            commentValue: response?.comments,
            type: ATSTypeModal.COMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                handleCommentChange(value);
                setToggleModal(false);
            },
        },
        confirmReject: {
            title: `Are you sure you want to reject selected student?`,
            bodyText: ["By performing this action the student will be rejected and their payments will be refunded."],
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            buttons: confirmRejectModalButtons,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        rejectStudent: {
            title: "Please, leave a reject reason.",
            type: ATSTypeModal.CANCELWITHCOMMENT,
            commentValue: response?.rejectComment,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                rejectStudent({ studentId: response.id, rejectComment: value });
                setToggleModal(false);
            },
        },
        confirmCancel: {
            title: `Are you sure you want to cancel selected student?`,
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            buttons: confirmCancelModalButtons,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        cancelStudent: {
            title: "Please leave a cancel reason.",
            type: ATSTypeModal.CANCELWITHCOMMENT,
            commentValue: response?.rejectComment,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                cancelStudent({ studentId: response.id, rejectComment: value });
                setToggleModal(false);
            },
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                setToggleModal(false);
            },
        },
    };

    const statusBadge = {
        Pending: <ATSBadge type={ATSTypeBadge.WARNING} content={"Pending"} />,
        Approved: <ATSBadge type={ATSTypeBadge.SUCCESS} content={"Approved"} />,
        Rejected: <ATSBadge type={ATSTypeBadge.DANGER} content={"Rejected"} />,
        Refunded: <ATSBadge type={ATSTypeBadge.DANGER} content={"Refunded"} />,
    };

    const studentStatus = {
        Approved: <i className={`icon-check_circle success`}></i>,
        Pending: <i className={`icon-pending_actions warning`}></i>,
        Rejected: <i className={`icon-cancel danger`}></i>,
        Refunded: <i className={`icon-undo danger`}></i>,
    };

    return (
        <Layout
            title={response ? response.studentName : ""}
            backHistory={true}
            buttons={buttons}
            badge={response && statusBadge[response.statusName]}
            isLoading={layoutLoading}>
            {response && !loading && (
                <div className={styles.layout}>
                    <div className={styles.layout_column}>
                        <>
                            {(response.comments && response.comments?.length > 0) ||
                            (response.rejectComment && response.rejectComment.length > 0) ||
                            (response.refundReason && response.refundReason?.length > 0) ? (
                                <div className="whitebox">
                                    <div className="whitebox_content">
                                        {response.comments?.length > 0 && (
                                            <>
                                                <h3 className="form_label">
                                                    Comments
                                                    <button
                                                        onClick={() => {
                                                            setModalConfig(singleStudentModals.comment);
                                                            setToggleModal(true);
                                                        }}>
                                                        <i className={`icon-edit cool ${styles.iconEditComment}`}></i>
                                                    </button>
                                                </h3>
                                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.comments) }} />
                                            </>
                                        )}
                                        {response.refundReason?.length > 0 && (
                                            <>
                                                <h3 className="form_label">Refund Reason</h3>
                                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.refundReason) }} />
                                            </>
                                        )}
                                        {response.rejectComment && response.rejectComment?.length > 0 && (
                                            <>
                                                <h3 className="form_label">Reject Comment</h3>
                                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.rejectComment) }} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                response.statusName !== "Rejected" &&
                                response.statusName !== "Refunded" && (
                                    <div className="whitebox centered">
                                        <ATSButton
                                            cta="Add comment"
                                            iconClass="icon-add cool"
                                            type={ATSTypeButton.FORM}
                                            action={() => {
                                                setModalConfig(singleStudentModals.comment);
                                                setToggleModal(true);
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </>
                        {response.manualPaymentComment && (
                            <div className="whitebox has_scroll">
                                <ATSInfoItem title={"Manual Payment Comment"} value={response.manualPaymentComment} />
                            </div>
                        )}
                        <div className="whitebox has_scroll">
                            <ul className="whitebox_list">
                                {response && <ATSInfoItem title={"NAME"} value={response.studentName} />}
                                {response && <ATSInfoItem title={"EMAIL"} value={response.email} />}
                                {response && <ATSInfoItem title={"PHONE NUMBER"} value={phoneFormatter.format(response.phone)} />}
                            </ul>
                        </div>
                    </div>

                    <div className={styles.layout_column}>
                        <article className={styles.card_status}>
                            {studentStatus[response.statusName]}
                            <div className={styles.card_status_info}>
                                <p className={styles.card_status_title}>status</p>
                                <h2 className={styles.card_status_value}>{response.statusName}</h2>
                            </div>
                            {response.statusName === "Pending" && (
                                <>
                                    <ATSButton
                                        cta="Approve"
                                        type={ATSTypeButton.SUCCESS}
                                        action={() => {
                                            if (studentId) approveStudent({ studentId: +studentId });
                                        }}
                                    />
                                    <ATSButton
                                        cta="Reject"
                                        type={ATSTypeButton.DANGER}
                                        action={() => {
                                            setModalConfig(singleStudentModals.confirmReject);
                                            setToggleModal(true);
                                        }}
                                    />
                                </>
                            )}
                            {response.statusName === "Approved" && response.methodId !== MANUALPAYMENTMETHOD && (
                                <NavLink to={`/students/${studentId}/refund`} state={{ response: response }}>
                                    Cancel & Refund
                                </NavLink>
                            )}
                            {response.statusName === "Approved" && response.methodId === MANUALPAYMENTMETHOD && (
                                <a
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        setModalConfig(singleStudentModals.confirmCancel);
                                        setToggleModal(true);
                                    }}>
                                    Cancel
                                </a>
                            )}
                        </article>
                        {response.courseName ? (
                            <div className="whitebox has_heading">
                                <div className="whitebox_heading">
                                    <i className="icon-menu_book"></i>
                                    <h2 className="whitebox_heading_title">Course information</h2>
                                </div>
                                <div className="whitebox_content">
                                    <ul className="whitebox_list inColumns">
                                        {response && (
                                            <>
                                                <ATSInfoItem title={"COURSE"} value={response.courseName} />
                                                <ATSInfoItem title={"LOCATION"} value={response.courseInstanceLocation} />
                                                <ATSInfoItem title={"START DATE"} value={formatDate(response.startDate)} />
                                                <ATSInfoItem
                                                    title={"TIME"}
                                                    value={timeConvert(getStartTime(response.startTime, response.startTimeMinute))}
                                                />
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="whitebox">
                                <span>No courses available</span>
                            </div>
                        )}

                        {response.paymentId ? (
                            <div className="whitebox has_heading">
                                <div className="whitebox_heading">
                                    <i className="icon-credit_card"></i>
                                    <h2 className="whitebox_heading_title">Payment information</h2>
                                </div>
                                <div className="whitebox_content">
                                    <ul className="whitebox_list inColumns">
                                        {response && (
                                            <>
                                                <ATSInfoItem
                                                    title={"TOKEN"}
                                                    value={response.paymentToken !== "" ? response.paymentToken : "MANUAL ENROLLMENT"}
                                                />
                                                <ATSInfoItem title={"PAID DATE"} value={formatDateFull(response.paymentDate)} />
                                                <ATSInfoItem title={"DEALER"} value={response.dealerName} />
                                                <ATSInfoItem title={"AMOUNT"} value={currencyDollarUS.format(response.amount)} />
                                                <ATSInfoItem
                                                    title={"DETAILS"}
                                                    value={`${response.seatsBought} x ${response.paymentCourseName}`}
                                                />
                                                <ATSInfoItem title={"PRICE"} value={currencyDollarUS.format(response.pricePerUnit)} />
                                                <ATSInfoItem title={"METHOD"} value={response.methodName} />
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="whitebox">
                                <span>No payments available</span>
                            </div>
                        )}
                    </div>
                    {toggleModal && <ATSModal {...{ ...modalConfig }} />}
                </div>
            )}
            {loading && <ATSSpinner title="Fetching student data" subtitle="Please wait..." />}
        </Layout>
    );
}
