import RadioCardComponent from './RadioCardComponent';
import SimpleRadioButtons from './SimpleRadioButtons';

interface IRadioProps {
    name: string;
    type: ATSRadioType;
    options: Array<RadioOption>;
    handleChange: (name: string, value: number) => void;
}

export interface RadioOption {
    label: string;
    value: number | string | boolean;
    classIcon?: string;
}

export enum ATSRadioType {
    CARD = 'card',
    DEFAULT = 'default'
}

/**
 * Create custom radio buttons, receive an array of options
 * @param name string ;
 * @param type ATSRadioType; -> set sub component style
 * @param options object -> { label : string , value: number, default : boolean};
 * @param handleChange ( )=> void -> setState function that recieves a name and value;
 */
function ATSRadioInput(props: IRadioProps) {
    const { type, name, options, handleChange } = props;

    const radioComponents = {
        card: function handler(option: RadioOption, i: number) {
            return (
                <RadioCardComponent {...{ option, handleChange, name }} key={`cardInput_${i}`} />
            );
        },
        default: function handler(option: RadioOption, i: number) {
            return (
                <SimpleRadioButtons {...{ option, handleChange, name }} key={`radioInput_${i}`} />
            );
        },
    };

    return (
        <div className="form_fieldset maxWidth row">
            {options.map((option: RadioOption, i: number) => (
                radioComponents[type](option, i)
            ))}
        </div>
    );
}

export default ATSRadioInput;