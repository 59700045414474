import { AxiosResponse } from "axios";
import { useDebugValue, useEffect, useRef, useState } from "react";
import { IResponse } from "../services/apiServices/ApiBaseService";

interface DownloadFileProps {
    readonly apiDefinition: () => Promise<IResponse<Blob>>;
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
    readonly getFileName: () => string;
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>;
    readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
    readonly name: string | undefined;
    readonly url: string | undefined;
}

export const useDownloadFile = ({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError,
    getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
    const ref = useRef<HTMLAnchorElement | null>(null);
    const [url, setFileUrl] = useState<any>();
    const [name, setFileName] = useState<string>();

    useEffect(() => {
        if (name) {
            ref.current?.click();
            URL.revokeObjectURL(url);
            postDownloading();
        }
    }, [name, url]);

    const download = async () => {
        try {
            preDownloading();
            const response = await apiDefinition();
            const url = URL.createObjectURL(new Blob([response as any], {type: 'text/csv;charset=utf-8'}));
            setFileUrl(url);
            setFileName(getFileName());
        } catch (error) {
            onError();
        }
    };

    return { download, ref, url, name };
};
