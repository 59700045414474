import { ReactElement, useEffect, useState } from "react";
import { IDashboardDataResponse } from "../../services/interfaces/IDashboard";
import DashboardService from "../../services/entitiesServices/DashboardService";
import Layout from "../../components/Layout";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../components/Shared/ATSModal";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../components/Shared/ATSButton";
import ATSRequestsStatus from "../../components/Shared/ATSRequestsStatusModule";
import { IStudentsStatusResponseItem } from "../../services/interfaces/serviceResponses/student/IStudentsStatusCountResponse";
import styles from "./Dashboard.module.scss";
import { groupVals } from "../../helpers/helpers";
import UpcomingDate from "./UpcomingCourses/UpcomingDate";

export default function Dashboard(): ReactElement {
    const [dashboardData, setDashboardData] = useState<IDashboardDataResponse>();
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [unexpectedErrorModal, setUnexpectedErrorModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [layoutLoading, setLayoutLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        setLayoutLoading(true);
        const res = await DashboardService.getDashboardData(10);
        
        if (res && res.success) {
            const cleanStatus: IStudentsStatusResponseItem[] = [];
            res.response.studentStatusCount.forEach((x: IStudentsStatusResponseItem) => { if (x.status !== "Refunded") cleanStatus.push(x); });
            setDashboardData({
                ...res.response,
                studentStatusCount: cleanStatus
            });
        }
        else if (res && !res.success) {
            const cleanStatus: IStudentsStatusResponseItem[] = [
                { status: "Pending", quantity: 0, id: 0 },
                { status: "Approved", quantity: 0, id: 0 },
                { status: "Rejected", quantity: 0, id: 0 }
            ];
            setErrorMessage(res.message);
            if (res.httpStatusCode === 500) {
                setUnexpectedErrorModal(!unexpectedErrorModal);
            }
            else {
                setErrorModal(!errorModal);
            }
            setDashboardData({
                ...res.response,
                studentStatusCount: cleanStatus,
            });
        }
        setLayoutLoading(false);
    }

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setErrorModal(!errorModal)
        },
    ];

    const buttonsUnexpectedErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setUnexpectedErrorModal(!unexpectedErrorModal)
        },
    ];

    const actionButtons = [
        {
            cta: "Create New Course",
            type: ATSTypeButton.CARD,
            iconClass: 'icon-menu_book',
            url: '/courses/new'
        },
        {
            cta: "Enroll New Student",
            type: ATSTypeButton.CARD,
            iconClass: 'icon-school',
            url: '/students/enroll'
        },
        {
            cta: "Create New Dealer",
            type: ATSTypeButton.CARD,
            iconClass: 'icon-group',
            url: '/dealers/new'
        },
        {
            cta: "Generate New Report",
            type: ATSTypeButton.CARD,
            iconClass: 'icon-summarize',
            url: '/reports'
        },
    ];

    return (
        <Layout backHistory={false} title={""} noHeader={true} isLoading={layoutLoading}>
            <div className={styles.layout}>
                <div className={styles.layout_top}>
                    {dashboardData?.studentStatusCount && <ATSRequestsStatus studentStatusCounts={dashboardData?.studentStatusCount} />}
                </div>
                <div className={styles.layout_content}>
                    <div className={styles.layout_content_aside}>
                        {actionButtons.map((button: IATSButtons, i) => (
                            <ATSButton {...{ ...button }} key={`dashboard_btns_${i}`} />
                        ))}
                    </div>
                    <div className={`whitebox has_heading ${styles.layout_content_main}`}>
                        <div className="whitebox_heading">
                            <i className="icon-menu_book"></i>
                            <h1 className="whitebox_heading_title">Upcoming courses</h1>
                        </div>
                        <div className="whitebox_content">
                            {dashboardData &&
                                dashboardData.upcomingCourses.length > 0 &&
                                groupVals(dashboardData.upcomingCourses, "date").map((date, i) => (
                                    <UpcomingDate {...{ ...date }} key={`dashboard_upcoming_${i}`} />
                                ))}
                            {dashboardData &&
                                dashboardData.upcomingCourses.length === 0 &&
                                <div className="ats_table_alert">
                                    <div className="ats_table_alert_box">
                                        <h3 className="ats_table_alert_title">No results found</h3>
                                        <p className="ats_table_alert_text">We couldn’t find any items to show. </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {unexpectedErrorModal && (
                    <ATSModal
                        title="ERROR"
                        icon="icon-error danger"
                        bodyText={[`Sorry it's looks like we had an error to fetch the data.`, `If this persists please contact with the admin`]}
                        type={ATSTypeModal.DEFAULT}
                        buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                        handleClose={() => setUnexpectedErrorModal(!unexpectedErrorModal)}
                        buttons={buttonsUnexpectedErrorModal}
                    />
                )}
                {errorModal && (
                    <ATSModal
                        title="ERROR"
                        icon="icon-error danger"
                        bodyText={[`${errorMessage}`]}
                        type={ATSTypeModal.DEFAULT}
                        buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                        handleClose={() => setErrorModal(!errorModal)}
                        buttons={buttonsErrorModal}
                    />
                )}
            </div>
        </Layout>
    );
}
