import { ReactElement, useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import ATSVirtualList from "../../../components/Shared/ATSVirtualList";
import DealerService from "../../../services/entitiesServices/DealerService";
import { useLocation, useNavigate } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { IDealer } from "../../../services/interfaces/IDealer";
import ATSFilter, { ATSFilterType, ATSSearchColumns } from "../../../components/Shared/ATSFilter";
import useQueryParams from "../../../hooks/useQueryParams";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";

export default function DealersList(): ReactElement {
    const navigate = useNavigate();
    const columnsToIgnore = ["id", "zip"];
    const sortColumns = ["name", "contactName"];
    const queryParams = useQueryParams();
    const location = useLocation();

    const [dealers, setDealers] = useState<IDealer[]>([]);
    const [noDataFound, setNoDataFound] = useState<boolean>(false);
    const [filterObject, setFilterObject] = useState<any>([]);
    const [gridFilter, setGridFilter] = useState<any>({ limit: 100, offset: 0 });
    const [clearValues, setClearValues] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);

    const filtersInit = [
        {
            type: ATSFilterType.TEXT,
            columns: ATSSearchColumns.DEALERNAME,
            placeholder: "Search by dealer",
            name: "dealerName",
            value: "",
            column: "search",
        },
    ];

    useEffect(() => {
        initFilters();
    }, [queryParams]);

    useEffect(() => {
        if (filterObject.length > 0) {
            fetchData();

            for (let filter in gridFilter) {
                let filterValue = gridFilter[filter];
                if (filter !== "offset" && filter !== "limit" && filterValue) {
                    queryParams.set(filter, filterValue);
                } else if (filterValue === "") {
                    queryParams.delete(filter);
                }
            }

            if (queryParams.toString().length > 0) {
                window.history.replaceState({}, "", `${location.pathname}?${queryParams.toString()}`);
            } else {
                window.history.replaceState({}, "", `${location.pathname}`);
            }
        }
    }, [gridFilter]);

    async function fetchData() {
        try {
            console.log("Fetching...");
            if (dealers.length === 0) setIsLoading(true);
            const res = await DealerService.getFilteredDealers(gridFilter);
            if (res && res.success && res.response.dealers.length > 0) {
                setNoDataFound(false);
                setDealers([...dealers, ...res.response.dealers]);
            } else {
                res.httpStatusCode && console.error(res.httpStatusCode, res.message);
                if (res.httpStatusCode === 500) {
                    setModalConfig(dealerListModals.unexpectedError);
                    setToggleModal(true);
                }
                if (res.message.length > 0) {
                    dealerListModals.error.bodyText = [res.message];
                    setModalConfig(dealerListModals.error);
                    setToggleModal(true);
                }

                setNoDataFound(true);
            }
            setClearValues(false);
            setIsLoading(false);
        } catch (err) {
            //do something
        }
    }

    async function fetchMore() {
        try {
            if (dealers.length % 100 === 0) {
                setGridFilter({ ...gridFilter, offset: gridFilter.offset + gridFilter.limit });
            } else {
                console.log("Max elements reached.");
            }
        } catch (err) { }
    }

    async function initFilters() {
        setLayoutLoading(true);

        filtersInit.forEach(e => {
            if (e.column === "search" && queryParams.has("search")) {
                e.value = queryParams.get("search") || "";
            }
        });

        setFilterObject(filtersInit);

        //Init Filter with params
        let filtersApplied = {};
        let allowedFilters = filtersInit.map(e => e["column"] || e["name"]);

        queryParams.forEach((value, key) => {
            if (allowedFilters.indexOf(key) > -1) {
                filtersApplied[key] = value;
            }
        });

        setGridFilter(prev => ({
            ...prev,
            ...filtersApplied,
        }));

        if (queryParams.has("orderBy") && queryParams.has("descending")) {
            const order = queryParams.get("descending");
            handleSort(queryParams.get("orderBy") ?? "", order === "true" ? true : false);
        }

        setLayoutLoading(false);
    }

    function handleFilter(filtersApplied: any) {
        setDealers([]);
        setGridFilter(prev => ({
            ...prev,
            ...filtersApplied,
        }));
    }

    function handleSort(column: string, order: boolean) {
        setDealers([]);
        queryParams.set("descending", order.toString());
        setGridFilter(prev => ({
            ...prev,
            orderBy: column,
            descending: order,
        }));
    }

    function handleClearFilter() {
        setGridFilter({ limit: 100, offset: 0 });

        let queryParamsKeys = new URLSearchParams(queryParams.toString()).keys();
        for (let ind of queryParamsKeys) {
            queryParams.delete(ind);
        }
        setDealers([]);

        setClearValues(true);
    }

    function onRowClickHandler(event, id) {
        navigate(`./${id}`);
    }

    const buttons: Array<IATSButtons> = [
        {
            cta: "Create New",
            type: ATSTypeButton.OUTNEGATIVE,
            url: "new",
            iconClass: "icon-add",
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const dealerListModals = {
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: ["Sorry there was an unexpected error while fetching the payment.", "If the problem persist call an administrator."],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
            buttons: buttonsErrorModal,
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };

    return (
        <Layout title={"Dealers"} backHistory={false} {...{ buttons }} isLoading={layoutLoading}>
            <section className="layout_full">
                <div className="layout_full_head">
                    <div className="grid_row">
                        {filterObject && filterObject.length > 0 && (
                            <ATSFilter
                                filtersApplied={filterObject}
                                filter={gridFilter}
                                handleFilter={handleFilter}
                                handleClearFilter={handleClearFilter}
                                resetValues={clearValues}
                            />
                        )}
                    </div>
                </div>
                <div className="layout_full_body">
                    {dealers && (
                        <ATSVirtualList
                            isLoading={isLoading}
                            dataSet={dealers}
                            onRowClickHandler={onRowClickHandler}
                            ignoreColumns={columnsToIgnore}
                            entity={"dealers"}
                            sortBy={sortColumns}
                            sortFunc={handleSort}
                            noData={noDataFound}
                            resetFilter={handleClearFilter}
                            fetchMore={fetchMore}
                        />
                    )}
                </div>
            </section>
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}
