import React, { FormEvent, KeyboardEvent, useEffect, useState } from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import ATSWarningMsg from "../ATSWarningMsg";
import styles from "./ATSNumberInput.module.scss";
interface IATSNumberInput {
    name: string;
    label: string;
    value?: number;
    min?: number;
    max?: number;
    error?: Array<string>;
    warning?: string;
    setErrorMessage?: (name: string, value: string[], _indexed?: string, _index?: number) => void;
    handleChange: (name: string, value: number) => void;
    maxLengthProps?: number;
}

/**
 * Create custom number
 * @param name string ;
 * @param label string ;
 * @param value number;
 * @param min: number; -> optional
 * @param max: number; -> optional
 * @param error Array<string>;
 * @param setErrorMessage ( )=> void -> setState function that recieves a name and value;
 * @param handleChange ( )=> void -> setState function that recieves a name and value;
 * @param maxLengthProps number -> optional
 * If the user sets a number greater than the max property, the input takes the value of the max property and returns it
 */
function ATSNumberInput(props: IATSNumberInput) {
    const { name, label, min = 0, max, error, warning, value, setErrorMessage, handleChange, maxLengthProps } = props;
    const initialValue = () => {
        if (min) {
            return min;
        } else if (value) {
            return value;
        } else {
            return 0;
        }
    };

    const [inputValue, setinputValue] = useState<string>(`${initialValue()}`);
    const [warningMessage, setWarningMessage] = useState<string>(warning ?? "");

    const handleBlurInput = () => {
        //if the value isn't a number, set value in 0 and return without execute

        if (inputValue === "") {
            setinputValue(`${min ?? 0}`);
            setWarningMessage(
                `The minimum value required is ${min ?? 0} and you didn't complete the input so we are now using the minumum value required`
            );
            return;
        }

        if ((max || max === 0) && parseInt(inputValue) > max) {
            setWarningMessage(`The maximum value accepted is ${max ?? 0} set to total seats. We are now using the maximum value`);
            setinputValue(`${max}`);
            handleChange(name, max);
        } else if ((min || min === 0) && parseInt(inputValue) < min) {
            setWarningMessage(`The minimum value required is ${min ?? 0} so we are now using the minumum value required`);
            setinputValue(`${min}`);
            handleChange(name, min);
        } else {
            handleChange(name, parseInt(inputValue));
        }
    };

    const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
        setinputValue(e.currentTarget.value);
        setWarningMessage("");
        setErrorMessage && setErrorMessage(name, []);
    };

    const increaseValueHandler = e => {
        e.preventDefault();
        setErrorMessage && setErrorMessage(name, []);

        if (((max || max === 0) && parseInt(inputValue) < max) || !(max || max === 0)) setinputValue(`${parseInt(inputValue) + 1}`);
        handleChange(name, parseInt(inputValue) + 1);
    };

    const decreaseValueHandler = e => {
        e.preventDefault();
        setErrorMessage && setErrorMessage(name, []);

        if ((min || min === 0) && parseInt(inputValue) > min) {
            setinputValue(`${parseInt(inputValue) - 1}`);
            handleChange(name, parseInt(inputValue) - 1);
        }
    };

    useEffect(() => {
        if (!warning) return;
        setWarningMessage(warning);
    }, [warning]);

    return (
        <div className={`form_item ${styles.wrapper}  ${error && error?.length > 0 ? "danger" : ""} `}>
            <label>{label}</label>
            <div className={styles.wrapper_input}>
                <input
                    type="text"
                    pattern="[0-9]*"
                    value={inputValue}
                    name={name}
                    onKeyPress={(e: KeyboardEvent) => {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={e => handleInputChange(e)}
                    onBlur={handleBlurInput}
                    maxLength={maxLengthProps ?? 9}
                />
                <div className={styles.arrows}>
                    <button onClick={increaseValueHandler} disabled={max === parseInt(inputValue)}>
                        <i className="icon-expand_less"></i>
                    </button>
                    <button onClick={decreaseValueHandler} disabled={min === parseInt(inputValue) || parseInt(inputValue) === 0}>
                        <i className="icon-expand_more"></i>
                    </button>
                </div>
            </div>
            {warningMessage && <ATSWarningMsg setWarnings={setWarningMessage} warnings={[`${warningMessage}`]} />}
            {error && <ATSErrorMsg errors={error} />}
        </div>
    );
}

export default ATSNumberInput;
