import styles from './ATSBadge.module.scss';

export enum ATSTypeBadge {
  DEFAULT = 'cool',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning'
}

export interface IATSBadgeProps {
  type: ATSTypeBadge,
  content: string
}

/**
 * Create badge
 * @param content string ; 
 * @param type ATSTypeBadge;
 */
function ATSBadge(props: IATSBadgeProps) {
  const { content, type } = props

  return (
    <span className={`${styles.badge} ${type}`}>
      {content}
    </span>
  )
}

export default ATSBadge