import React from "react";
import { getStartTime, timeConvert } from "../../../../helpers/helpers";
import InfoItem from "./InfoItem";

function PurchaseInformation({ instance, dealer, seats }) {
    return (
        <div className="whitebox has_heading">
            <div className="whitebox_heading">
                <h3 className="whitebox_heading_title">Purchase Information</h3>
            </div>
            <div className="whitebox_content">
                <ul className="whitebox_list">
                    {instance && (
                        <>
                            <InfoItem title={"COURSE"} value={instance.courseName} />
                            <InfoItem title={"LOCATION"} value={instance.location} />
                            <InfoItem
                                title={"START DATE"}
                                value={`${new Date(instance.date).toLocaleDateString("en-US")} ${timeConvert(
                                    getStartTime(instance.startTime, instance.startTimeMinute)
                                )}`}
                            />
                            {dealer && <InfoItem title={"DEALER"} value={dealer} />}
                            {seats > 0 && <InfoItem title={"SEATS"} value={seats} />}
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default PurchaseInformation;
