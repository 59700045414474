import { markdownParser } from '../../../helpers/helpers';
import styles from './ATSTooltip.module.scss';

export interface IATSTootltipProps {
    iconClass?: string;
    title?: string;
    body: Array<IATSTooltipBody>;
    alignment: ATSTooltipAlignment;
}

interface IATSTooltipBody {
    text: string,
    alignment: ATSTooltipBodyAlignment;
}

export enum ATSTooltipAlignment {
    TOPRIGHT = 'top_right',
    TOPLEFT = 'top_left',
    BOTTOMRIGHT = 'bottom_right',
    BOTTOMLEFT = 'bottom_left',

}

export enum ATSTooltipBodyAlignment {
    CENTERED = 'center',
    LEFT = 'left',
    RIGHT = 'rigth'
}
/**
 * Create ATSTooltip 
 * @param title string;
 * @param iconClass string;
 * @param body Array<IATSTooltipBody>;
 * @param alignment ATSTooltipAlignment;
 * @returns JSX Tooltip box
 * 
 * This component returns a tooltip with multiple text configuration based on a string regex,
 * it's use a markdownParser function to setinnerHtml. Also you can configure the position of the texts
 * e.g 'centered' and the positioning of the component over the parent with 'alignment' property
 */
function ATSTooltip(props: IATSTootltipProps) {
    const { iconClass, title, body, alignment } = props;

    return (
        <div className={`${styles.tooltip} ${styles[alignment]}`}>
            { title && (
                <h3 className={styles.tooltip_heading}>
                    {iconClass && <i className={iconClass}></i>}
                    <span>
                        {title}
                    </span>
                </h3>
            )}
            {
                <div className={styles.tooltip_content}>
                    {body.map((line, i) => {
                        return (
                            <p dangerouslySetInnerHTML={{ __html: markdownParser(line.text) }} key={`tooltip_line_${i}`} className={styles[line.alignment]} />
                        );
                    })}
                </div>
            }
        </div>
    );
}

export default ATSTooltip;