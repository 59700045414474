import { ReactElement, useState } from "react";
import Layout from "../../components/Layout";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../components/Shared/ATSButton";
import ATSInfo, { ATSInfoType } from "../../components/Shared/ATSInfo";
import { ModalexampleUsageDefault, ModalexampleUsageComment, ModalexampleUsageCancelClass } from "../../components/Shared/ATSModal/example-usage";
import ATSRequestsStatus from "../../components/Shared/ATSRequestsStatusModule";
import ATSRequestsStatusCard from "../../components/Shared/ATSRequestsStatusModule/ATSRequestsStatusCard";
import ATSTooltip, { ATSTooltipAlignment, ATSTooltipBodyAlignment } from "../../components/Shared/ATSTooltip";
import ATSWidget, { ATSWidgetType } from "../../components/Shared/ATSWidget";
import ATSSpinner from "../../components/Shared/ATSSpinner";

export default function Playground(): ReactElement {

    const buttons: Array<IATSButtons> = [{
        cta: "Default",
        type: ATSTypeButton.DEFAULT,
    },
    {
        cta: "Form",
        iconClass: 'icon-add',
        type: ATSTypeButton.FORM,
    },
    {
        cta: "Positive",
        type: ATSTypeButton.POSITIVE,
    },
    {
        cta: "Out Positive",
        type: ATSTypeButton.OUTPOSITIVE,
    },
    {
        cta: "Negative",
        type: ATSTypeButton.NEGATIVE,
    },
    {
        cta: "Out Negative",
        type: ATSTypeButton.OUTNEGATIVE,
    },
    {
        cta: "Success",
        type: ATSTypeButton.SUCCESS,
    },
    {
        cta: "Out success",
        type: ATSTypeButton.OUTSUCCESS,
    },
    {
        cta: "Danger",
        type: ATSTypeButton.DANGER,
    },
    {
        cta: "Out Danger",
        type: ATSTypeButton.OUTDANGER,
    },];

    const requests: any = [{
        status: "Pending",
        quantity: 20,
    },
    {
        status: "Approved",
        quantity: 20,
    },
    {
        status: "Rejected",
        quantity: 20,
    }];

    const info = [{
        type: ATSInfoType.DEFAULT,
        content: 'something to test'
    },
    {
        type: ATSInfoType.WARNING,
        content: 'something to test warning'
    },
    {
        type: ATSInfoType.ERROR,
        content: 'something to test'
    },
    {
        type: ATSInfoType.SUCCESS,
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at cursus odio. Aliquam eleifend odio eget dui tincidunt, ut hendrerit purus pellentesque. Pellentesque in laoreet arcu.'
    }];

    const widgets = [{
        type: ATSWidgetType.DEFAULT,
        content: 'something to test',
        days: 2
    },
    {
        type: ATSWidgetType.WARNING,
        content: 'something to test warning',
        days: 25
    },
    {
        type: ATSWidgetType.ERROR,
        content: 'something to test',
        days: 3594
    },
    {
        type: ATSWidgetType.SUCCESS,
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        days: 96
    }];

    return (
        <Layout title={""} backHistory={false} >
            <section className="flex">
                {
                    buttons &&
                    buttons.map((button, i) => <ATSButton {...{ ...button }} key={i + button.type} />)
                }
            </section>
            <hr />
            <section className="flex">
                <label htmlFor="creditCard" className="radioButton">
                    <input id="creditCard" type="radio" name="typePayment" />
                    <button>
                        <i className="icon-credit_card"></i>
                        <span>Credit card</span>
                    </button>
                </label>

                <label htmlFor="manual" className="radioButton">
                    <input id="manual" type="radio" name="typePayment" />
                    <button>
                        <i className="icon-account_balance_wallet"></i>
                        <span>Manual</span>
                    </button>
                </label>
            </section>
            <hr />
            <section className="flex">
                <ATSRequestsStatus studentStatusCounts={requests} collapse={true} />
            </section>
            <hr />
            <section className="flex">
                {info.map((data) => <ATSInfo {...{ ...data }} />)}
            </section>
            <hr />
            <section className="flex">
                {widgets.map((data) => <ATSWidget {...{ ...data }} />)}
            </section>
            <hr />
            <section className="flex">
                <ModalexampleUsageDefault />

                <ModalexampleUsageComment />

                <ModalexampleUsageCancelClass />
            </section>
            <div className="wrapper">
                <i className="icon-info" />
                <ATSTooltip
                    alignment={ATSTooltipAlignment.TOPRIGHT}
                    title='Test'
                    iconClass='icon-info'
                    body={[
                        { text: 'esto es un /*test*/ para revisar si hay textos con /*negrita*/ o /_subrayado_/', alignment: ATSTooltipBodyAlignment.LEFT },
                        { text: 'esto es un test para revisar si hay textos con /*negrita*/ o subrayado', alignment: ATSTooltipBodyAlignment.CENTERED },
                        { text: 'esto es un test para revisar si hay textos con negrita o subrayado', alignment: ATSTooltipBodyAlignment.RIGHT }
                    ]} />
            </div>

            <div>
                <i className="icon-amex"></i>;
                <i className="icon-mastercard"></i>;
                <i className="icon-visa"></i>;
                <i className="icon-discover"></i>;
                <i className="icon-generic_card"></i>;
            </div>

            <div>
                <ATSSpinner title="No results found" subtitle="We can’t find any item matching your search. " />
            </div>
        </Layout>
    );
}
