import React, { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ATSTypeButton } from "../../../../components/Shared/ATSButton";
import ATSInfo, { ATSInfoType } from "../../../../components/Shared/ATSInfo";
import { currencyDollarUS, formatDate, formatDateFull, getStartTime, timeConvert } from "../../../../helpers/helpers";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../../components/Shared/ATSModal";
import ATSInfoItem from "../../../../components/Shared/ATSInfoItem";
import ATSVirtualList from "../../../../components/Shared/ATSVirtualList";
import PaymentService from "../../../../services/entitiesServices/PaymentService";
import Layout from "../../../../components/Layout";
import styles from "./RefundSuccess.module.scss";

function RefundSuccess(): ReactElement {
    const paymentInit = {
        students: [],
        payment: {
            id: 0,
            dealerName: "",
            amount: 0,
            token: "",
            date: "",
            statusName: "",
            typeName: "",
            methodId: 0,
            methodName: "",
            startDate: "",
            endDate: "",
            startTime: 0,
            startTimeMinute: 0,
            price: 0,
            location: "",
            comment: "",
            slots: 0,
            courseName: "",
            refundComment: "",
            manualPaymentComment: "",
        },
    };
    const { paymentId } = useParams();
    const location = useLocation();
    const { refundAmount, pending } = location.state as any;
    const navigate = useNavigate();
    const [response, setResponse] = useState<any>(paymentInit);
    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);

    useEffect(() => {
        loadPayment();
    }, []);

    const loadPayment = async () => {
        try {
            setLayoutLoading(true);
            const response = await PaymentService.getPayment(paymentId);
            if (response && response.success && response.response) {
                setResponse(response.response);
            } else {
                response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                if (response.httpStatusCode === 500) {
                    setModalConfig(refundPaymentModals.unexpectedError);
                    setToggleModal(true);
                }
            }
            setLayoutLoading(false);
        } catch (e) {
            //do something
        }
    };

    function onRowClickHandler(event, id) {
        navigate(`/students/${id}`);
    }

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const buttonGoBack = [
        {
            cta: "Go back",
            type: ATSTypeButton.SUCCESS,
            url: `/payments/${paymentId}`,
        },
    ];

    const buttonGoBackWarning = [
        {
            cta: "Go back",
            type: ATSTypeButton.WARNING,
            url: `/payments/${paymentId}`,
        },
    ];

    const refundPaymentModals = {
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: ["Sorry there was an unexpected error while fetching the payment.", "If the problem persist call an administrator."],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
            buttons: buttonsErrorModal,
        },
    };

    return (
        <Layout
            title={"Refund Payment"}
            backHistory={true}
            backLabel="Payment"
            isLoading={layoutLoading}
            buttons={[{ cta: "Print Receipt", type: ATSTypeButton.NEGATIVE, action: () => window.print() }]}>
            <div className={styles.layout}>
                <div className={styles.layout_aside}>
                    <div className="whitebox has_heading">
                        <div className="whitebox_heading">
                            <h3 className="whitebox_heading_title">Transaction</h3>
                        </div>
                        <div className="whitebox_content">
                            <ul className="whitebox_list inColumns">
                                {response && (
                                    <>
                                        <ATSInfoItem title={"TOKEN"} value={response.payment.token} />
                                        <ATSInfoItem
                                            title={"AMOUNT REFUNDED"}
                                            value={refundAmount ? currencyDollarUS.format(refundAmount) : ""}
                                        />
                                        <ATSInfoItem title={"STATUS"} value={response.payment.statusName} />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={styles.layout_content}>
                    {!pending && (
                        <ATSInfo
                            type={ATSInfoType.SUCCESS}
                            title="Your refund was successful, and your order is complete."
                            content={""}
                            buttons={buttonGoBack}
                        />
                    )}
                    {pending && (
                        <ATSInfo
                            type={ATSInfoType.WARNING}
                            title="Thanks for your order! Your refund is pending."
                            content={""}
                            buttons={buttonGoBackWarning}
                        />
                    )}
                    <div className="whitebox has_heading">
                        <div className="whitebox_heading">
                            <i className="icon-credit_card"></i>
                            <h2 className="whitebox_heading_title">Refund details</h2>
                        </div>
                        <div className="whitebox_content">
                            <div className="whitebox_area">
                                <div className="whitebox_heading">
                                    <h2 className="whitebox_heading_subtitle">Students</h2>
                                </div>
                                {response?.students && (
                                    <ATSVirtualList
                                        dataSet={response.students}
                                        onRowClickHandler={onRowClickHandler}
                                        noData={false}
                                        resetFilter={() => {}}
                                        entity="students"
                                        ignoreColumns={["id", "statusName"]}
                                        rowHeight={35}
                                    />
                                )}
                            </div>

                            <div className="whitebox_area">
                                <div className="whitebox_heading">
                                    <h2 className="whitebox_heading_subtitle">Course info</h2>
                                </div>
                                <ul className="whitebox_list inColumns">
                                    <ATSInfoItem title="Course" value={response.payment.courseName} />
                                    <ATSInfoItem title="Location" value={response.payment.location} />
                                    <ATSInfoItem
                                        title="Start Date and Time"
                                        value={`${formatDate(response.payment.startDate)} - ${timeConvert(
                                            getStartTime(response.payment.startTime, response.payment.startTimeMinute)
                                        )}`}
                                    />
                                    <ATSInfoItem title="Seats" value={`x ${response.payment.slots}`} />
                                    <ATSInfoItem title="Price" value={`${currencyDollarUS.format(response.payment.price)}`} />
                                </ul>
                            </div>

                            <div>
                                <div className="whitebox_heading">
                                    <h2 className="whitebox_heading_subtitle">Original Payment</h2>
                                </div>
                                <ul className="whitebox_list inColumns">
                                    <ATSInfoItem title="Token" value={response.payment.token ? response.payment.token : "MANUAL ENROLLMENT"} />
                                    <ATSInfoItem title="Paid date" value={`${formatDateFull(response.payment.date)}`} />
                                    <ATSInfoItem title="Method" value={response.payment.methodName} />
                                    <ATSInfoItem title="Dealer" value={response.payment.dealerName} />
                                    <ATSInfoItem title="Amount" value={`${currencyDollarUS.format(response.payment.amount)}`} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default RefundSuccess;
