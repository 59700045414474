import { useState } from "react";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from ".";
import ATSButton, { ATSTypeButton } from "../ATSButton";

/**
 *
 * USAGE EXAMPLE FOR MODALS
 *
 */

export function ModalexampleUsageDefault() {
    const [openModal, setOpenModal] = useState(false);

    //the modal need a buttons provided with his function or links provided, also you need to pass it on the function that's close the modal.
    const buttonsModal = [
        {
            cta: "Back",
            type: ATSTypeButton.OUTPOSITIVE,
            action: () => {
                setOpenModal(!openModal), console.log("do something else");
            },
        },
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => {
                setOpenModal(!openModal), console.log("do something else");
            },
        },
    ];

    //TITLE and BUTTON/S are MANDATORY for this modal
    return (
        <>
            <ATSButton cta="Open default modal" type={ATSTypeButton.DEFAULT} action={() => setOpenModal(!openModal)} />
            {openModal && (
                <ATSModal
                    title="You will create a token for this dealer"
                    icon="icon-cancel danger"
                    bodyText={["To perform this action, first refund all the students or move them to another course."]}
                    type={ATSTypeModal.DEFAULT}
                    bodyTextType={ATSBodyTextTypeModal.SUBTITLE}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setOpenModal(!openModal)}
                    buttons={buttonsModal}
                />
            )}
        </>
    );
}

export function ModalexampleUsageComment() {
    const [openModal, setOpenModal] = useState(false);

    //COMMENT TYPE is MANDATORY for this modal
    return (
        <>
            <ATSButton cta="Open comment modal" type={ATSTypeButton.DEFAULT} action={() => setOpenModal(!openModal)} />
            {openModal && (
                <ATSModal
                    title="TEST MODAL COMMENT"
                    type={ATSTypeModal.COMMENT}
                    handleClose={() => setOpenModal(!openModal)}
                    handleSetComment={() => console.log("")}
                />
            )}
        </>
    );
}

export function ModalexampleUsageCancelClass() {
    const [openModal, setOpenModal] = useState(false);

    //COMMENT TYPE is MANDATORY for this modal
    return (
        <>
            <ATSButton cta="Open cancel class modal" type={ATSTypeButton.DEFAULT} action={() => setOpenModal(!openModal)} />
            {openModal && (
                <ATSModal
                    type={ATSTypeModal.CANCELWITHCOMMENT}
                    handleClose={() => setOpenModal(!openModal)}
                    handleSetComment={() => console.log("")}
                />
            )}
        </>
    );
}
