import { ReactElement, useEffect, useState } from "react";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { objectValidations } from "../../../helpers/helpers";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import { PhoneNumFormatter } from "../../../components/form/ATSPhoneInput/phonenumberinfo";
import Layout from "../../../components/Layout";
import ATSTextInput from "../../../components/form/ATSTextInput";
import ATSSpinner from "../../../components/Shared/ATSSpinner";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import styles from "./UserPassword.module.scss";
import UserService from "../../../services/entitiesServices/UserService";
import { IUserProfileResponse } from "../../../services/interfaces/serviceResponses/user/IUserProfileResponse";
import { IChangePasswordRequest } from "../../../services/interfaces/servicePostBody/user/IChangePasswordRequest";
import { SelectOption } from "../../../components/form/ATSSelect";
import StatesService from "../../../services/entitiesServices/StatesService";
import { useNavigate } from "react-router-dom";

function UserPassword(): ReactElement {
    const userInit: IUserProfileResponse = {
        firstName: "",
        lastName: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        state: "",
        phone: "",
        countryId: 0,
        countryName: "",
    };

    const userFormInit: IChangePasswordRequest = {
        currentPassword: "",
        newPassword: "",
        repeatPassword: ""
    };

    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [changePasswordForm, setChangePasswordForm] = useState<IChangePasswordRequest>(userFormInit);
    const [errors, setErrors] = useState<Record<string, Array<string>>>({});
    const [response, setResponse] = useState<IUserProfileResponse>(userInit);
    const [loading, setLoading] = useState<boolean>(false);
    const [stateList, setStateList] = useState<SelectOption[]>([]);
    const navigate = useNavigate();

    const phoneFormatter = new PhoneNumFormatter();

    useEffect(() => {
        loadUser();
        getStateList();
    }, []);

    async function getStateList() {
        const res = await StatesService.getStates();
        if (res && res.success)
            setStateList(
                res.response.items.map(state => {
                    if (state.code === response?.state) {
                        return { value: state.code, label: state.name, default: true };
                    } else return { value: state.code, label: state.name };
                })
            );
    }

    const loadUser = async () => {
        setLoading(true);
        const res = await UserService.getUserProfile();
        if (res.success) {
            setResponse(res.response);
        }
        setLoading(false);
    };

    const handleInputChange = (name: string, value: string | number): void => {
        setChangePasswordForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    function getStateDescription(stateCode: string) {
        return stateList.find(state => state.value === stateCode)?.label;
    }

    async function handleSubmit() {
        const validations = [
            {
                key: "currentPassword",
                type: "string",
                required: true,
            },
            {
                key: "newPassword",
                type: "string",
                required: true,
            },
            {
                key: "repeatPassword",
                type: "string",
                required: true,
            }
        ];
        const properties = objectValidations(changePasswordForm, validations);
        if (changePasswordForm.newPassword !== changePasswordForm.repeatPassword) {
            setErrors({ ...errors, ...{ "repeatPassword": ["Passwords don't match"] } });
            return;
        }
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) {
            try {
                const response = await UserService.changePassword(changePasswordForm);
                if (response.success) {
                    setModalConfig(editUserModals.success);
                    setToggleModal(true);
                    setChangePasswordForm({ currentPassword: "", newPassword: "", repeatPassword: "" });
                } else {
                    editUserModals.error.bodyText.push(response.message);
                    setModalConfig(editUserModals.error);
                    setToggleModal(true);
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            setErrors(errors);
            console.error("validations not passed");
        }
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Save",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
        {
            cta: "Back",
            type: ATSTypeButton.OUTNEGATIVE,
            action: () => navigate(`/settings`),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: `/settings`,
            action: () => {
                closeAndReload();
            },
        },
    ];

    function closeAndReload() {
        setToggleModal(false);
        loadUser();
    }

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];
    const editUserModals = {
        success: {
            title: "Successfully changed password",
            icon: "icon-check_circle_fill success",
            bodyText: ["The password was successfully changed."],
            type: ATSTypeModal.DEFAULT,
            buttons: buttonsModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => closeAndReload(),
        },
        error: {
            title: "ERROR",
            icon: "icon-error danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [
                ""
            ],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };
    //#endregion

    return (
        <Layout title={"Change password"} backHistory={false} buttons={buttons} userSettings={true}>
            {!loading ? (
                <div className={styles.layout}>
                    <div className={styles.layout_area}>
                        <div className="whitebox">
                            <ul className="whitebox_list">
                                <h2 className="form_title">Previous info</h2>
                                {response && (
                                    <>
                                        <ATSInfoItem title={"FIRST NAME"} value={response.firstName} />
                                        <ATSInfoItem title={"LAST NAME"} value={response.lastName} />
                                        <ATSInfoItem title={"EMAIL"} value={response.email ?? "-"} />
                                        <ATSInfoItem title={"ADDRESS LINE 1"} value={response.addressLine1 ?? "-"} />
                                        {response.addressLine2 && <ATSInfoItem title={"ADDRESS LINE 2"} value={response.addressLine2 ?? "-"} />}
                                        <ATSInfoItem title={"CITY"} value={response.city ?? "-"} />
                                        <ATSInfoItem title={"ZIP CODE"} value={response.zip ?? "-"} />
                                        <ATSInfoItem title={"STATE"} value={response.state ? getStateDescription(response.state) : "-"} />
                                        <ATSInfoItem
                                            title={"PHONE NUMBER"}
                                            value={response.phone ? phoneFormatter.format(response.phone) : "-"}
                                        />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.layout_area}>
                        <div className="whitebox hasScroll">
                            <form className="form">
                                <fieldset className="form_fieldset maxWidth">
                                    <ATSTextInput
                                        label="Current Password"
                                        name="currentPassword"
                                        handleChange={handleInputChange}
                                        value={changePasswordForm.currentPassword}
                                        error={errors && errors.currentPassword ? errors.currentPassword : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={200}
                                        required
                                        type="password"
                                    />
                                    <ATSTextInput
                                        label="New Password"
                                        name="newPassword"
                                        handleChange={handleInputChange}
                                        value={changePasswordForm.newPassword}
                                        error={errors && errors.newPassword ? errors.newPassword : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={200}
                                        required
                                        type="password"
                                    />
                                    <ATSTextInput
                                        label="Repeat Password"
                                        name="repeatPassword"
                                        handleChange={handleInputChange}
                                        value={changePasswordForm.repeatPassword}
                                        error={errors && errors.repeatPassword ? errors.repeatPassword : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={200}
                                        required
                                        type="password"
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <ATSSpinner />
            )}
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default UserPassword;
