import { formatTextDate } from "../../../../helpers/helpers";
import UpcomingCard from "../UpcomingCard";
import styles from './UpcomingDate.module.scss';

interface IUpcomingDateProps {
    groupBy: string;
    values: Array<object>;
}
function UpcomingDate(props: IUpcomingDateProps) {
    const { groupBy, values } = props;
    const date = formatTextDate(groupBy);
    return (
        <div className={styles.upcoming_group}>
            <h3 className="form_label">{`${date}`}</h3>
            {values.map((upcoming, i) => (
                <UpcomingCard {...{ ...upcoming }} key={`upcoming_card_${i}`} />
            ))}
        </div>
    );
}

export default UpcomingDate;