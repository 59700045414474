import { ATSSteps, IBaseStepProps } from '..';
import ATSRadioInput, { ATSRadioType } from '../../../../components/form/ATSRadioInput';
import ATSButton, { ATSTypeButton } from '../../../../components/Shared/ATSButton';
import { IObjectValidationsProperties, objectValidations } from '../../../../helpers/helpers';
import { PaymentMethods } from '../../../../services/interfaces/IPayments';
import AchMethod from './Ach';
import CreditCard from './CreditCard';
import Manual from './Manual';
import styles from './PaymentStep.module.scss';
interface IPaymentStepProps extends IBaseStepProps {
    handleSubmit: () => void;
    setEnrollData: (state: any) => void;
    restorePayMethods: (method: PaymentMethods) => void;
}

function PaymentStep(props: IPaymentStepProps) {
    const { handleInputChange, state, handleErrorsChange, errors, handleStep, handleSubmit, restorePayMethods } = props;

    const paymentOptions = [
        {
            label: 'Credit Card',
            value: PaymentMethods.CreditCard,
            classIcon: 'icon-credit_card',
            default: true
        },
        {
            label: 'Manual',
            value: PaymentMethods.Manual,
            classIcon: 'icon-account_balance_wallet',
            default: false
        },
        {
            label: 'ACH',
            value: PaymentMethods.ACH,
            classIcon: 'icon-account_balance_wallet',
            default: false
        }
    ];

    const paymentForms = {
        1: <CreditCard {...{ handleInputChange, state, handleErrorsChange, errors }} />,
        2: <AchMethod {...{ handleInputChange, state, handleErrorsChange, errors }} />,
        3: <Manual {...{ handleInputChange, state, handleErrorsChange, errors }} />
    };

    const disablePayButton = () => {
        switch (state.method) {
            case PaymentMethods.CreditCard:
                return validateCardData();
            case PaymentMethods.ACH:
                return validateAchData();
            case PaymentMethods.Manual:
                return validateManualData();
        }

    };

    const validateCardData = (): boolean => {
        const validationsCardInputs: Array<IObjectValidationsProperties> = [
            {
                key: 'cardNumber',
                type: 'string',
                required: true
            },
            {
                key: 'cvc',
                type: 'string',
                required: true
            },
            {
                key: 'expirationMonth',
                type: 'number',
                required: true
            },
            {
                key: 'expirationYear',
                type: 'number',
                required: true
            },
            {
                key: 'name',
                type: 'string',
                required: true
            },
            {
                key: 'country',
                type: 'string',
                required: true
            },
            {
                key: 'postalCode',
                type: 'string',
                required: true
            }
        ];
        if (!state.paymentData) return false;
        const properties = objectValidations(state.paymentData, validationsCardInputs);
        if (errors.creditCard) {
            if (errors.creditCard.cardNumber?.length > 0 ||
                errors.creditCard.cvc?.length > 0 ||
                errors.creditCard.expiration?.length > 0 ||
                errors.creditCard.name?.length > 0 ||
                errors.creditCard.postalCode?.length > 0) return false;
        }
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) return true;
        else {
            return false;
        }
    };

    const validateManualData = (): boolean => {
        const validationsManualInputs: Array<IObjectValidationsProperties> = [
            {
                key: 'title',
                type: 'string',
                required: true
            },
            {
                key: 'comment',
                type: 'string',
                required: true
            }
        ];
        if (!state.paymentData) return false;
        const properties = objectValidations(state.paymentData, validationsManualInputs);
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) return true;
        else {
            return false;
        }
    };

    const validateAchData = (): boolean => {
        const validationsACHInputs: Array<IObjectValidationsProperties> = [
            {
                key: 'accountNumber',
                type: 'string',
                required: true
            },
            {
                key: 'routingNumber',
                type: 'string',
                required: true
            },
            {
                key: 'holderName',
                type: 'string',
                required: true
            },
            {
                key: 'accountHolderType',
                type: 'string',
                required: true
            }
        ];
        if (!state.paymentData) return false;
        const properties = objectValidations(state.paymentData, validationsACHInputs);
        if (properties &&
            properties.hasRequiredInputs &&
            properties.passTypeValidations &&
            state.paymentData.accountNumber.length == 12 &&
            state.paymentData.routingNumber.length == 9)
            return true;
        else {
            return false;
        }
    };

    return (
        <>
            <ATSRadioInput name='method' type={ATSRadioType.CARD} options={paymentOptions} handleChange={handleInputChange} />
            {
                paymentForms[state.method]
            }
            <div className={`form_fieldset row maxWidth ${styles.buttonsWrapper}`}>
                <ATSButton
                    cta="Back"
                    type={ATSTypeButton.POSITIVE}
                    action={() => {
                        handleStep(ATSSteps.BACK);
                        restorePayMethods(state.method);
                    }}
                />
                <ATSButton
                    cta="Pay Now"
                    type={ATSTypeButton.SUCCESS}
                    action={handleSubmit}
                    disabled={!disablePayButton()}
                />
            </div>
        </>
    );
}

export default PaymentStep;