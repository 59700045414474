import React, { useEffect, useRef, useState } from "react";
import { ReactElement } from "react";
import { IStudent } from "../../../services/interfaces/IStudent";
import { IPayment } from "../../../services/interfaces/IPayment";
import { IDealer } from "../../../services/interfaces/IDealer";
import { dictionary } from "../../../helpers/dictionary";
import { useVirtualizer } from "@tanstack/react-virtual";
import ATSVirtualListRow from "./ATSVirtualListRow";
import styles from "./ATSVirtualList.module.scss";
import { ICourseResponse } from "../../../services/interfaces/serviceResponses/course/ICoursesResponse";
import ATSButton, { ATSTypeButton } from "../ATSButton";
import ATSSpinner from "../ATSSpinner";
import { useInView } from "react-intersection-observer";
interface IATSVirtualListProps {
    dataSet: IDealer[] | ICourseResponse[] | IStudent[] | IPayment[];
    onRowClickHandler: (event: React.MouseEvent<HTMLElement>, id: unknown) => void;
    noData: boolean;
    resetFilter: () => void;
    ignoreColumns?: string[];
    entity?: string;
    sortBy?: string[];
    sortFunc?: (column: string, order: boolean) => void;
    handleSelected?: (e) => void;
    rowHeight?: number;
    isLoading?: boolean;
    hasSelect?: boolean;
    fetchMore?: () => void;
}

export enum ATSVirtualTableHeight {
    NORMAL = 54,
    COLLAPSED = 36,
}

/**
 * Set new ATSVirtualList component.
 * @param dataSet: IDealer[] | ICourseResponse[] | IStudent[] | IPayment[];
 * @param onRowClickHandler: function: void
 * @param noData: boolean
 * @param resetFilter: function: void
 * @param entity: string; -> optional
 * @param ignoredColumns: string[] -> optional
 * @param sortBy: string[]; -> optional
 * @param sortFunc: function(columns: string, order:boolean (true: descending, false: ascending) ); -> optional
 * @param handleSelected: (selected: boolean, studentsId: number) => void; -> optional
 * @param rowHeight: number; -> optional
 * @param isLoading?: boolean;
 * @param hasSelect?: boolean;
 * @param fetchMore: () => void;
 */

export default function ATSVirtualList(atsVirtualListProps: IATSVirtualListProps): ReactElement {
    const {
        dataSet,
        onRowClickHandler,
        noData,
        resetFilter,
        entity,
        ignoreColumns,
        sortBy,
        sortFunc,
        handleSelected,
        rowHeight,
        isLoading,
        hasSelect,
        fetchMore
    } = atsVirtualListProps;
    //Extract titles from dataSet
    const rows = dataSet as any;
    const titles = dataSet.length > 0 ? Object.keys(dataSet[0]) : [];
    const [order, setOrder] = useState({ order: false, title: "" });
    const [checklist, setChecklist] = useState<number[]>([]);
    //const [virtualData, setVirtualData] = useState<any[]>();
    const parentRef = useRef<HTMLTableElement>(null);
    const { ref: lastElement, inView } = useInView();

    useEffect(() => {
        setChecklist([]);
    }, [dataSet]);

    useEffect(() => {
        if (inView && fetchMore && dataSet.length % 100 === 0) {
            console.log("Fetching more elements please...");
            fetchMore();
        }
    }, [inView])

    function handleCheckboxOnChange(e) {
        if (e.target.checked && checklist.indexOf(+e.target.value) === -1) {
            setChecklist(prev => [...prev, +e.target.value]);
        } else {
            let list = [...checklist];
            list.splice(list.indexOf(+e.target.value), 1);
            setChecklist(list);
        }
        handleSelected && handleSelected(e);
    }

    function titleClickHandler(e, title, orderParam) {
        e.preventDefault();
        if (sortFunc) {
            sortFunc(title, orderParam);
            setOrder({ order: !order.order, title: title });
        }
    }
    
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => rowHeight ?? ATSVirtualTableHeight.NORMAL,
        overscan: 5,
    });

    const returnDictionaryTitle = (title: string) => {
        return dictionary[title] ? dictionary[title] : title;
    };
    const virtualData = rowVirtualizer.getVirtualItems();
    /*
    useEffect(() => {
        //console.log(rowVirtualizer.setOptions({ ...rowVirtualizer.options, estimateSize: rowHeight }));
        console.log(rowVirtualizer.options.estimateSize());
        console.log(rowHeight);
        console.log(virtualData);
        setVirtualData(rowVirtualizer.getVirtualItems());
    }, [rowVirtualizer, dataSet, rowHeight])
    */
    return (
        <>
            {isLoading && <ATSSpinner title="Fetching data..." subtitle="Please wait, while we fetch the requested data." />}
            {!isLoading && !noData ? (
                <table ref={parentRef} className="ats_table">
                    <thead>
                        <tr>
                            {hasSelect && <th></th>}
                            {virtualData &&
                                virtualData.length > 0 &&
                                titles.map((title: string, i: number) => {
                                    if (entity === "payments" && title === "courseName") {
                                        return <th key={`${title}_${i}`}>PURCHASED</th>;
                                    } else if (entity === "students" && title === "date") {
                                        return (
                                            <th
                                                key={`${title}_${i}`}
                                                className={styles.titleSort}
                                                onClick={e => {
                                                    titleClickHandler(e, title, !order.order);
                                                }}>
                                                START DATE
                                                <i
                                                    className={`icon-expand_more ${
                                                        title === order.title && order.order ? styles.ascending : ""
                                                    }`}></i>
                                            </th>
                                        );
                                    } else if (!ignoreColumns?.some(e => e === title)) {
                                        if (sortBy?.some(e => e === title)) {
                                            return (
                                                <th
                                                    key={`${title}_${i}`}
                                                    className={styles.titleSort}
                                                    onClick={e => {
                                                        titleClickHandler(e, title, !order.order);
                                                    }}>
                                                    {returnDictionaryTitle(title)}
                                                    <i
                                                        className={`icon-expand_more ${
                                                            title === order.title && order.order ? styles.ascending : ""
                                                        }`}></i>
                                                </th>
                                            );
                                        } else {
                                            return <th key={`${title}_${i}`}>{dictionary[title] ? dictionary[title] : title}</th>;
                                        }
                                    }
                                })}
                            {entity === "courses" && <th></th>}
                        </tr>
                    </thead>
                    <tbody
                        className={styles.body}
                        style={{
                            ["--bodyHeight" as string]: `${rowVirtualizer.getTotalSize()}px`,
                        }}>
                        {virtualData &&
                            virtualData.length > 0 &&
                            virtualData.map(virtualRow => (
                                <tr
                                    ref={virtualRow.index === virtualData.length -1 ? lastElement : null}
                                    key={virtualRow.index}
                                    onClick={e => onRowClickHandler(e, rows[virtualRow.index].id)}
                                    className={
                                        entity === "courses" && rows[virtualRow.index].courseStatusName === "Cancel"
                                            ? `${styles.virtualBox} ${styles.cancelled}`
                                            : `${styles.virtualBox}`
                                    }
                                    style={{
                                        //["--virtualHeight" as string]: `${rowHeight}px`,
                                        ["--virtualHeight" as string]: `${virtualRow.size}}px`,
                                    }}>
                                    {hasSelect && (
                                        <td>
                                            {rows[virtualRow.index].statusStudentName === "Pending" ? (
                                                <input
                                                    type="checkbox"
                                                    name={virtualRow.id}
                                                    checked={checklist.indexOf(rows[virtualRow.index].id) !== -1}
                                                    value={rows[virtualRow.index].id}
                                                    onClick={e => e.stopPropagation()}
                                                    onChange={e => {
                                                        handleCheckboxOnChange(e);
                                                    }}></input>
                                            ) : (
                                                <input type="checkbox" checked={false} disabled></input>
                                            )}
                                        </td>
                                    )}
                                    <ATSVirtualListRow
                                        key={virtualRow.id}
                                        data={rows[virtualRow.index]}
                                        ignoredColumns={ignoreColumns}
                                        entity={entity}
                                        handleSelected={handleSelected}
                                    />
                                </tr>
                            ))}
                    </tbody>
                </table>
            ) : (
                !isLoading && (
                    <div className="ats_table_alert">
                        <div className="ats_table_alert_box">
                            <h3 className="ats_table_alert_title">No results found</h3>
                            <p className="ats_table_alert_text">We can’t find any items matching your search. </p>
                            <ATSButton cta="Clear filters" type={ATSTypeButton.POSITIVE} action={resetFilter}></ATSButton>
                        </div>
                    </div>
                )
            )}
        </>
    );
}
