import { useEffect, useRef, useState } from "react";
import ATSEmailInput from "../../../../../components/form/ATSEmailInput";
import ATSPhoneInput from "../../../../../components/form/ATSPhoneInput";
import ATSTextInput from "../../../../../components/form/ATSTextInput";
import ATSButton, { ATSTypeButton } from "../../../../../components/Shared/ATSButton";
import { copyJSON } from "../../../../../helpers/helpers";
import styles from "./StudentForm.module.scss";

function StudentForm({ index, manageStudents, students, errors, handleErrorsChange }) {
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
    const [handleIcon, setHandleIcon] = useState<boolean>();
    const accordionOpened = useRef<boolean>();

    //Set firs student collapsed item opened
    useEffect(() => {
        if (index === 0) {
            accordionOpened.current = accordionOpen;
            setAccordionOpen(true);
        }
    }, []);

    //Set icon when the collapsed item has errors
    useEffect(() => {
        if (!errors.students) return;
        if (!errors.students[index]) return;
        let base = false;
        Object.keys(errors.students[index]).forEach(key => {
            if (!(errors.students[index][key].length > 0)) return;
            base = true;
        });
        setHandleIcon(base);
    }, [errors, accordionOpen]);

    //Validate errors when accordion it's collapsed
    useEffect(() => {
        if (!accordionOpened.current) return;
        if (accordionOpen) return;

        let encapsuledErrors, studentErrors;

        for (const [key, value] of Object.entries(students[index])) {
            if (value === "")
                encapsuledErrors = {
                    ...encapsuledErrors,
                    [key]: ["This field is mandatory and you did not provide a value."],
                };
        }

        if (errors["students"]) {
            studentErrors = copyJSON(errors["students"]);
        } else {
            let copyErrors = copyJSON(errors);
            copyErrors["students"] = [];
            studentErrors = copyJSON(copyErrors["students"]);
        }

        //Set the new object with errors
        studentErrors[index] = {
            ...studentErrors[index],
            ...encapsuledErrors,
        };

        handleErrorsChange("students", studentErrors);
    }, [accordionOpen]);

    function handleManageStudents(name, value) {
        manageStudents(index, name, value);
    }

    function handleErrorsIndexed(name, value, indexed, index) {
        handleErrorsChange(name, value, indexed, index);
    }

    const hanldeAccordionOpen = () => {
        accordionOpened.current = accordionOpen;
        setAccordionOpen(!accordionOpen);
    };

    return (
        <li className={`${styles.accordion_item} ${accordionOpen ? styles.active : ""}`}>
            <div className={styles.accordion_heading} onClick={hanldeAccordionOpen}>
                <span>
                    {students[index].firstName !== "" || students[index].lastName !== ""
                        ? students[index].firstName + " " + students[index].lastName
                        : `Student ${index + 1}`}
                </span>
                {typeof handleIcon !== "undefined" && !accordionOpen && (
                    <i className={`${handleIcon ? "icon-error danger" : "icon-check_circle_fill success"}`}></i>
                )}
                <i className="icon-expand_more"></i>
            </div>
            <div className={styles.accordion_content}>
                <fieldset className="form_fieldset">
                    <div className="group c1x1">
                        <ATSTextInput
                            label="First Name"
                            name="firstName"
                            handleChange={handleManageStudents}
                            value={students[index].firstName}
                            error={errors.students && errors.students[index] ? errors.students[index].firstName : []}
                            setErrorMessage={(name, value) => handleErrorsIndexed(name, value, "students", index)}
                            maxLengthProps={50}
                            required
                        />
                        <ATSTextInput
                            label="Last Name"
                            name="lastName"
                            handleChange={handleManageStudents}
                            value={students[index].lastName}
                            error={errors.students && errors.students[index] ? errors.students[index].lastName : []}
                            setErrorMessage={(name, value) => handleErrorsIndexed(name, value, "students", index)}
                            maxLengthProps={50}
                            required
                        />
                    </div>

                    <ATSPhoneInput
                        label="Phone Number"
                        name="phoneNumber"
                        handleChange={handleManageStudents}
                        value={students[index].phoneNumber}
                        error={errors.students && errors.students[index] ? errors.students[index].phoneNumber : []}
                        setErrorMessage={(name, value) => handleErrorsIndexed(name, value, "students", index)}
                        maxLengthProps={15}
                        required
                    />

                    <ATSEmailInput
                        label="Email"
                        name="email"
                        handleChange={handleManageStudents}
                        value={students[index].email}
                        error={errors.students && errors.students[index] ? errors.students[index].email : []}
                        setErrorMessage={(name, value) => handleErrorsIndexed(name, value, "students", index)}
                        maxLengthProps={100}
                        required
                    />

                    <div className="group btn-end">
                        <ATSButton cta="Ok" type={ATSTypeButton.POSITIVE} action={hanldeAccordionOpen} />
                    </div>
                </fieldset>
            </div>
        </li>
    );
}

export default StudentForm;
