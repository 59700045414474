import { ReactElement, useEffect, useState } from "react";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import ATSSelect, { SelectOption } from "../../../components/form/ATSSelect";
import Layout from "../../../components/Layout";
import styles from "./NewDealer.module.scss";
import ATSTextInput from "../../../components/form/ATSTextInput";
import StatesService from "../../../services/entitiesServices/StatesService";
import ATSEmailInput from "../../../components/form/ATSEmailInput";
import ATSPhoneInput from "../../../components/form/ATSPhoneInput";
import ATSZipCodeInput from "../../../components/form/ATSZipCodeInput";
import { objectValidations } from "../../../helpers/helpers";
import DealerService from "../../../services/entitiesServices/DealerService";
import { ICreateDealerRequest } from "../../../services/interfaces/servicePostBody/dealer/ICreateDealerRequest";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import ATSInfo, { ATSInfoType } from "../../../components/Shared/ATSInfo";
import { useNavigate } from "react-router-dom";
import CountriesService from "../../../services/entitiesServices/CountriesService";

interface countriesList extends SelectOption {
    code: string;
}
function NewDealer(): ReactElement {
    const [openModal, setOpenModal] = useState(false);
    const [unexpectedErrorModal, setUnexpectedErrorModal] = useState(false);
    const [dealerForm, setDealerForm] = useState<any>({ addressLine2: "", zip: "" });
    const [errors, setErrors] = useState<any>();
    const [countriesList, setCountriesList] = useState<countriesList[]>();
    const [stateList, setStateList] = useState<SelectOption[]>();
    const [resetStates, setResetStates] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        getCountriesList();
    }, []);

    useEffect(() => {
        if (!dealerForm.country) return;
        setResetStates(true);
        setStateList([]);
        const { state, ...rest } = dealerForm;
        setDealerForm({ ...rest });
        getStateList(dealerForm.country);
    }, [dealerForm.country]);

    async function getCountriesList() {
        const res = await CountriesService.getCountries();

        if (res && res.success) {
            if (res.response.items.length > 1) {
                setCountriesList(res.response.items.map(country => ({ value: country.id, label: country.name, code: country.code })));
            } else {
                setCountriesList(res.response.items.map(country => ({ value: country.id, label: country.name, code: country.code, default: true })));
            }
        }
    }

    async function getStateList(countryId) {

        const res = await StatesService.getByCountry(countryId);
        if (res && res.success) {
            setResetStates(false);
            setErrors(prev => ({
                ...prev,
                state: [],
            }));

            if (res.response.items.length > 1) {
                setStateList(res.response.items.map(state => ({ value: state.code, label: state.name })));
            } else {
                setStateList(res.response.items.map(state => ({ value: state.code, label: state.name, default: true })));
            }
        }
    }

    const handleInputChange = (name: string, value: string | number): void => {
        setDealerForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    async function handleSubmit() {
        const validations = [
            {
                key: "name",
                type: "string",
                required: true,
            },
            {
                key: "email",
                type: "string",
                required: true,
            },
            {
                key: "addressLine1",
                type: "string",
                required: true,
            },
            {
                key: "addressLine2",
                type: "string",
                required: false,
            },
            {
                key: "city",
                type: "string",
                required: true,
            },
            {
                key: "zip",
                type: "string",
                required: true,
            },
            {
                key: "state",
                type: "string",
                required: true,
            },
            {
                key: "phone",
                type: "string",
                required: true,
            },
            {
                key: "contactName",
                type: "string",
                required: true,
            },
        ];
        const properties = objectValidations(dealerForm, validations);
        const hasErrors: Array<boolean> = Object.keys(errors).map((err) => !(errors[err].length === 0));
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations && !hasErrors.includes(true)) {
            try {
                const response = await DealerService.createDealer(dealerForm as ICreateDealerRequest);
                if (response.success) {
                    setOpenModal(!openModal);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(prev => ({
                        ...prev,
                        ...errors
                    }));
                    response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                    if (response.httpStatusCode === 500) setUnexpectedErrorModal(!unexpectedErrorModal);
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            setErrors(prev => ({
                ...prev,
                ...errors
            }));
            console.error("validations not passed");
        }
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Create",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: "/dealers",
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setUnexpectedErrorModal(!unexpectedErrorModal),
        },
    ];
    //#endregion

    return (
        <Layout title={"Create Dealer"} backHistory={true} buttons={buttons}>
            <div className={styles.layout}>
                <div className={styles.layout_area}>
                    <div className="whitebox noPadding">
                        <ATSInfo type={ATSInfoType.DEFAULT} content="After creating this dealer, a unique token will be autogenerated" />
                    </div>
                </div>
                <div className={styles.layout_area}>
                    <div className="whitebox hasScroll">
                        <form className="form">
                            <fieldset className="form_fieldset maxWidth">
                                <ATSTextInput
                                    label="Name"
                                    name="name"
                                    handleChange={handleInputChange}
                                    value={dealerForm.name}
                                    error={errors && errors.name ? errors.name : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                    required
                                />
                                <ATSEmailInput
                                    label="Email"
                                    name="email"
                                    handleChange={handleInputChange}
                                    value={dealerForm.email}
                                    error={errors && errors.email ? errors.email : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                    required
                                />
                                <ATSTextInput
                                    label="Address Line 1"
                                    name="addressLine1"
                                    handleChange={handleInputChange}
                                    value={dealerForm.addressLine1}
                                    error={errors && errors.addressLine1 ? errors.addressLine1 : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                    required
                                />
                                <ATSTextInput
                                    label="Address Line 2"
                                    name="addressLine2"
                                    handleChange={handleInputChange}
                                    value={dealerForm.addressLine2}
                                    error={errors && errors.addressLine2 ? errors.addressLine2 : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                />
                                <ATSTextInput
                                    label="City"
                                    name="city"
                                    handleChange={handleInputChange}
                                    value={dealerForm.city}
                                    error={errors && errors.city ? errors.city : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                    required
                                />
                                <ATSSelect
                                    name="country"
                                    options={countriesList ?? []}
                                    placeholder="Select Country"
                                    label="Country"
                                    handleChange={handleInputChange}
                                    setErrorMessage={handleErrorsChange}
                                    error={errors && errors.country ? errors.country : []}
                                    required
                                />
                                <div className="group c1x1">
                                    <ATSZipCodeInput
                                        label="Zip Code"
                                        name="zip"
                                        country={(dealerForm.country && countriesList) && countriesList.filter(state => state.value === dealerForm.country)[0]?.code}
                                        handleChange={handleInputChange}
                                        value={dealerForm.zip}
                                        error={errors && errors.zip ? errors.zip : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        required
                                    />

                                    <ATSSelect
                                        name="state"
                                        options={stateList ?? []}
                                        placeholder="Select State"
                                        label="State"
                                        handleChange={handleInputChange}
                                        setErrorMessage={handleErrorsChange}
                                        error={errors && errors.state ? errors.state : []}
                                        resetValues={resetStates}
                                        required
                                    />

                                </div>
                                <ATSPhoneInput
                                    label="Phone number"
                                    name="phone"
                                    handleChange={handleInputChange}
                                    value={dealerForm.phone}
                                    error={errors && errors.phone ? errors.phone : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                    required
                                />
                                <ATSTextInput
                                    label="Contact name"
                                    name="contactName"
                                    handleChange={handleInputChange}
                                    value={dealerForm.contactName}
                                    error={errors && errors.contactName ? errors.contactName : []}
                                    setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    maxLengthProps={100}
                                    required
                                />
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            {openModal && (
                <ATSModal
                    title="Successfully created"
                    icon="icon-check_circle_fill success"
                    bodyText={["The dealer was successfully created."]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => navigate("/dealers", { replace: true })}
                    buttons={buttonsModal}
                />
            )}
            {unexpectedErrorModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={[
                        "Sorry, the dealer was NOT created successfully.",
                        "There was an unexpected error , check the information and try again.",
                        " If the problem persist call an administrator",
                    ]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setUnexpectedErrorModal(!unexpectedErrorModal)}
                    buttons={buttonsErrorModal}
                />
            )}
        </Layout>
    );
}

export default NewDealer;
