import { ReactElement, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { ISingleDealerData } from "../../../services/interfaces/serviceResponses/dealer/ISingleDealerResponse";
import Layout from "../../../components/Layout";
import ATSList from "../../../components/Shared/ATSList";
import DealerService from "../../../services/entitiesServices/DealerService";
import styles from "./SingleDealer.module.scss";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import { PhoneNumFormatter } from "../../../components/form/ATSPhoneInput/phonenumberinfo";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import StatesService from "../../../services/entitiesServices/StatesService";
import { SelectOption } from "../../../components/form/ATSSelect";
import { formatComment } from "../../../helpers/helpers";

export default function SingleDealer(): ReactElement {
    const dealerInit = {
        dealer: {
            id: 0,
            dealerName: "",
            token: "",
            contactName: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            state: "",
            city: "",
            phone: "",
            comments: "",
            zip: "",
            countryId: 0,
            countryName: "",
        },
        payments: [],
        students: [],
    };

    const { dealerId } = useParams();
    const navigate = useNavigate();
    const studentColumnsToIgnore = ["startTimeMinute", "id"];
    const paymentColumnsToIgnore = ["id", "slots", "methodId"];

    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [response, setResponse] = useState<ISingleDealerData>(dealerInit);
    const [stateList, setStateList] = useState<SelectOption[]>([]);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(true);

    const [iconTitle, setIconTitle] = useState<string>("Copy token");
    const phoneFormatter = new PhoneNumFormatter();

    useEffect(() => {
        loadDealer();
    }, []);

    useEffect(() => {
        // -- Call only once when dealer is loaded
        if (response !== dealerInit) {
            getStateList();
        }
    }, [response]);

    const loadDealer = async () => {
        setLayoutLoading(true);
        const res = await DealerService.getDealer(dealerId);
        if (res.success) setResponse(res.response);
        setLayoutLoading(false);
    };

    const handleCommentChange = async (value: string) => {
        const res = await DealerService.updateDealerComment({ dealerId: response.dealer.id, comments: value });
        if (res && res.success) loadDealer();
        else {
            singleDealerModals.error.bodyText = [res.message];
            setModalConfig(singleDealerModals.error);
            setToggleModal(true);
        }
    };

    async function handleGenerateToken() {
        preLoading();
        const res = await DealerService.generateToken({ dealerId: response.dealer.id });
        if (res && res.success) {
            closeModalAndReload();
        } else {
            singleDealerModals.error.bodyText = [res.message];
            setModalConfig(singleDealerModals.error);
            setToggleModal(true);
        }
    }

    async function getStateList() {
        const res = await StatesService.getStates();
        if (res && res.success)
            setStateList(
                res.response.items.map(state => {
                    if (state.code === response?.dealer.state) {
                        return { value: state.code, label: state.name, default: true };
                    } else return { value: state.code, label: state.name };
                })
            );
    }

    function closeModalAndReload() {
        setToggleModal(false);
        loadDealer();
    }

    function onRowClickHandler(event, id, entity) {
        navigate(`/${entity}/${id}`);
    }

    function preLoading() {
        setModalConfig(singleDealerModals.loading);
        setToggleModal(true);
    }

    function getStateDescription(stateCode: string) {
        return stateList.find(state => state.value === stateCode)?.label;
    }

    const buttons: Array<IATSButtons> = [
        {
            cta: "Edit",
            type: ATSTypeButton.NEGATIVE,
            url: `/dealers/edit/${response.dealer.id}`,
        },
    ];

    const okButtonModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            action: closeModalAndReload,
        },
    ];

    const tokenButtonModal = [
        {
            cta: "No",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
        {
            cta: "Yes",
            type: ATSTypeButton.SUCCESS,
            action: handleGenerateToken,
        },
    ];

    const singleDealerModals = {
        comment: {
            commentValue: response.dealer.comments,
            type: ATSTypeModal.COMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                handleCommentChange(value);
                setToggleModal(false);
            },
        },
        generateToken: {
            title: "YOU WILL CREATE A NEW TOKEN FOR THIS DEALER",
            type: ATSTypeModal.DEFAULT,
            bodyText: ["Are you sure?"],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: tokenButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                setToggleModal(false);
            },
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                setToggleModal(false);
            },
        },
        loading: {
            tilte: "Loading...",
            type: ATSTypeModal.LOADING,
            bodyText: ["Please wait ..."],
            noClose: true,
        },
    };

    return (
        <Layout
            title={response ? response.dealer.dealerName : ""}
            backHistory={true}
            backLabel="Dealers"
            {...{ buttons }}
            isLoading={layoutLoading}>
            {response?.dealer && (
                <div className={styles.layout}>
                    <div className={styles.token}>
                        <p className={styles.token_info}>
                            <span>Token</span>
                            <i
                                className="icon-content_copy"
                                title={iconTitle}
                                onClick={e => {
                                    navigator.clipboard.writeText(response.dealer.token);
                                    setIconTitle("Copied!");
                                    setTimeout(() => setIconTitle("Copy token"), 1500);
                                }}></i>
                            <i
                                className="icon-autorenew"
                                title="Generate new token"
                                onClick={() => {
                                    setModalConfig(singleDealerModals.generateToken);
                                    setToggleModal(true);
                                }}></i>
                        </p>
                        <p className={styles.token_code}>{response.dealer.token}</p>
                    </div>

                    {response.dealer.comments && response.dealer.comments?.length > 0 ? (
                        <div className="whitebox">
                            <div className="whitebox_content">
                                <h3 className="form_label">
                                    Comments
                                    <button
                                        onClick={() => {
                                            setModalConfig(singleDealerModals.comment);
                                            setToggleModal(true);
                                        }}>
                                        <i className={`icon-edit cool ${styles.iconEditComment}`}></i>
                                    </button>
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.dealer.comments) }} />
                            </div>
                        </div>
                    ) : (
                        <div className="whitebox centered">
                            <ATSButton
                                cta="Add comment"
                                iconClass="icon-add cool"
                                type={ATSTypeButton.FORM}
                                action={() => {
                                    setModalConfig(singleDealerModals.comment);
                                    setToggleModal(true);
                                }}
                            />
                        </div>
                    )}
                    <div className="whitebox">
                        <div className="whitebox_content">
                            <ul className="whitebox_list">
                                {response.dealer && <ATSInfoItem title={"CONTACT NAME"} value={response.dealer.contactName} />}
                                {response.dealer && <ATSInfoItem title={"EMAIL"} value={response.dealer.email} />}
                                {response.dealer && <ATSInfoItem title={"ADDRESS LINE 1"} value={`${response.dealer.addressLine1}`} />}
                                {response.dealer && response.dealer.addressLine2 && (
                                    <ATSInfoItem title={"ADDRESS LINE 2"} value={`${response.dealer.addressLine2}`} />
                                )}
                                <ATSInfoItem title={"CITY"} value={response.dealer.city ?? "-"} />
                                <ATSInfoItem title={"COUNTRY"} value={response.dealer.countryName} />
                                <ATSInfoItem title={"STATE"} value={response.dealer.state ? getStateDescription(response.dealer.state) : "-"} />
                                <ATSInfoItem title={"ZIP CODE"} value={response.dealer.zip ?? "-"} />
                                {response.dealer && <ATSInfoItem title={"PHONE"} value={phoneFormatter.format(response.dealer.phone)} />}
                            </ul>
                        </div>
                    </div>

                    <div className="whitebox has_heading">
                        <div className="whitebox_heading">
                            <i className="icon-credit_card"></i>
                            <h2 className="whitebox_heading_title">Latest Payments</h2>
                            {response.payments && response.payments.length > 0 ? (
                                //navigate to payments list filtered by this dealer
                                <NavLink to={`/payments?search=${response.dealer.dealerName}`}>See more</NavLink>
                            ) : (
                                //navigate to full payments list
                                <NavLink to={`/payments`}>See all</NavLink>
                            )}
                        </div>
                        <div className="whitebox_content">
                            {response.payments && response.payments.length > 0 ? (
                                <ATSList
                                    key="payments"
                                    dataSet={response.payments}
                                    entity="payments"
                                    onClickHandler={onRowClickHandler}
                                    ignoredColumns={paymentColumnsToIgnore}
                                />
                            ) : (
                                <span>No payments available</span>
                            )}
                        </div>
                    </div>

                    <div className="whitebox has_heading">
                        <div className="whitebox_heading">
                            <i className="icon-school"></i>
                            <h2 className="whitebox_heading_title">Latest Students</h2>
                            {response.students && response.students.length > 0 ? (
                                //navigate to students list filtered by this dealer
                                <NavLink to={`/students?searchDealer=${response.dealer.dealerName}`}>See more</NavLink>
                            ) : (
                                //navigate to full students list
                                <NavLink to={`/students`}>See all</NavLink>
                            )}
                        </div>
                        <div className="whitebox_content">
                            {response.students && response.students.length > 0 ? (
                                <ATSList
                                    key="students"
                                    dataSet={response.students}
                                    entity="students"
                                    onClickHandler={onRowClickHandler}
                                    ignoredColumns={studentColumnsToIgnore}
                                />
                            ) : (
                                <span>No students available</span>
                            )}
                        </div>
                    </div>
                    {toggleModal && <ATSModal {...{ ...modalConfig }} />}
                </div>
            )}
            {!response?.dealer && <h1> Dealer not found! </h1>}
        </Layout>
    );
}
