import React from "react";

function ATSInfoItem({ title, value }) {
    return (
        <li>
            <h4 className="form_label">{title}</h4>
            <p>{value}</p>
        </li>
    );
}

export default ATSInfoItem;
