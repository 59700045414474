import { useEffect, useState } from "react";
import { ICourse } from "../../../../services/interfaces/ICourse";
import { useNavigate } from "react-router-dom";
import { AddPaymentRequest, AddPaymentResponse } from "../../../../services/interfaces/IPayments";
import { currencyDollarUS, formatDateFull, getStartTime, timeConvert } from "../../../../helpers/helpers";
import { ISingleDealerData } from "../../../../services/interfaces/serviceResponses/dealer/ISingleDealerResponse";
import PurchaseComponent from "./Purchase";
import TransactionComponent from "./Transaction";
import ATSInfo, { ATSInfoType } from "../../../../components/Shared/ATSInfo";
import ATSVirtualList from "../../../../components/Shared/ATSVirtualList";
import styles from "./SuccessPayment.module.scss";

interface ISuccesPaymentProps {
    responsePayment: AddPaymentResponse | null;
    prevState: AddPaymentRequest;
    courseInstance: ICourse | null;
    dealer?: ISingleDealerData;
}

function SuccesPayment(props: ISuccesPaymentProps) {
    const { responsePayment, prevState, courseInstance, dealer } = props;
    const navigate = useNavigate();
    const [purchaseInfo, setPurchaseInfo] = useState<Array<any>>();
    const [transaction, setTransaction] = useState<Array<any>>();

    useEffect(() => {
        if (!courseInstance || !prevState || !responsePayment || !dealer) return;
        setPurchaseInfo([
            {
                label: "Seats",
                value: ` x ${prevState.slots}`,
            },
            {
                label: "Course",
                value: courseInstance.courseName,
            },
            {
                label: "Start Date",
                value: `${new Date(courseInstance.date).toLocaleDateString("en-US")}`,
            },
            {
                label: "Time",
                value: `${timeConvert(getStartTime(courseInstance.startTime, courseInstance.startTimeMinute))}`,
            },
            {
                label: "Location",
                value: courseInstance.location,
            },
            {
                label: "Price",
                value: prevState.slots ? currencyDollarUS.format(prevState.slots * courseInstance.price) : "",
            },
        ]);

        setTransaction([
            {
                label: "Token",
                value: responsePayment.stripePaymentId,
            },
            {
                label: "Paid Date",
                value: `${formatDateFull(responsePayment.date)}`,
            },
            {
                label: "Status",
                value: responsePayment.pending ? "Pending" : "Paid",
            },
            {
                label: "Dealer",
                value: dealer.dealer.dealerName,
            },
            {
                label: "Gateway",
                value: "Web Sale",
            },
        ]);
    }, [responsePayment, prevState, courseInstance]);

    function onRowClickHandler(event, id) {
        navigate(`/students/${id}`);
    }
    return (
        <div className={styles.layout}>
            <div className={styles.layout_aside}>
                <TransactionComponent transactionData={transaction} />
            </div>
            <div className={styles.layout_content}>
                <ATSInfo
                    type={responsePayment && responsePayment.pending ? ATSInfoType.WARNING : ATSInfoType.SUCCESS}
                    title="Thanks for your order!"
                    content={
                        responsePayment && responsePayment.pending
                            ? "Your payment is pending."
                            : "Your payment was successful, and your order is complete."
                    }
                />
                {purchaseInfo && <PurchaseComponent purchaseData={purchaseInfo} />}
                <div className="whitebox has_heading">
                    <div className="whitebox_heading">
                        <i className="icon-school"></i>
                        <h2 className="whitebox_heading_title">Students</h2>
                    </div>
                    <div className="whitebox_content">
                        {responsePayment && (
                            <ATSVirtualList
                                dataSet={responsePayment.students}
                                onRowClickHandler={onRowClickHandler}
                                noData={false}
                                resetFilter={() => {}}
                                ignoreColumns={["id", "statusName"]}
                                entity="students"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccesPayment;
