import React, { ReactElement } from "react";
import { formatDate, formatNumber, getStartTime, timeConvert } from "../../../../helpers/helpers";
import { PhoneNumFormatter } from "../../../form/ATSPhoneInput/phonenumberinfo";
import ATSBadge, { ATSTypeBadge } from "../../ATSBadge";
import styles from "./ATSVirtualListRow.module.scss";

export default function ATSVirtualListRow(props): ReactElement {
    const { data, ignoredColumns, entity } = props;

    const phoneFormatter = new PhoneNumFormatter();

    //Save a copy of "data" to keep both dataSets (full(data) and cleaned(dataCleaned))
    const dataCopy = { ...data };
    const dataCleaned = cleanData(dataCopy);

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    // Remove ignored columns from dataSet
    function cleanData(parameter) {
        for (const index in ignoredColumns) {
            const colName = ignoredColumns[index];
            delete parameter[colName];
        }
        return parameter;
    }

    function seatsHandler(current, max, min, status) {
        if (status !== "Cancel") {
            if (current < min) return <ATSBadge type={ATSTypeBadge.DANGER} content={"BELOW MIN."} />;
            else if (current === max) return <ATSBadge type={ATSTypeBadge.SUCCESS} content={"FULL"} />;
            else return;
        } else return;
    }

    const paymentStatus = {
        Paid: <i className={`icon-check_circle ${styles.iconPayment} ${styles.paid}`}></i>,
        Pending: <i className={`icon-pending ${styles.iconPayment} ${styles.pending}`}></i>,
        Refunded: <i className={`icon-undo ${styles.iconPayment} ${styles.refunded}`}></i>,
        "Partial Refund": <i className={`icon-undo ${styles.iconPayment} ${styles.pending}`}></i>,
        Rejected: <i className={`icon-cancel ${styles.iconPayment} ${styles.refunded}`}></i>,
    };

    return (
        <>
            {dataCleaned && Object.keys(dataCleaned).length > 0 ? (
                Object.keys(dataCleaned).map(key => {
                    if (key === "startTime") {
                        return <td key={`${data["id"]}-${key}`}> {timeConvert(getStartTime(data.startTime, data.startTimeMinute))}</td>;
                    } else if (key === "seats") {
                        return (
                            <React.Fragment key={`${data["id"]}-${key}`}>
                                <td>
                                    {formatNumber(data["occupiedSeats"])}/{formatNumber(dataCleaned[key])}
                                </td>
                                <td className={styles.badgePadding}>
                                    {seatsHandler(data.occupiedSeats, data.seats, data.minimumSeats, data.courseStatusName)}
                                </td>
                            </React.Fragment>
                        );
                    } else if (entity === "students" && key === "statusStudentName" && dataCleaned["statusStudentName"] === "Approved") {
                        return (
                            <td key={`${data["id"]}-${key}`} className={`${styles.approved} ${styles.dot} ${styles.boldUppercase}`}>
                                {dataCleaned[key]}
                            </td>
                        );
                    } else if (entity === "students" && key === "statusStudentName" && dataCleaned["statusStudentName"] === "Pending") {
                        return (
                            <td key={`${data["id"]}-${key}`} className={`${styles.pending} ${styles.dot} ${styles.boldUppercase}`}>
                                {dataCleaned[key]}
                            </td>
                        );
                    } else if (entity === "students" && key === "statusStudentName" && dataCleaned["statusStudentName"] === "Rejected") {
                        return (
                            <td key={`${data["id"]}-${key}`} className={`${styles.rejected} ${styles.dot} ${styles.boldUppercase}`}>
                                {dataCleaned[key]}
                            </td>
                        );
                    } else if (entity === "students" && key === "statusStudentName" && dataCleaned["statusStudentName"] === "Refunded") {
                        return (
                            <td key={`${data["id"]}-${key}`} className={`${styles.refunded} ${styles.dot} ${styles.boldUppercase}`}>
                                {dataCleaned[key]}
                            </td>
                        );
                    } else if (entity === "students" && (key === "phone" || key === "phoneNumber")) {
                        return <td key={`${data["id"]}-${key}`}>{phoneFormatter.format(dataCleaned[key])}</td>;
                    } else if (entity === "payments" && key === "statusName") {
                        return (
                            <td key={`${data["id"]}-${key}`}>
                                {paymentStatus[`${data[key]}`]}
                                {dataCleaned[key]}
                            </td>
                        );
                    } else if (entity === "payments" && key === "amount") {
                        return <td key={`${data["id"]}-${key}`}>{dollarUS.format(dataCleaned[key])}</td>;
                    } else if (entity === "payments" && key === "courseName") {
                        return (
                            <td key={`${data["id"]}-${key}`}>
                                {data["slots"]} x {dataCleaned[key]}
                            </td>
                        );
                    } else if (
                        entity === "payments" &&
                        key === "token" &&
                        dataCleaned["token"] === "" &&
                        data["methodId"] === 3 &&
                        data["methodName"].toLowerCase() === "manual"
                    ) {
                        return <td key={`${data["id"]}-${key}`}>MANUAL ENROLLMENT</td>;
                    } else if (entity === "dealers" && key === "phone") {
                        return <td key={`${data["id"]}-${key}`}>{phoneFormatter.format(dataCleaned[key])}</td>;
                    } else if (key === "date") {
                        return <td key={`${data["id"]}-${key}`}>{formatDate(dataCleaned[key])}</td>;
                    } else {
                        return <td key={`${data["id"]}-${key}`}>{dataCleaned[key]}</td>;
                    }
                })
            ) : (
                <></>
            )}
        </>
    );
}
