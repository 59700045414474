import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";

export class ReportsService extends ApiBaseService {
    public static async getGeneralReport(body: any): Promise<IResponse<Blob>> {
        const response = await this.securedPost<Blob>(`report/generalReport`, body);
        if (response != null && !response.message) {
            return response;
        } else if (response.message) {
            console.error("Error: ", response.message);
            return response;
        } else {
            console.error("Error: Something unexpected happened");
            return response;
        }
    }
    public static async getDealerReport(body: any): Promise<IResponse<Blob>> {
        const response = await this.securedPost<Blob>(`report/dealerReport`, body);
        if (response != null && !response.message) {
            return response;
        } else if (response.message) {
            console.error("Error: ", response.message);
            return response;
        } else {
            console.error("Error: Something unexpected happened");
            return response;
        }
    }
    public static async getStateReport(body: any): Promise<IResponse<Blob>> {
        const response = await this.securedPost<Blob>(`report/stateReport`, body);
        if (response != null && !response.message) {
            return response;
        } else if (response.message) {
            console.error("Error: ", response.message);
            return response;
        } else {
            console.error("Error: Something unexpected happened");
            return response;
        }
    }
}
export default ReportsService;
