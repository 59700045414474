import ATSInfoItem from "../../../../../components/Shared/ATSInfoItem";

interface IPurchaseComponent {
    purchaseData: Array<{ label: string, value: any; }>;
}

function PurchaseComponent(props: any) {
    const { purchaseData } = props;
    return (
        <div className="whitebox has_heading">
            <div className="whitebox_heading">
                <i className="icon-credit_card"></i>
                <h2 className="whitebox_heading_title">Purchase</h2>
            </div>
            <div className="whitebox_content">
                <ul className="whitebox_list inColumns">
                    {purchaseData && purchaseData.map((data, i) => <ATSInfoItem title={data.label} value={data.value} key={`payment_${data.label}_${i}`} />)}
                </ul>
            </div>
        </div>
    );
}

export default PurchaseComponent;