import { ReactElement, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "./ATSButton.module.scss";

export interface IATSButtons {
    cta: string;
    type: ATSTypeButton;
    url?: string;
    iconClass?: string;
    action?: () => void;
    disabled?: boolean;
    loading?: boolean;
    isActive?: boolean;
}

export enum ATSTypeButton {
    DEFAULT = "default",
    FORM = "form",
    CARD = "card",
    POSITIVE = "positive",
    OUTPOSITIVE = "outpositive",
    OUTTHIN = "outthin",
    NEGATIVE = "negative",
    OUTNEGATIVE = "outnegative",
    SUCCESS = "success",
    OUTSUCCESS = "outsuccess",
    DANGER = "danger",
    OUTDANGER = "outdanger",
    LIST = "list",
    WARNING = "warning",
    OUTWARNING = "outwarning",
}

/**
 * Set new ATS button component, see the component props for more information
 * @param  cta: string,
 * @param  type: ATSTypeButton,
 * @param  url: string, -> optional if you don't provide a action the buttons default's use a url
 * @param  iconClass: string, -> optional
 * @param  action: () => void -> optional if you give an action, the url isn't used
 * @param  disabled : boolean -> optional disable button action
 * @param loading : boolean -> optional state loading animation action
 * @param isActive : boolean -> optional
 */
function ATSButton(props: IATSButtons): ReactElement {
    const { url, iconClass, cta, type, action, disabled, loading, isActive } = props;

    return (
        <>
            {action ? (
                <button
                    key={url}
                    className={`${styles.btn} ${type !== ATSTypeButton.DEFAULT ? styles[type] : ""} ${loading ? styles.loading : ""} ${
                        isActive ? styles.active : ""
                    } print-hidden`}
                    onClick={e => {
                        e.preventDefault();
                        action();
                    }}
                    disabled={disabled}>
                    {iconClass && <i className={iconClass}></i>}
                    <span>{cta}</span>
                </button>
            ) : (
                <NavLink
                    key={url}
                    className={`${styles.btn} ${styles[type]} ${disabled && styles.disabled} ${loading ? styles.loading : ""}`}
                    to={`${url && url}`}>
                    {iconClass && <i className={iconClass}></i>}
                    <span>{cta}</span>
                </NavLink>
            )}
        </>
    );
}

export default ATSButton;
