import Dashboard from "../Pages/Dashboard";
import SingleDealer from "../Pages/Dealers/SingleDealer";
import CoursesList from "../Pages/Courses/CoursesList";
import SingleCourse from "../Pages/Courses/SingleCourse";
import StudentsList from "../Pages/Students/StudentsList";
import SingleStudent from "../Pages/Students/SingleStudent";
import PaymentsList from "../Pages/Payments/PaymentsList";
import SinglePayment from "../Pages/Payments/SinglePayment";
import Reports from "../Pages/Reports";
import Login from "../Pages/Login";
import NewDealer from "../Pages/Dealers/NewDealer";
import DealersList from "../Pages/Dealers/DealersList";
import NotFound from "../Pages/NotFound";
import CreateCourse from "../Pages/Courses/CreateCourse";
import Playground from "../Pages/Playground";
import EnrollStudents from "../Pages/Students/EnrollStudents";
import EditDealer from "../Pages/Dealers/EditDealer";
import EditStudent from "../Pages/Students/EditStudent";
import MoveStudent from "../Pages/Students/MoveStudent";
import RefundStudent from "../Pages/Students/RefundStudent";
import RefundPayment from "../Pages/Payments/RefundPayment";
import EditCourse from "../Pages/Courses/EditCourse";
import UserSettings from "../Pages/UserSettings";
import RescheduleCourse from "../Pages/Courses/RescheduleCourse";
import RefundSuccess from "../Pages/Payments/RefundPayment/RefundSuccess";
import RefundFail from "../Pages/Payments/RefundPayment/RefundFail";
import RefundStudentSuccess from "../Pages/Students/RefundStudent/RefundStudentSuccess";
import RefundStudentFail from "../Pages/Students/RefundStudent/RefundStundentFail";
import ForgotPassword from "../Pages/Password/ForgotPassword";
import ResetPassword from "../Pages/Password/ResetPassword";
import UserPassword from "../Pages/Password/UserPassword";

export interface IRoute {
    url: string;
    label?: string;
    iconClass?: string;
    isPublic: boolean;
    showInSidebar: boolean;
    positionSidebar?: sideBarPosition;
    element: JSX.Element;
}

export enum sideBarPosition {
    TOP = "top",
    BOTTOM = "bottom",
}

export const routes = Object.freeze<IRoute[]>([
    {
        url: "login",
        label: "Log In",
        isPublic: true,
        showInSidebar: false,
        positionSidebar: sideBarPosition.TOP,
        element: <Login />,
    },
    {
        url: "password/forgot",
        label: "Forgot password",
        isPublic: true,
        showInSidebar: false,
        positionSidebar: sideBarPosition.TOP,
        element: <ForgotPassword />,
    },
    {
        url: "password/reset",
        label: "Reset password",
        isPublic: true,
        showInSidebar: false,
        positionSidebar: sideBarPosition.TOP,
        element: <ResetPassword />,
    },
    {
        url: "dashboard",
        label: "Dashboard",
        iconClass: "icon-dashboard",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.TOP,
        element: <Dashboard />,
    },
    {
        url: "courses",
        label: "Courses",
        iconClass: "icon-menu_book",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.TOP,
        element: <CoursesList />,
    },
    {
        url: "courses/:courseId",
        isPublic: false,
        showInSidebar: false,
        element: <SingleCourse />,
    },
    {
        url: "/courses/edit/:courseId",
        isPublic: false,
        showInSidebar: false,
        element: <EditCourse />,
    },
    {
        url: "/courses/reschedule/:courseId",
        isPublic: false,
        showInSidebar: false,
        element: <RescheduleCourse />,
    },
    {
        url: "courses/new",
        isPublic: false,
        showInSidebar: false,
        element: <CreateCourse />,
    },
    {
        url: "students",
        label: "Students",
        iconClass: "icon-school",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.TOP,
        element: <StudentsList />,
    },
    {
        url: "students/:studentId",
        isPublic: false,
        showInSidebar: false,
        element: <SingleStudent />,
    },
    {
        url: "students/edit/:studentId",
        isPublic: false,
        showInSidebar: false,
        element: <EditStudent />,
    },
    {
        url: "students/enroll",
        isPublic: false,
        showInSidebar: false,
        element: <EnrollStudents />,
    },
    {
        url: "students/move/:studentId",
        isPublic: false,
        showInSidebar: false,
        element: <MoveStudent />,
    },
    {
        url: "students/:studentId/refund",
        isPublic: false,
        showInSidebar: false,
        element: <RefundStudent />,
    },
    {
        url: "students/:studentId/refund/success",
        isPublic: false,
        showInSidebar: false,
        element: <RefundStudentSuccess />,
    },
    {
        url: "students/:studentId/refund/fail",
        isPublic: false,
        showInSidebar: false,
        element: <RefundStudentFail />,
    },
    {
        url: "dealers",
        label: "Dealers",
        iconClass: "icon-group",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.TOP,
        element: <DealersList />,
    },
    {
        url: "dealers/:dealerId",
        isPublic: false,
        showInSidebar: false,
        element: <SingleDealer />,
    },
    {
        url: "dealers/new",
        isPublic: false,
        showInSidebar: false,
        element: <NewDealer />,
    },
    {
        url: "dealers/edit/:dealerId",
        isPublic: false,
        showInSidebar: false,
        element: <EditDealer />,
    },
    {
        url: "payments",
        label: "Payments",
        iconClass: "icon-credit_card",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.TOP,
        element: <PaymentsList />,
    },
    {
        url: "payments/:paymentId",
        isPublic: false,
        showInSidebar: false,
        element: <SinglePayment />,
    },
    {
        url: "payments/:paymentId/refunds",
        isPublic: false,
        showInSidebar: false,
        element: <RefundPayment />,
    },
    {
        url: "payments/:paymentId/refunds/success",
        isPublic: false,
        showInSidebar: false,
        element: <RefundSuccess />,
    },
    {
        url: "payments/:paymentId/refunds/fail",
        isPublic: false,
        showInSidebar: false,
        element: <RefundFail />,
    },
    {
        url: "reports",
        label: "Reports",
        iconClass: "icon-summarize",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.TOP,
        element: <Reports />,
    },
    {
        url: "settings",
        label: "User Settings",
        iconClass: "icon-settings",
        isPublic: false,
        showInSidebar: true,
        positionSidebar: sideBarPosition.BOTTOM,
        element: <UserSettings />,
    },
    {
        url: "changePassword",
        label: "ChangePassword",
        iconClass: "icon-settings",
        isPublic: false,
        showInSidebar: false,
        positionSidebar: sideBarPosition.BOTTOM,
        element: <UserPassword />,
    },
    {
        url: "404",
        label: "Not Found",
        isPublic: false,
        showInSidebar: false,
        element: <NotFound />,
    },
    {
        url: "playground",
        label: "Playground",
        isPublic: false,
        showInSidebar: false,
        element: <Playground />,
    },
]);
