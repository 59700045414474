import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { ICountriesResponse } from "../interfaces/serviceResponses/country/ICountriesResponse";

class CountriesService extends ApiBaseService {
    public static async getCountries(): Promise<IResponse<ICountriesResponse>> {
        const response = await this.securedGet<ICountriesResponse>(`country`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }
}

export default CountriesService;
