import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import ATSAlertBox, { ATSAlertBoxType } from "../../components/Shared/ATSAlertBox";
import { ATSTypeButton, IATSButtons } from "../../components/Shared/ATSButton";
import Layout from "../../components/Layout";

function NotFound(): ReactElement {
    const navigate = useNavigate();

    const buttonGoBack: Array<IATSButtons> = [
        {
            cta: "Go to Dashboard",
            type: ATSTypeButton.DANGER,
            action: () => navigate("/dashboard"),
        },
    ];
    return (
        <Layout title={""} backHistory={false} backLabel="Payment">
            <ATSAlertBox
                type={ATSAlertBoxType.ERROR}
                content="The page you requested could not be found. Please go back to the Dashboard"
                title="404"
                buttons={buttonGoBack}
            />
        </Layout>
    );
}

export default NotFound;
