import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICourseStudent } from "../../../services/interfaces/serviceResponses/course/ISingleCourseResponse";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { ATSProgressBar } from "../../../components/Shared/ATSProgressBar";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import { ICourse } from "../../../services/interfaces/ICourse";
import { currencyDollarUS, formatNumber, formatTextDate, getStartTime, timeConvert, formatComment } from "../../../helpers/helpers";
import Layout from "../../../components/Layout";
import ATSList from "../../../components/Shared/ATSList";
import CourseService from "../../../services/entitiesServices/CourseService";
import styles from "./SingleCourse.module.scss";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { DateTime } from "luxon";
import { ICourseInstanceDate } from "../../../services/interfaces/servicePostBody/course/ICourseAdd";
export default function SingleCourse(): ReactElement {
    const courseInit: ICourse = {
        id: 0,
        courseName: "",
        date: "",
        startTime: 0,
        startTimeMinute: 0,
        location: "",
        seats: 0,
        seatsOccupied: 0,
        price: 0,
        courseStatusName: "",
        days: 0,
        comments: "",
        cancelComments: "",
        minimumSeats: 0,
    };
    const { courseId } = useParams();
    const navigate = useNavigate();
    const studentColumnsToIgnore = ["id", "courseInstanceId", "isStudentCancelled", "statusId"];

    const [courseInstanceResponse, setCourseInstanceResponse] = useState<ICourse>(courseInit);
    const [courseInstanceStudentsResponse, setCourseInstanceStudentsResponse] = useState<ICourseStudent[]>([]);
    const [courseInstanceDate, setCourseInstanceDate] = useState<ICourseInstanceDate[]>([]);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(true);

    // -- Modals
    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    //

    useEffect(() => {
        loadCourse();
    }, []);

    const loadCourse = async () => {
        setLayoutLoading(true);
        const res = await CourseService.getCourse(courseId);
        if (res && res.response) {
            setCourseInstanceResponse(res.response.courseInstanceDataView);
            setCourseInstanceStudentsResponse(res.response.courseInstanceStudentDataViews);
            setCourseInstanceDate(res.response.courseInstanceDateDataViews);
        }
        setLayoutLoading(false);
    };

    const cancelCourse = async (id: number, comment: string) => {
        const res = await CourseService.cancelCourse({ courseInstanceId: id, cancelComments: comment });
        if (res && res.response && res.success === true && res.response[0].canceled === 1) {
            setModalConfig(singleCourseModals.cancelSuccess);
            setToggleModal(true);
        } else {
            singleCourseModals.error.bodyText = [res.response[0].message];
            setModalConfig(singleCourseModals.error);
            setToggleModal(true);
        }
    };

    function onRowClickHandler(event: React.MouseEvent<HTMLElement>, id: number): void {
        navigate(`/students/${id}`);
    }

    const handleCommentChange = async (value: string) => {
        const res = await CourseService.updateCourseComment({ courseInstanceId: courseInstanceResponse.id, comments: value });
        if (res && res.success) loadCourse();
        else {
            singleCourseModals.error.bodyText = [res.response[0].message];
            setModalConfig(singleCourseModals.error);
            setToggleModal(true);
        }
    };

    const validateCancelClass = () => {
        const rejectedStudents = courseInstanceStudentsResponse.filter(student => student.isStudentCancelled);

        if (courseInstanceStudentsResponse.length > 0 && !(rejectedStudents.length === courseInstanceStudentsResponse.length)) {
            setModalConfig(singleCourseModals.validateError);
            setToggleModal(true);
        } else {
            setModalConfig(singleCourseModals.confirmCancel);
            setToggleModal(true);
        }
    };

    const buttons: Array<IATSButtons> = [
        {
            cta: "Cancel Course",
            type: ATSTypeButton.DANGER,
            disabled: courseInstanceResponse.courseStatusName === "Cancel" || courseInstanceResponse.days < 1,
            action: () => validateCancelClass(),
        },
        {
            cta: "Reschedule",
            type: ATSTypeButton.NEGATIVE,
            disabled: courseInstanceResponse.courseStatusName === "Cancel" || courseInstanceResponse.days < 1,
            action: () => navigate(`/courses/reschedule/${courseId}`),
        },
        {
            cta: "Edit",
            type: ATSTypeButton.OUTNEGATIVE,
            disabled: courseInstanceResponse.days < 1,
            action: () => navigate(`/courses/edit/${courseId}`),
        },
    ];

    function closeModalAndReload() {
        setToggleModal(false);
        loadCourse();
    }

    const okButtonModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            action: closeModalAndReload,
        },
    ];

    const confirmCancelModalButtons: Array<IATSButtons> = [
        {
            cta: "No",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
        {
            cta: "Yes",
            type: ATSTypeButton.SUCCESS,
            action: () => {
                setModalConfig(singleCourseModals.cancelCourse);
                setToggleModal(true);
            },
        },
    ];

    const singleCourseModals = {
        comment: {
            commentValue: courseInstanceResponse.comments,
            type: ATSTypeModal.COMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                handleCommentChange(value);
                setToggleModal(false);
            },
        },
        validateError: {
            title: "Cancel course",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: ["To perform this action, first refund all the students or move them to another course."],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: closeModalAndReload,
        },
        confirmCancel: {
            title: `Are you sure you want to cancel the course?`,
            bodyText: ["By performing this action the course will be cancelled and removed from the website."],
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            buttons: confirmCancelModalButtons,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        cancelCourse: {
            title: "Please, leave a reason.",
            commentValue: courseInstanceResponse.cancelComments,
            type: ATSTypeModal.CANCELWITHCOMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                cancelCourse(courseInstanceResponse.id, value);
                setToggleModal(false);
            },
        },
        cancelSuccess: {
            title: "The course was successfully canceled",
            icon: "icon-check_circle success",
            type: ATSTypeModal.DEFAULT,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: closeModalAndReload,
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                setToggleModal(false);
            },
        },
        loadingReport: {
            tilte: "Loading...",
            type: ATSTypeModal.LOADING,
            noClose: true,
            bodyText: ["Please wait while the report is prepared"],
        },
    };

    const getFileName = () => {
        return DateTime.local().toISODate() + "_course-report.csv";
    };

    const { ref, url, download, name } = useDownloadFile({
        apiDefinition: () => CourseService.getCourseReport({ courseInstanceId: courseId }),
        preDownloading: () => {
            setModalConfig(singleCourseModals.loadingReport);
            setToggleModal(true);
        },
        postDownloading: () => setToggleModal(false),
        onError: () => {
            console.log("Error downloading file.");
            setToggleModal(false);
        },
        getFileName,
    });

    return (
        <>
            {courseInstanceResponse && (
                <Layout
                    title={courseInstanceResponse.courseName}
                    backLabel="Courses"
                    backHistory={true}
                    {...{ buttons }}
                    isLoading={layoutLoading}>
                    {courseInstanceResponse ? (
                        <div className={styles.layout}>
                            <div className={styles.layout_aside}>
                                <div
                                    className={`${styles.layout_aside_top} ${
                                        courseInstanceResponse.courseStatusName === "Cancel" && styles.fullProgressBar
                                    }`}>
                                    <ATSProgressBar
                                        entity="Course"
                                        current={courseInstanceResponse.seatsOccupied}
                                        total={courseInstanceResponse.seats}
                                    />

                                    {courseInstanceResponse.courseStatusName !== "Cancel" && (
                                        <div className="widget cool">
                                            {courseInstanceResponse.days >= 1 ? (
                                                <>
                                                    <h2 className="widget_title">{formatNumber(courseInstanceResponse.days)}</h2>
                                                    <p className="widget_text">
                                                        {courseInstanceResponse.days === 1 ? "day before beginning" : "days before beginning"}
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="widget_text">Course started</p>
                                                    <h2 className="widget_title">{courseInstanceResponse.date}</h2>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <>
                                    <div className="whitebox">
                                        <div className="whitebox_content">
                                            {courseInstanceResponse.comments?.length > 0 ? (
                                                <>
                                                    <h3 className="form_label">
                                                        Comments
                                                        <button
                                                            onClick={() => {
                                                                setModalConfig(singleCourseModals.comment);
                                                                setToggleModal(true);
                                                            }}>
                                                            <i className={`icon-edit cool ${styles.iconEditComment}`}></i>
                                                        </button>
                                                    </h3>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: formatComment(courseInstanceResponse.comments),
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                courseInstanceResponse.courseStatusName !== "Cancel" && (
                                                    <div className="add_comment_btn">
                                                        <ATSButton
                                                            cta="Add comment"
                                                            iconClass="icon-add cool"
                                                            type={ATSTypeButton.FORM}
                                                            action={() => {
                                                                setModalConfig(singleCourseModals.comment);
                                                                setToggleModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            {courseInstanceResponse.rescheduleComments && courseInstanceResponse.rescheduleComments?.length > 0 && (
                                                <>
                                                    <h3 className="form_label">Reschedule Comments</h3>
                                                    {courseInstanceResponse.rescheduleComments.map((comment, i) => {
                                                        return (
                                                            <p
                                                                key={"rescheduleComment" + i}
                                                                className={styles.rescheduleComment}
                                                                dangerouslySetInnerHTML={{ __html: formatComment(comment) }}
                                                            />
                                                        );
                                                    })}
                                                </>
                                            )}
                                            {courseInstanceResponse.cancelComments?.length > 0 && (
                                                <>
                                                    <h3 className="form_label">Cancel Comments</h3>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: formatComment(courseInstanceResponse.cancelComments),
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>

                                <div className="whitebox noPadding">
                                    <a href={url} download={name} className="hidden" ref={ref} />
                                    <ATSButton
                                        cta="Download Course Report"
                                        iconClass="icon-download cool"
                                        type={ATSTypeButton.CARD}
                                        action={download}
                                    />
                                </div>

                                <div className="whitebox has_heading">
                                    <div className="whitebox_heading">
                                        <i className="icon-menu_book"></i>
                                        <h2 className="whitebox_heading_title">Course information</h2>
                                    </div>
                                    <div className="whitebox_content">
                                        <ul className="whitebox_list">
                                            {courseInstanceResponse && (
                                                <>
                                                    {courseInstanceDate.length > 0 && (
                                                        <ATSInfoItem
                                                            title={`DATE AND TIME`}
                                                            value={courseInstanceDate
                                                                .map(
                                                                    item =>
                                                                        `${formatTextDate(item.date.toString())} ${timeConvert(
                                                                            getStartTime(item.startTime, item.startTimeMinute)
                                                                        )} - ${timeConvert(getStartTime(item.endTime, item.endTimeMinute))}`
                                                                )
                                                                .join("\r\n")}
                                                        />
                                                    )}
                                                    <ATSInfoItem title={`TOTAL SEATS`} value={courseInstanceResponse.seats} />
                                                    <ATSInfoItem title={`MINIMUM SEATS`} value={courseInstanceResponse.minimumSeats} />
                                                    <ATSInfoItem title={`LOCATION`} value={courseInstanceResponse.location} />
                                                    <ATSInfoItem title={`PRICE`} value={currencyDollarUS.format(courseInstanceResponse.price)} />
                                                    <ATSInfoItem title={`STATUS`} value={courseInstanceResponse.courseStatusName} />
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className={`whitebox has_heading ${styles.layout_content}`}>
                                <div className="whitebox_heading">
                                    <i className="icon-school"></i>
                                    <h2 className="whitebox_heading_title">Students</h2>
                                </div>
                                <div className="whitebox_content">
                                    {courseInstanceStudentsResponse && courseInstanceStudentsResponse.length > 0 ? (
                                        <ATSList
                                            dataSet={courseInstanceStudentsResponse}
                                            entity="students"
                                            onClickHandler={onRowClickHandler}
                                            ignoredColumns={studentColumnsToIgnore}
                                        />
                                    ) : (
                                        <span>No students available</span>
                                    )}
                                </div>
                            </div>

                            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
                        </div>
                    ) : (
                        <h1> Course not found! </h1>
                    )}
                </Layout>
            )}
        </>
    );
}
