import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ButtonNav.module.scss'

interface IButtonsProps {
    url: string,
    label: string,
    iconClass: string,
}

function ButtonNav(props: IButtonsProps): ReactElement {
    const { url, label, iconClass } = props

    return (
        <li>
            <NavLink className={({ isActive }) =>
                isActive ? `${styles.ats_aside_menu_item} ${styles.active}` : styles.ats_aside_menu_item} to={`/${url}`} >
                <i className={iconClass}></i>
                <span>{label}</span>
            </NavLink>
        </li>
    )
}

export default ButtonNav