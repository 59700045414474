import { ReactElement, useState } from "react";
import UserService from "../../../services/entitiesServices/UserService";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./ForgotPassword.module.scss";
import logo from "../../../assets/images/logo.jpg";
import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";

export default function ForgotPassword(props: any): ReactElement {
    const { pageData } = props;
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();

    const [formResetPasswordValues, setFormResetPasswordValues] = useState({
        userName: "",
        password: "",
    });

    function closeModal() {
        setToggleModal(false);
    }

    const okButtonModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            action: closeModal,
        },
    ];

    const listModals = {
        loading: {
            title: "Loading...",
            type: ATSTypeModal.LOADING,
            bodyText: ["Please wait ..."],
            noClose: true,
        },
        success: {
            title: "An email has been sent with instructions to reset your password",
            icon: "icon-check_circle success",
            type: ATSTypeModal.DEFAULT,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
        }
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setFormResetPasswordValues({ ...formResetPasswordValues, [name]: value });
        setError(null);
    };

    function preLoading() {
        setModalConfig(listModals.loading);
        setToggleModal(true);
    }

    const handleSubmit = async () => {
        preLoading();
        const formData = { ...formResetPasswordValues };
        try {
            const response = await UserService.resetPassword({
                userName: formData.userName
            });
            if (response.success) {
                setModalConfig(listModals.success);
                setToggleModal(true);
            } else {
                setToggleModal(false);
                console.log(response.message);
                setError(response.message);
            }
        } catch (event) {
            //do something
        }
    };

    return (
        <main className={styles.forgotPassword_main}>
            <div className={styles.forgotPassword_box}>
                <header className={styles.forgotPassword_box_header}>
                    <div className={styles.forgotPassword_box_identity}>
                        <img src={logo} alt="ats-logo" />
                    </div>
                    <div className={styles.forgotPassword_box_heading}>
                        <i className="icon-login success"></i>
                        <h1 className={styles.forgotPassword_box_title}>Forgot Password</h1>
                    </div>
                </header>
                <section className={styles.forgotPassword_box_content}>
                    <form className={`form ${styles.forgotPassword_form}`}>
                        <div className="form_item">
                            <label htmlFor="userName">username</label>
                            <input
                                placeholder="Username"
                                onChange={handleChange}
                                name="userName"
                                value={formResetPasswordValues.userName}
                                type="text"
                                id="userName"
                                className={`${styles.input}`}
                                maxLength={200}
                            />
                        </div>
                        {/* <div className="form_item">
                            <label htmlFor="confirm password">password</label>
                            <input
                                placeholder="password"
                                onChange={handleChange}
                                name="password"
                                value={formResetPasswordValues.password}
                                type="password"
                                id="password"
                                className={styles.input}
                                maxLength={200}
                            />
                        </div> */}
                        {error && (
                            <p className={styles.forgotPassword_error}>
                                <i className="icon-error"></i>
                                <span>{error}</span>
                            </p>
                        )}
                        <Link className={styles.forgotPassword_link} to="/login">
                            Back to Login
                        </Link>
                        <div className={styles.forgotPassword_btn_group}>
                            <ATSButton
                                cta="Reset Password"
                                type={ATSTypeButton.POSITIVE}
                                disabled={!formResetPasswordValues.userName ? true : false}
                                action={handleSubmit}
                            />
                        </div>
                    </form>
                    {toggleModal && <ATSModal {...{ ...modalConfig }} />}
                </section>
            </div>
        </main>
    );
}
