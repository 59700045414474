import { ReactElement, useEffect, useState } from "react";
import { ATSTypeButton, IATSButtons } from "../../components/Shared/ATSButton";
import ATSSelect, { SelectOption } from "../../components/form/ATSSelect";
import { objectValidations } from "../../helpers/helpers";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../components/Shared/ATSModal";
import { PhoneNumFormatter } from "../../components/form/ATSPhoneInput/phonenumberinfo";
import Layout from "../../components/Layout";
import ATSTextInput from "../../components/form/ATSTextInput";
import StatesService from "../../services/entitiesServices/StatesService";
import ATSEmailInput from "../../components/form/ATSEmailInput";
import ATSPhoneInput from "../../components/form/ATSPhoneInput";
import ATSZipCodeInput from "../../components/form/ATSZipCodeInput";
import ATSSpinner from "../../components/Shared/ATSSpinner";
import ATSInfoItem from "../../components/Shared/ATSInfoItem";
import styles from "./UserSettings.module.scss";
import UserService from "../../services/entitiesServices/UserService";
import { IUserProfileResponse } from "../../services/interfaces/serviceResponses/user/IUserProfileResponse";
import { IEditUserProfileRequest } from "../../services/interfaces/servicePostBody/user/IEditUserProfileRequest";
import { useNavigate } from "react-router-dom";
import CountriesService from "../../services/entitiesServices/CountriesService";

interface countriesList extends SelectOption {
    code: string;
}

function UserSettings(): ReactElement {
    const userInit: IUserProfileResponse = {
        firstName: "",
        lastName: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        state: "",
        phone: "",
        countryId: 0,
        countryName: ""
    };

    const userFormInit: IEditUserProfileRequest = {
        firstName: "",
        lastName: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        state: "",
        phone: "",
        countryId: 0
    };

    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [userForm, setUserForm] = useState<IEditUserProfileRequest>(userFormInit);
    const [errors, setErrors] = useState<Record<string, Array<string>>>({});
    const [countriesList, setCountriesList] = useState<countriesList[]>();
    const [stateList, setStateList] = useState<SelectOption[]>([]);
    const [resetStates, setResetStates] = useState<boolean>(false);
    const [response, setResponse] = useState<IUserProfileResponse>(userInit);
    const [loading, setLoading] = useState<boolean>(false);
    const [allStates, setAllStates] = useState<SelectOption[]>([]);

    const navigate = useNavigate();

    const phoneFormatter = new PhoneNumFormatter();

    useEffect(() => {
        loadUser();
        getAllStates();
    }, []);

    useEffect(() => {
        // -- Call only once when dealer is loaded
        if (response !== userInit) {
            getCountriesList();
        }
    }, [response]);

    useEffect(() => {
        if (!userForm.countryId) return;
        setResetStates(true);
        setStateList([]);
        setUserForm({ ...userForm, state: "" });
        getStateList(userForm.countryId);
    }, [userForm.countryId]);

    const loadUser = async () => {
        setLoading(true);
        const res = await UserService.getUserProfile();
        if (res.success) {
            const userFormData: IEditUserProfileRequest = {
                firstName: res.response.firstName,
                lastName: res.response.lastName,
                addressLine1: res.response.addressLine1,
                addressLine2: res.response.addressLine2 ?? '',
                city: res.response.city,
                zip: res.response.zip,
                state: res.response.state,
                phone: res.response.phone,
                email: res.response.email,
                countryId: res.response.countryId
            };
            setResponse(res.response);
            setUserForm(userFormData);
        }
        setLoading(false);
    };

    async function getAllStates() {
        const res = await StatesService.getStates();
        if (res && res.success) setAllStates(res.response.items.map(state => ({ value: state.code, label: state.name })));
    }

    async function getCountriesList() {
        const res = await CountriesService.getCountries();

        if (res && res.success) {
            if (res.response.items.length > 1) {
                setCountriesList(res.response.items.map(country => {
                    if (country.id === response?.countryId) {
                        return { value: country.id, label: country.name, code: country.code, default: true };
                    }
                    else {
                        return { value: country.id, label: country.name, code: country.code };
                    }

                }));
            } else {
                setCountriesList(res.response.items.map(country => ({ value: country.id, label: country.name, code: country.code, default: true })));
            }
        }
    }

    async function getStateList(countryId) {
        const res = await StatesService.getByCountry(countryId);
        if (res && res.success)
            setResetStates(false);
        setErrors(prev => ({
            ...prev,
            state: [],
        }));


        if (res.response.items.length > 1) {
            setStateList(
                res.response.items.map(state => {
                    if (state.code === response?.state) {
                        return { value: state.code, label: state.name, default: true };
                    } else return { value: state.code, label: state.name };
                })
            );
        } else {
            setStateList(res.response.items.map(state => ({ value: state.code, label: state.name, default: true })));
        }


    }

    const handleInputChange = (name: string, value: string | number): void => {
        setUserForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    async function handleSubmit() {
        const validations = [
            {
                key: "firstName",
                type: "string",
                required: true,
            },
            {
                key: "lastName",
                type: "string",
                required: true,
            },
            {
                key: "email",
                type: "string",
                required: true,
            },
            {
                key: "addressLine1",
                type: "string",
                required: true,
            },
            {
                key: "addressLine2",
                type: "string",
                required: false,
            },
            {
                key: "city",
                type: "string",
                required: true,
            },
            {
                key: "zip",
                type: "string",
                required: true,
            },
            {
                key: "state",
                type: "string",
                required: true,
            },
            {
                key: "phone",
                type: "string",
                required: true,
            },
            {
                key: "email",
                type: "string",
                required: true,
            },
        ];
        const properties = objectValidations(userForm, validations);
        const hasErrors: Array<boolean> = Object.keys(errors).map((err) => !(errors[err].length === 0));
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations && !hasErrors.includes(true)) {
            try {
                const response = await UserService.editUserProfile(userForm as IEditUserProfileRequest);
                if (response.success) {
                    setModalConfig(editUserModals.success);
                    setToggleModal(true);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(prev => ({
                        ...prev,
                        ...errors
                    }));
                    response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                    if (response.httpStatusCode === 500) {
                        setModalConfig(editUserModals.unexpectedError);
                        setToggleModal(true);
                    }
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            setErrors(prev => ({
                ...prev,
                ...errors
            }));
            console.error("validations not passed");
        }
    }

    function getStateDescription(stateCode: string) {
        return allStates.find(state => state.value === stateCode)?.label;
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Save Changes",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
        {
            cta: "Change Password",
            type: ATSTypeButton.OUTNEGATIVE,
            action: () => navigate(`/changePassword`),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: `/settings`,
            action: () => {
                closeAndReload();
            },
        },
    ];

    function closeAndReload() {
        setToggleModal(false);
        loadUser();
    }

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];
    const editUserModals = {
        success: {
            title: "Successfully updated",
            icon: "icon-check_circle_fill success",
            bodyText: ["The user was successfully updated."],
            type: ATSTypeModal.DEFAULT,
            buttons: buttonsModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => closeAndReload(),
        },
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [
                "Sorry, the user was NOT updated.",
                "There was an unexpected error, check the information and try again.",
                "If the problem persist call an administrator",
            ],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };
    //#endregion

    return (
        <Layout title={"User settings"} backHistory={false} buttons={buttons} userSettings={true}>
            {!loading ? (
                <div className={styles.layout}>
                    <div className={styles.layout_area}>
                        <div className="whitebox">
                            <ul className="whitebox_list">
                                <h2 className="form_title">Previous info</h2>
                                {response && (
                                    <>
                                        <ATSInfoItem title={"FIRST NAME"} value={response.firstName} />
                                        <ATSInfoItem title={"LAST NAME"} value={response.lastName} />
                                        <ATSInfoItem title={"EMAIL"} value={response.email ?? "-"} />
                                        <ATSInfoItem title={"ADDRESS LINE 1"} value={response.addressLine1 ?? "-"} />
                                        {response.addressLine2 && <ATSInfoItem title={"ADDRESS LINE 2"} value={response.addressLine2 ?? "-"} />}
                                        <ATSInfoItem title={"CITY"} value={response.city ?? "-"} />
                                        <ATSInfoItem title={"ZIP CODE"} value={response.zip ?? "-"} />
                                        <ATSInfoItem title={"COUNTRY"} value={response.countryName ?? "-"} />
                                        <ATSInfoItem title={"STATE"} value={response.state ? getStateDescription(response.state) : "-"} />
                                        <ATSInfoItem
                                            title={"PHONE NUMBER"}
                                            value={response.phone ? phoneFormatter.format(response.phone) : "-"}
                                        />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.layout_area}>
                        <div className="whitebox hasScroll">
                            <form className="form">
                                <fieldset className="form_fieldset maxWidth">
                                    <ATSTextInput
                                        label="First Name"
                                        name="firstName"
                                        handleChange={handleInputChange}
                                        value={userForm.firstName}
                                        error={errors && errors.firstName ? errors.firstName : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={200}
                                        required
                                    />
                                    <ATSTextInput
                                        label="Last Name"
                                        name="lastName"
                                        handleChange={handleInputChange}
                                        value={userForm.lastName}
                                        error={errors && errors.lastName ? errors.lastName : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={200}
                                        required
                                    />
                                    <ATSEmailInput
                                        label="Email"
                                        name="email"
                                        handleChange={handleInputChange}
                                        value={userForm.email}
                                        error={errors && errors.email ? errors.email : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={200}
                                        required
                                    />
                                    <ATSTextInput
                                        label="Address Line 1"
                                        name="addressLine1"
                                        handleChange={handleInputChange}
                                        value={userForm.addressLine1}
                                        error={errors && errors.addressLine1 ? errors.addressLine1 : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        required
                                    />
                                    <ATSTextInput
                                        label="Address Line 2"
                                        name="addressLine2"
                                        handleChange={handleInputChange}
                                        value={userForm.addressLine2 ?? ''}
                                        error={errors && errors.addressLine2 ? errors.addressLine2 : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                    />
                                    <ATSTextInput
                                        label="City"
                                        name="city"
                                        handleChange={handleInputChange}
                                        value={userForm.city}
                                        error={errors && errors.city ? errors.city : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSSelect
                                        name="countryId"
                                        options={countriesList ?? []}
                                        placeholder="Select Country"
                                        label="Country"
                                        handleChange={handleInputChange}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        error={errors && errors.country ? errors.country : []}
                                        required
                                    />
                                    <div className="group c1x1">
                                        <ATSZipCodeInput
                                            label="Zip Code"
                                            name="zip"
                                            country={(userForm.countryId && countriesList) ? countriesList.filter(state => state.value === userForm.countryId)[0]?.code : undefined}
                                            handleChange={handleInputChange}
                                            value={userForm.zip}
                                            error={errors && errors.zip ? errors.zip : []}
                                            setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                            required
                                        />
                                        <ATSSelect
                                            name="state"
                                            options={stateList}
                                            placeholder="Select State"
                                            label="State"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.state ? errors.state : []}
                                            resetValues={resetStates}
                                            required
                                        />
                                    </div>
                                    <ATSPhoneInput
                                        label="Phone number"
                                        name="phone"
                                        handleChange={handleInputChange}
                                        value={userForm.phone}
                                        error={errors && errors.phone ? errors.phone : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <ATSSpinner />
            )}
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default UserSettings;
