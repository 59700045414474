import { ReactElement } from "react"
import { NavLink } from "react-router-dom"
import styles from './ATSRequestsStatusCard.module.scss'

export interface IATSRequestsStatusCardProps {
    status: string,
    quantity: number,
    id: number
}


/**
 * Create ATS Requests Status Card component. 
 * @param status: string;
 * @param quantity: number;
 */
function ATSRequestsStatusCard(props: IATSRequestsStatusCardProps): ReactElement {
    const { status, quantity, id } = props
    return (
        <article className={`${styles.status_card} ${styles[status]}`}>
            <h3 className={styles.status_card_info}>
                <span>{quantity}</span>
                {status}
            </h3>
            <NavLink className={styles.status_card_link} state={{ filter: status }} to={`/students?studentStatusId=${id}`}>
                View All
            </NavLink>
        </article>
    );
}

export default ATSRequestsStatusCard