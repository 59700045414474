import { DateTime } from "luxon";

export const stringCounter = str => {
    return str.split("").reduce((total, letter) => {
        total[letter] ? total[letter]++ : (total[letter] = 1);
        return total;
    }, {});
};

export function formatDate(date: string): string {
    // CHECK IF UTC IS CORRECT
    const formattedDate = DateTime.fromISO(date).setZone("UTC").toFormat("MM/dd/yyyy");
    return formattedDate;
}

export function formatTextDate(date: string): string {
    // CHECK IF UTC IS CORRECT
    //WED , SEPTEMBER 21, 2022
    const formattedDate = DateTime.fromISO(date).setZone("UTC").toFormat("EEE, MMMM dd, yyyy");
    return formattedDate;
}

export function formatDateSlash(date: string): string {
    // CHECK IF UTC IS CORRECT
    const formattedDate = DateTime.fromISO(date).setZone("UTC").toFormat("yyyy-MM-dd");
    return formattedDate;
}

export function formatDateFull(date: string): string {
    const formattedDate = DateTime.fromISO(date).setZone("UTC").toFormat("MM/dd/yyyy hh:mm a");
    return formattedDate;
}

export function convertHours(hours: number, timezone: number) {
    const hoursOffset = getLocalHoursOffset();
    // -- Input hours - local timezone + specific(location) timezone
    const calculatedHours = hours - hoursOffset + timezone;

    return calculatedHours;
}

export function adjustDateToTimeZone(date: string, hours: number, minutes: number, timezone: number): string {
    const localTimeZoneHours = getLocalHoursOffset();
    const calculatedHours = localTimeZoneHours - timezone;

    const zone = calculatedHours < 0 ? `UTC${calculatedHours}` : `UTC+${calculatedHours}`;

    const dateObj = DateTime.fromISO(date).plus({ hours, minutes }).setZone(zone);
    const formattedDate = dateObj.toFormat("yyyy-MM-dd");
    return formattedDate;
}

export function getLocalHoursOffset() {
    const local = DateTime.local();
    const hoursOffset = local.offset / 60;
    return hoursOffset;
}

//Checks if the input number is below 10 and return it as 2 digit string
export function formatNumber(num: number): string {
    return `${num.toLocaleString().length === 1 ? "0" + num : num}`;
}

export function copyJSON(json) {
    return JSON.parse(JSON.stringify(json));
}

export const currencyDollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

//#region Object Validations
export interface IObjectValidationsProperties {
    key: string;
    type: string;
    required?: boolean;
}
interface IObjectValidationsReturn {
    hasRequiredInputs: boolean;
    passTypeValidations: boolean;
    inputs: Array<{ key: string; has: boolean; typeValid?: boolean; }>;
}
/**
 * Validate properties of an object
 * @param o object;
 * @param properties array; Must contain key and type for validate
 * @returns object;
 *
 * First validate if the key exists, then validate type of the values
 * Some types in javascript refers an object, in this function only take the "Array" case. If you want to use another like "null"
 * add the validation.
 * The final object returns two keys with a boolean value thats say if all validations are passed or not and finally the object
 */
export function objectValidations(o: object, properties: Array<IObjectValidationsProperties>): IObjectValidationsReturn | undefined {
    let validations: Array<{ key: string; has: boolean; typeValid?: boolean; }> = [];
    if (typeof o === "undefined") return;
    properties.forEach(property => {
        if (o.hasOwnProperty(property.key) && property.required) {
            const ifRequired = () => {
                if (property.type != "array") {
                    return typeof o[property.key] === property.type && o[property.key] !== "" && o[property.key] !== null;
                } else {
                    return Array.isArray(o[property.key]);
                }
            };
            validations.push({
                key: property.key,
                has: true,
                typeValid: ifRequired(),
            });
        } else if (o.hasOwnProperty(property.key)) {
            validations.push({
                key: property.key,
                has: true,
                typeValid: property.type != "array" ? typeof o[property.key] === property.type : Array.isArray(o[property.key]),
            });
        } else {
            validations.push({
                key: property.key,
                has: property.required ? false : true,
                typeValid: property.required ? false : true,
            });
        }
    });

    return {
        hasRequiredInputs: validations.every(x => x.has),
        passTypeValidations: validations.every(x => x.typeValid),
        inputs: validations,
    };
}

//#endregion

//#region MarkdownParser
interface IMarkdownParserObject {
    text: string;
    type: ImarkdownType;
}

const returnCorrectHtmlTag = (section: IMarkdownParserObject) => {
    switch (section.type) {
        case ImarkdownType.BOLD:
            return `<b>${section.text}</b>`;
        case ImarkdownType.UNDERLINE:
            return `<u>${section.text}</u>`;
        default:
            return section.text;
    }
};

enum ImarkdownType {
    BOLD,
    UNDERLINE,
    DEFAULT,
}
/**
 * Markdonw Parser
 * @param str string;
 * @return string;
 *
 * Recieve a string and return string of an html with bold and underline tags, this function search
 * in the string a slash separated words and then check if they have a asterisk or underscore to set
 * the correct html tag.
 */
export function markdownParser(str): string {
    let splittedStr = str.split("/");
    let outputArr: Array<IMarkdownParserObject> = [];
    for (let i = 0; i < splittedStr.length; i++) {
        let text = splittedStr[i];
        let outputArrRow: IMarkdownParserObject = {
            text: text.replace(/\*?_?\*?\b/g, "").replace(/\b\*?_?\*?/g, ""),
            type: ImarkdownType.DEFAULT,
        };
        if ((text.match(/_/g) || []).length > 1) {
            // If more than two occurrences of _ in the text.
            outputArrRow.type = ImarkdownType.UNDERLINE;
        }
        if ((text.match(/\*/g) || []).length > 1) {
            // If more than two occurrences of * in the text.
            outputArrRow.type = ImarkdownType.BOLD;
        }
        outputArr.push(outputArrRow);
    }
    return `${outputArr.map(section => returnCorrectHtmlTag(section)).join("")} `;
}

//#endregion

//#region GroupVals

/**
 *
 * @param object array; -> of objets to group
 * @param key string; -> key to group
 * @returns
 * Array of objects group by key
 */
export function groupVals(object: Array<object>, key: string) {
    return Object.entries(
        object.reduce((val, objVals) => {
            const byKey = objVals[key];
            if (!val[byKey]) {
                val[byKey] = [];
            }
            val[byKey].push({ ...objVals });
            return val;
        }, {})
    ).map(([groupBy, values]) => ({ groupBy, values }));
}
//#endregion

//#region TimeConverter
/**
 * Time converter
 * @param time string; -> of 24hs format 24:00:00
 * @returns
 * String of 24 hours format in AM PM
 * The string given needs at least four digits e.g. 14:03
 */
export function timeConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours

        if (time[0].toString().length === 1) {
            time[0] = `0${time[0]}`;
            return time.join("");
        } else {
            return time.join("");
        }
    }
    return time.join(""); // return adjusted time or original string
}
//#endregion

//#region getNormalizedStartTime
/**
 * Normalize times with 2 digits and return it
 * @param startTime
 * @param startTimeMinute
 * @returns
 */
export function getStartTime(startTime, startTimeMinute): string {
    let time: string;

    if (startTime.toString().length > 1) {
        time = `${startTime}:`;
    } else {
        time = `0${startTime}:`;
    }

    if (typeof time !== "undefined" || time !== "") {
        if (startTimeMinute.toString().length > 1) time = time + startTimeMinute;
        else time = time + `0${startTimeMinute}`;
    }

    return time;
}
//#endregion

//#region formatComment
/**
 * Format comment to be displayed as HTML
 * @param comment
 * @returns
 */
export function formatComment(comment: string): string {
    return comment.replace(/(?:\r\n|\r|\n)/g, '<br />');
}
//#endregion
