import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import ATSSelect, { SelectOption } from "../../../components/form/ATSSelect";
import { objectValidations } from "../../../helpers/helpers";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import { ISingleDealerData } from "../../../services/interfaces/serviceResponses/dealer/ISingleDealerResponse";
import { IEditDealerRequest } from "../../../services/interfaces/servicePostBody/dealer/IEditDealerRequest";
import { PhoneNumFormatter } from "../../../components/form/ATSPhoneInput/phonenumberinfo";
import Layout from "../../../components/Layout";
import DealerService from "../../../services/entitiesServices/DealerService";
import ATSTextInput from "../../../components/form/ATSTextInput";
import StatesService from "../../../services/entitiesServices/StatesService";
import ATSEmailInput from "../../../components/form/ATSEmailInput";
import ATSPhoneInput from "../../../components/form/ATSPhoneInput";
import ATSZipCodeInput from "../../../components/form/ATSZipCodeInput";
import styles from "./EditDealer.module.scss";
import ATSSpinner from "../../../components/Shared/ATSSpinner";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import { useNavigate } from "react-router-dom";
import CountriesService from "../../../services/entitiesServices/CountriesService";


interface countriesList extends SelectOption {
    code: string;
}

function EditDealer(): ReactElement {
    const dealerInit = {
        dealer: {
            id: 0,
            dealerName: "",
            token: "",
            contactName: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            state: "",
            city: "",
            phone: "",
            comments: "",
            zip: "",
            countryId: 0,
            countryName: ""
        },
        payments: [],
        students: [],
    };

    const dealerFormInit = {
        dealerId: 0,
        name: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        state: "",
        phone: "",
        contactName: "",
        countryId: 0
    };

    const { dealerId } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [unexpectedErrorModal, setUnexpectedErrorModal] = useState(false);
    const [dealerForm, setDealerForm] = useState<IEditDealerRequest>(dealerFormInit);
    const [errors, setErrors] = useState<Record<string, Array<string>>>({});
    const [countriesList, setCountriesList] = useState<countriesList[]>();
    const [stateList, setStateList] = useState<SelectOption[]>([]);
    const [resetStates, setResetStates] = useState<boolean>(false);
    const [response, setResponse] = useState<ISingleDealerData>(dealerInit);
    const [loading, setLoading] = useState<boolean>(false);
    const [allStates, setAllStates] = useState<SelectOption[]>([]);

    const phoneFormatter = new PhoneNumFormatter();
    const navigate = useNavigate();

    useEffect(() => {
        loadDealer();
        getAllStates();
    }, []);

    useEffect(() => {
        // -- Call only once when dealer is loaded
        if (response !== dealerInit) {
            getCountriesList();
        }
    }, [response]);

    useEffect(() => {
        if (!dealerForm.countryId) return;
        setResetStates(true);
        setStateList([]);
        setDealerForm({ ...dealerForm, state: "" });
        getStateList(dealerForm.countryId);
    }, [dealerForm.countryId]);

    const loadDealer = async () => {
        setLoading(true);
        const res = await DealerService.getDealer(dealerId);
        if (res.success) {
            const dealerFormData: IEditDealerRequest = {
                dealerId: res.response.dealer.id,
                name: res.response.dealer.dealerName,
                email: res.response.dealer.email,
                addressLine1: res.response.dealer.addressLine1,
                addressLine2: res.response.dealer.addressLine2,
                city: res.response.dealer.city,
                zip: res.response.dealer.zip,
                state: res.response.dealer.state,
                phone: res.response.dealer.phone,
                contactName: res.response.dealer.contactName,
                countryId: res.response.dealer.countryId
            };
            setResponse(res.response);
            setDealerForm(dealerFormData);
        }
        setLoading(false);
    };

    async function getAllStates() {
        const res = await StatesService.getStates();
        if (res && res.success) setAllStates(res.response.items.map(state => ({ value: state.code, label: state.name })));
    }
    async function getCountriesList() {
        const res = await CountriesService.getCountries();

        if (res && res.success) {
            if (res.response.items.length > 1) {
                setCountriesList(res.response.items.map(country => {
                    if (country.id === response?.dealer.countryId) {
                        return { value: country.id, label: country.name, code: country.code, default: true };
                    }
                    else {
                        return { value: country.id, label: country.name, code: country.code };
                    }

                }));
            } else {
                setCountriesList(res.response.items.map(country => ({ value: country.id, label: country.name, code: country.code, default: true })));
            }
        }
    }

    async function getStateList(countryId) {
        const res = await StatesService.getByCountry(countryId);
        if (res && res.success)
            setResetStates(false);
        setErrors(prev => ({
            ...prev,
            state: [],
        }));

        if (res.response.items.length > 1) {
            setStateList(
                res.response.items.map(state => {
                    if (state.code === response?.dealer.state) {
                        return { value: state.code, label: state.name, default: true };
                    } else return { value: state.code, label: state.name };
                })
            );
        } else {
            setStateList(res.response.items.map(state => ({ value: state.code, label: state.name, default: true })));
        }


    }

    const handleInputChange = (name: string, value: string | number): void => {
        setDealerForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    async function handleSubmit() {
        const validations = [
            {
                key: "name",
                type: "string",
                required: true,
            },
            {
                key: "email",
                type: "string",
                required: true,
            },
            {
                key: "addressLine1",
                type: "string",
                required: true,
            },
            {
                key: "addressLine2",
                type: "string",
                required: false,
            },
            {
                key: "city",
                type: "string",
                required: true,
            },
            {
                key: "zip",
                type: "string",
                required: true,
            },
            {
                key: "state",
                type: "string",
                required: true,
            },
            {
                key: "phone",
                type: "string",
                required: true,
            },
            {
                key: "contactName",
                type: "string",
                required: true,
            },
        ];
        const properties = objectValidations(dealerForm, validations);
        const hasErrors: Array<boolean> = Object.keys(errors).map((err) => !(errors[err].length === 0));
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations && !hasErrors.includes(true)) {
            try {
                const response = await DealerService.editDealer(dealerForm as IEditDealerRequest);
                if (response.success) {
                    setOpenModal(!openModal);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(prev => ({
                        ...prev,
                        ...errors
                    }));
                    response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                    if (response.httpStatusCode === 500) setUnexpectedErrorModal(!unexpectedErrorModal);
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            setErrors(prev => ({
                ...prev,
                ...errors
            }));
            console.error("validations not passed");
        }
    }

    function getStateDescription(stateCode: string) {
        return allStates.find(state => state.value === stateCode)?.label;
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Save Changes",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: `/dealers/${response.dealer.id}`,
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setUnexpectedErrorModal(!unexpectedErrorModal),
        },
    ];
    //#endregion

    return (
        <Layout title={"Edit Dealer"} backHistory={true} buttons={buttons}>
            {!loading ? (
                <div className={styles.layout}>
                    <div className={styles.layout_area}>
                        <div className="whitebox">
                            <ul className="whitebox_list">
                                <h2 className="form_title">Previous info</h2>
                                {response.dealer && (
                                    <>
                                        <ATSInfoItem title={"NAME"} value={response.dealer.dealerName} />
                                        <ATSInfoItem title={"EMAIL"} value={response.dealer.email} />
                                        <ATSInfoItem title={"ADDRESS LINE 1"} value={response.dealer.addressLine1} />
                                        {response.dealer.addressLine2 && (
                                            <ATSInfoItem title={"ADDRESS LINE 2"} value={response.dealer.addressLine2} />
                                        )}
                                        <ATSInfoItem title={"CITY"} value={response.dealer.city} />
                                        <ATSInfoItem title={"ZIP CODE"} value={response.dealer.zip} />
                                        <ATSInfoItem title={"COUNTRY"} value={response.dealer.countryName} />
                                        <ATSInfoItem title={"STATE"} value={getStateDescription(response.dealer.state)} />
                                        <ATSInfoItem title={"PHONE NUMBER"} value={phoneFormatter.format(response?.dealer.phone)} />
                                        <ATSInfoItem title={"CONTACT NAME"} value={response.dealer.contactName} />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.layout_area}>
                        <div className="whitebox hasScroll">
                            <form className="form">
                                <fieldset className="form_fieldset maxWidth">
                                    <ATSTextInput
                                        label="Name"
                                        name="name"
                                        handleChange={handleInputChange}
                                        value={dealerForm.name}
                                        error={errors && errors.name ? errors.name : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSEmailInput
                                        label="Email"
                                        name="email"
                                        handleChange={handleInputChange}
                                        value={dealerForm.email}
                                        error={errors && errors.email ? errors.email : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSTextInput
                                        label="Address Line 1"
                                        name="addressLine1"
                                        handleChange={handleInputChange}
                                        value={dealerForm.addressLine1}
                                        error={errors && errors.addressLine1 ? errors.addressLine1 : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSTextInput
                                        label="Address Line 2"
                                        name="addressLine2"
                                        handleChange={handleInputChange}
                                        value={dealerForm.addressLine2}
                                        error={errors && errors.addressLine2 ? errors.addressLine2 : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                    />
                                    <ATSTextInput
                                        label="City"
                                        name="city"
                                        handleChange={handleInputChange}
                                        value={dealerForm.city}
                                        error={errors && errors.city ? errors.city : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSSelect
                                        name="countryId"
                                        options={countriesList ?? []}
                                        placeholder="Select Country"
                                        label="Country"
                                        handleChange={handleInputChange}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        error={errors && errors.country ? errors.country : []}
                                        required
                                    />
                                    <div className="group c1x1">
                                        <ATSZipCodeInput
                                            label="Zip Code"
                                            name="zip"
                                            country={(dealerForm.countryId && countriesList) ? countriesList.filter(state => state.value === dealerForm.countryId)[0]?.code : undefined}
                                            handleChange={handleInputChange}
                                            value={dealerForm.zip}
                                            error={errors && errors.zip ? errors.zip : []}
                                            setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                            required
                                        />

                                        <ATSSelect
                                            name="state"
                                            options={stateList}
                                            placeholder="Select State"
                                            label="State"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.state ? errors.state : []}
                                            resetValues={resetStates}
                                            required
                                        />

                                    </div>
                                    <ATSPhoneInput
                                        label="Phone number"
                                        name="phone"
                                        handleChange={handleInputChange}
                                        value={dealerForm.phone}
                                        error={errors && errors.phone ? errors.phone : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSTextInput
                                        label="Contact name"
                                        name="contactName"
                                        handleChange={handleInputChange}
                                        value={dealerForm.contactName}
                                        error={errors && errors.contactName ? errors.contactName : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <ATSSpinner />
            )}
            {openModal && (
                <ATSModal
                    title="Successfully updated"
                    icon="icon-check_circle_fill success"
                    bodyText={["The dealer was successfully updated."]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => navigate(`/dealers/${response.dealer.id}`, { replace: true })}
                    buttons={buttonsModal}
                />
            )}
            {unexpectedErrorModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={[
                        "Sorry, the dealer was NOT updated successfully.",
                        "There was an unexpected error, check the information and try again.",
                        " If the problem persist call an administrator",
                    ]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setUnexpectedErrorModal(!unexpectedErrorModal)}
                    buttons={buttonsErrorModal}
                />
            )}
        </Layout>
    );
}

export default EditDealer;
