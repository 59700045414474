import { IStudent } from "./IStudent";

export interface AddPaymentRequest {
    courseInstanceId: number | null;
    dealerId: number | null;
    slots: number | null;
    students: AddPaymentStudent[];
    method: PaymentMethods | null;
    paymentData: AddPaymentCreditCard | AddPaymentACH | AddPaymentManual;
    isDealer: boolean;
}

export interface AddPaymentCreditCard {
    cardNumber: string;
    cvc: string;
    expirationMonth: number | null;
    expirationYear: number | null;
    name: string;
    country: string;
    postalCode: string;
}

export interface AddPaymentACH {
    accountNumber: string;
    routingNumber: string;
    holderName: string;
    accountHolderType: string;
    ipAddress: string;
    userAgent: string;
}

export interface AddPaymentManual {
    title: string;
    comment: string;
}

export interface AddPaymentStudent {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface AddPaymentResponse {
    amount: number;
    date: string;
    id: number;
    pending: boolean;
    stripePaymentId: string;
    students: Array<IStudent>;
    tax: number;
    verificationLink?: string;
    success: boolean;
}

export enum PaymentMethods {
    CreditCard = 1,
    ACH = 2,
    Manual = 3,
}

export enum PaymentMethodsString {
    CreditCard = 'creditCard',
    ACH = 'ach',
    Manual = 'manual',
}