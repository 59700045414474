import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { currencyDollarUS, objectValidations } from "../../../helpers/helpers";
import { ICourseInstanceEditRequest } from "../../../services/interfaces/servicePostBody/course/ICourseInstanceEditRequest";
import ATSSelect, { SelectOption } from "../../../components/form/ATSSelect";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import Layout from "../../../components/Layout";
import ATSSpinner from "../../../components/Shared/ATSSpinner";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import styles from "./EditCourse.module.scss";
import CourseService from "../../../services/entitiesServices/CourseService";
import ATSNumberInput from "../../../components/form/ATSNumberInput";
import ATSCurrencyInput from "../../../components/form/ATSCurrencyInput";
import { ISingleCourseData } from "../../../services/interfaces/serviceResponses/course/ISingleCourseResponse";

function EditCourse(): ReactElement {
    const courseInit = {
        courseInstanceDataView: {
            id: 0,
            courseName: "",
            date: "",
            startTime: 0,
            startTimeMinute: 0,
            location: "",
            seats: 0,
            seatsOccupied: 0,
            price: 0,
            courseStatusName: "",
            days: 0,
            comments: "",
            cancelComments: "",
            rescheduleComments: [],
            minimumSeats: 0,
        },
        courseInstanceStudentDataViews: [],
        courseInstanceDateDataViews: [],
    };

    const courseFormInit = {
        id: 0,
        price: 0,
        courseStatusId: 0,
        seats: 1,
        minimumSeats: 0,
    };

    enum ATSCourseStatus {
        ACTIVE = 1,
        CANCEL = 2,
        FROZEN = 3,
        RESCHEDULED = 4,
    }

    const { courseId } = useParams();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [courseForm, setCourseForm] = useState<ICourseInstanceEditRequest>(courseFormInit);
    const [errors, setErrors] = useState<Record<string, Array<string>>>({});
    const [courseStatusList, setCourseStatusList] = useState<SelectOption[]>([]);
    const [response, setResponse] = useState<ISingleCourseData>(courseInit);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadCourse();
    }, []);

    useEffect(() => {
        // -- Call only once when course is loaded
        if (response !== courseInit) {
            getStatusList();
        }
    }, [response]);

    const loadCourse = async () => {
        setLoading(true);
        const res = await CourseService.getCourse(courseId);
        if (res.success) {
            const courseFormData: ICourseInstanceEditRequest = {
                id: res.response.courseInstanceDataView.id,
                price: res.response.courseInstanceDataView.price,
                courseStatusId: 0,
                seats: res.response.courseInstanceDataView.seats,
                minimumSeats: res.response.courseInstanceDataView.minimumSeats,
            };
            setResponse(res.response);
            setCourseForm(courseFormData);
        }
        setLoading(false);
    };

    async function getStatusList() {
        const res = await CourseService.getCourseStatus();
        if (res && res.success) {
            const filteredList = res.response.items.filter(
                status => status.id !== ATSCourseStatus.CANCEL && status.id !== ATSCourseStatus.RESCHEDULED
            );
            setCourseStatusList(
                filteredList.map(statusFiltered => {
                    if (statusFiltered.name === response.courseInstanceDataView.courseStatusName) {
                        return { value: statusFiltered.id, label: statusFiltered.name, default: true };
                    } else return { value: statusFiltered.id, label: statusFiltered.name };
                })
            );
        }
    }

    const handleInputChange = (name: string, value: string | number): void => {
        setCourseForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    async function handleSubmit() {
        const validations = [
            {
                key: "price",
                type: "number",
                required: true,
            },
            {
                key: "courseStatusId",
                type: "number",
                required: true,
            },
            {
                key: "seats",
                type: "number",
                required: false,
            },
            {
                key: "minimumSeats",
                type: "number",
                required: false,
            },
        ];
        const properties = objectValidations(courseForm, validations);
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) {
            if (
                courseForm.courseStatusId === ATSCourseStatus.CANCEL &&
                response.courseInstanceStudentDataViews &&
                response.courseInstanceStudentDataViews.length > 0
            ) {
                setModalConfig(editCourseModals.validateError);
                setToggleModal(true);
            }
            try {
                const response = await CourseService.editCourse(courseForm as ICourseInstanceEditRequest);
                if (response.success) {
                    setModalConfig(editCourseModals.success);
                    setToggleModal(true);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(errors);
                    response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                    if (response.httpStatusCode === 500) {
                        setModalConfig(editCourseModals.unexpectedError);
                        setToggleModal(true);
                    }
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            setErrors(errors);
            console.error("validations not passed");
        }
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Save Changes",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: `/courses/${courseId}`,
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const editCourseModals = {
        validateError: {
            title: "Cancel course",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: ["To perform this action, first refund all the students or move them to another course."],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        success: {
            title: "Successfully updated",
            icon: "icon-check_circle_fill success",
            bodyText: ["The course was successfully updated."],
            type: ATSTypeModal.DEFAULT,
            buttons: buttonsModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => navigate(`/courses/${response.courseInstanceDataView.id}`, { replace: true }),
        },
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [
                "Sorry, the course was NOT updated.",
                "There was an unexpected error, check the information and try again.",
                "If the problem persist call an administrator",
            ],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };

    //#endregion

    return (
        <Layout title={"Edit Course"} backHistory={true} buttons={buttons}>
            {!loading ? (
                <div className={styles.layout}>
                    <div className={styles.layout_aside}>
                        <div className="whitebox">
                            <ul className="whitebox_list">
                                <h2 className="form_title">Course info</h2>
                                {response && (
                                    <>
                                        <ATSInfoItem title={"COURSE"} value={response.courseInstanceDataView.courseName} />
                                        <ATSInfoItem title={"LOCATION"} value={response.courseInstanceDataView.location} />
                                    </>
                                )}
                            </ul>
                        </div>
                        <div className="whitebox">
                            <h2 className="form_title">Previous info</h2>
                            <ul className="whitebox_list">
                                {response && (
                                    <>
                                        <ATSInfoItem title={"PRICE"} value={currencyDollarUS.format(response.courseInstanceDataView.price)} />
                                        <ATSInfoItem title={"STATUS"} value={response.courseInstanceDataView.courseStatusName} />
                                        <ATSInfoItem title={"TOTAL SEATS"} value={response.courseInstanceDataView.seats} />
                                        <ATSInfoItem title={"MINIMUM SEATS"} value={response.courseInstanceDataView.minimumSeats} />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.layout_main}>
                        <div className="whitebox hasScroll">
                            <form className="form">
                                <fieldset className="form_fieldset maxWidth">
                                    <div className="group c1x1">
                                        <ATSCurrencyInput
                                            name="price"
                                            label="Price"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.price ? errors.price : []}
                                            required
                                            value={response.courseInstanceDataView.price}
                                            maxLengthProps={11}
                                        />
                                        {courseStatusList && (
                                            <ATSSelect
                                                name="courseStatusId"
                                                options={courseStatusList}
                                                placeholder="Select Status"
                                                label="Status"
                                                handleChange={handleInputChange}
                                                setErrorMessage={handleErrorsChange}
                                                error={errors && errors.courseStatusId ? errors.courseStatusId : []}
                                                required
                                            />
                                        )}
                                    </div>
                                    <div className="group c1x1">
                                        <ATSNumberInput
                                            name="seats"
                                            label="Total Seats"
                                            value={courseForm.seats}
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.seats ? errors.seats : []}
                                            maxLengthProps={4}
                                        />
                                        <ATSNumberInput
                                            name="minimumSeats"
                                            label="Minimum Seats"
                                            value={courseForm.minimumSeats}
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.minimumSeats ? errors.minimumSeats : []}
                                            maxLengthProps={4}
                                        />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <ATSSpinner />
            )}
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default EditCourse;
