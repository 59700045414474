import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { ICourseResponse } from "../../../services/interfaces/serviceResponses/course/ICoursesResponse";
import ATSVirtualList from "../../../components/Shared/ATSVirtualList";
import CourseService from "../../../services/entitiesServices/CourseService";
import Layout from "../../../components/Layout";
import ATSFilter, { ATSSearchColumns, ATSFilterType } from "../../../components/Shared/ATSFilter";
import { SelectOption } from "../../../components/form/ATSSelect";
import useQueryParams from "../../../hooks/useQueryParams";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";

export default function CoursesList(): ReactElement {
    const navigate = useNavigate();
    const columnsToIgnore = ["minimumSeats", "id", "startTimeMinute", "minimumSeats", "occupiedSeats"];
    const sortColumns = ["courseName", "date", "location", "courseStatusName"];
    const queryParams = useQueryParams();
    const location = useLocation();

    const [courses, setCourses] = useState<ICourseResponse[]>([]);
    const [gridFilter, setGridFilter] = useState<any>({ limit: 100, offset: 0 });
    const [filterObject, setFilterObject] = useState<any>([]);
    const [clearValues, setClearValues] = useState<boolean>(false);
    const [noDataFound, setNoDataFound] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);

    const [errorDates, setErrorDates] = useState<any>([""]);

    const RESCHEDULED_ID = 4;

    const filtersInit = [
        {
            type: ATSFilterType.TEXT,
            columns: ATSSearchColumns.COURSENAME,
            placeholder: "Search by course",
            name: "courseName",
            value: "",
            column: "search",
        },
        {
            type: ATSFilterType.SELECT,
            options: [] as SelectOption[],
            placeholder: "Status",
            name: "courseStatusId",
            value: "",
        },
        {
            type: ATSFilterType.SELECT,
            options: [] as SelectOption[],
            placeholder: "Location",
            name: "locationId",
            value: "",
        },

        { type: ATSFilterType.DATEPICKER, name: "startDateFrom", columns: [ATSSearchColumns.STARTDATE], placeholder: "mm/dd/yyyy", value: "" },
        { type: ATSFilterType.DATEPICKER, name: "startDateTo", columns: [ATSSearchColumns.STARTDATE], placeholder: "mm/dd/yyyy", value: "" },
    ];

    useEffect(() => {
        initFilters();
    }, [queryParams]);

    useEffect(() => {
        if (filterObject.length > 0) {
            if (checkFilterDates()) {
                setErrorDates([""]);
                fetchData();

                for (let filter in gridFilter) {
                    let filterValue = gridFilter[filter];
                    if (filter !== "offset" && filter !== "limit" && filterValue) {
                        queryParams.set(filter, filterValue);
                    } else if (filterValue === "") {
                        queryParams.delete(filter);
                    }
                }

                if (queryParams.toString().length > 0) {
                    window.history.replaceState({}, "", `${location.pathname}?${queryParams.toString()}`);
                } else {
                    window.history.replaceState({}, "", `${location.pathname}`);
                }
            } else {
                setErrorDates(["The start date must not be greater than the end date of the range"]);
            }
        }
    }, [gridFilter]);

    function checkFilterDates() {
        const filterDates = Object.keys(gridFilter).filter(filter => filter === "startDateFrom" || filter === "startDateTo");
        if (filterDates.length > 1) {
            const dateFrom = new Date(gridFilter["startDateFrom"]);
            const dateTo = new Date(gridFilter["startDateTo"]);
            if (dateFrom > dateTo) {
                return false;
            }
        }
        return true;
    }

    async function fetchData() {
        try {
            console.log("Fetching...");
            if (courses.length === 0) setIsLoading(true);
            const res = await CourseService.getFilteredCourses(gridFilter);
            if (res && res.success && res.response.courseInstances.length > 0) {
                setNoDataFound(false);
                setCourses([...courses, ...res.response.courseInstances]);
            } else {
                res.httpStatusCode && console.error(res.httpStatusCode, res.message);
                if (res.httpStatusCode === 500) {
                    setModalConfig(courseListModals.unexpectedError);
                    setToggleModal(true);
                }
                if (res.message.length > 0) {
                    courseListModals.error.bodyText = [res.message];
                    setModalConfig(courseListModals.error);
                    setToggleModal(true);
                }
                setNoDataFound(true);
            }
            setClearValues(false);
            setIsLoading(false);
        } catch (err) {
            //do something
        }
    }

    async function fetchMore() {
        try {
            if (courses.length % 100 === 0) {
                setGridFilter({ ...gridFilter, offset: gridFilter.offset + gridFilter.limit });
            } else {
                console.log("Max elements reached.");
            }
        } catch (err) { }
    }

    async function initFilters() {
        let courseStatus: SelectOption[] = [];
        let courseLocation: SelectOption[] = [];

        setLayoutLoading(true);

        await Promise.all([
            CourseService.getCourseStatus().then(function (resp) {
                if (resp && resp.success) {
                    resp.response.items.map(status => {
                        //set course status dropdown (except rescheduled course status)
                        if (status.id !== RESCHEDULED_ID) courseStatus.push({ value: status.id, label: status.name });
                    });
                }
            }),
            CourseService.getCourseLocation().then(function (resp) {
                if (resp && resp.success) courseLocation = resp.response.items.map(location => ({ value: location.id, label: location.name }));
            }),
        ]);

        filtersInit.forEach(e => {
            if (e.name === "courseStatusId") {
                e.options = courseStatus;
            }
            if (e.name === "locationId") {
                e.options = courseLocation;
            }
            if (e.name === "startDateFrom" && queryParams.has("startDateFrom")) {
                e.value = queryParams.get("startDateFrom") || "";
            }
            if (e.name === "startDateTo" && queryParams.has("startDateTo")) {
                e.value = queryParams.get("startDateTo") || "";
            }
            if (e.name === "locationId" && queryParams.has("locationId")) {
                e.value = queryParams.get("locationId") || "";
            }
            if (e.name === "courseStatusId" && queryParams.has("courseStatusId")) {
                e.value = queryParams.get("courseStatusId") || "";
            }
            if (e.column === "search" && queryParams.has("search")) {
                e.value = queryParams.get("search") || "";
            }
        });

        if (queryParams.has("orderBy") && queryParams.has("descending")) {
            const order = queryParams.get("descending");
            handleSort(queryParams.get("orderBy") ?? "", order === "true" ? true : false);
        }

        setLayoutLoading(false);
        setFilterObject(filtersInit);

        //Init Filter with params
        let filtersApplied = {};
        let allowedFilters = filtersInit.map(e => e["column"] || e["name"]);

        queryParams.forEach((value, key) => {
            if (allowedFilters.indexOf(key) > -1) {
                filtersApplied[key] = value;
            }
        });

        setGridFilter(prev => ({
            ...prev,
            ...filtersApplied,
        }));
    }

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrorDates(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    function handleFilter(filtersApplied: any) {
        setCourses([]);
        setGridFilter(prev => ({
            ...prev,
            ...filtersApplied,
        }));
    }

    function handleSort(column: string, order: boolean) {
        setCourses([]);
        queryParams.set("descending", order.toString());
        setGridFilter(prev => ({
            ...prev,
            orderBy: column,
            descending: order,
        }));
    }

    function handleClearFilter() {
        setGridFilter({ limit: 100, offset: 0 });

        let queryParamsKeys = new URLSearchParams(queryParams.toString()).keys();
        for (let ind of queryParamsKeys) {
            queryParams.delete(ind);
        }
        setCourses([]);

        setClearValues(true);
    }

    function onRowClickHandler(event, id) {
        navigate(`/courses/${id}`);
    }

    const buttons: Array<IATSButtons> = [
        {
            cta: "Create New",
            type: ATSTypeButton.OUTNEGATIVE,
            url: "new",
            iconClass: "icon-add",
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const courseListModals = {
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: ["Sorry there was an unexpected error while fetching the payment.", "If the problem persist call an administrator."],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
            buttons: buttonsErrorModal,
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };

    return (
        <Layout title={"Courses"} backHistory={false} {...{ buttons }} isLoading={layoutLoading}>
            <section className="layout_full">
                <div className="layout_full_head">
                    <div className="grid_row">
                        {filterObject && filterObject.length > 0 && (
                            <ATSFilter
                                filtersApplied={filterObject}
                                filter={gridFilter}
                                handleFilter={handleFilter}
                                handleClearFilter={handleClearFilter}
                                resetValues={clearValues}
                                errorDates={errorDates}
                                setErrorMessage={handleErrorsChange}
                            />
                        )}
                    </div>
                </div>
                <div className="layout_full_body">
                    {courses && (
                        <ATSVirtualList
                            isLoading={isLoading}
                            dataSet={courses}
                            onRowClickHandler={onRowClickHandler}
                            ignoreColumns={columnsToIgnore}
                            entity={"courses"}
                            sortBy={sortColumns}
                            sortFunc={handleSort}
                            noData={noDataFound}
                            resetFilter={handleClearFilter}
                            fetchMore={fetchMore}
                        />
                    )}
                </div>
            </section>
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}
