import styles from './StepsIndicator.module.scss';

function StepsIndicator({ totalSteps, actualStep }) {
    return (
        <ul className={`${styles.steps} ${styles['step_' + actualStep]}`}>
            {totalSteps.map((step, i) => (
                <li key={`step-${i}`}title={step} className={(i === actualStep) ? styles.active : ''}>
                    <i className="icon-check"></i>
                </li>
            ))}
        </ul>
    );
}

export default StepsIndicator;