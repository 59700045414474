import React from 'react';

function InfoItem({ title, value }) {
    return (
        <li>
            <h4 className='form_label'>{title}</h4>
            <p>{value}</p>
        </li>
    );
}

export default InfoItem;