import { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../Shared/ATSButton";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../Shared/ATSModal";
import ATSSpinner from "../Shared/ATSSpinner";
import Header from "./Header";
import styles from "./Layout.module.scss";
import Sidebar from "./Sidebar";

interface ILayoutProps {
    title: string;
    backHistory: boolean;
    backLabel?: string;
    noHeader?: boolean;
    buttons?: Array<IATSButtons>;
    badge?: React.ReactNode;
    isLoading?: boolean;
    userSettings?: boolean;
}

function Layout(props: PropsWithChildren<ILayoutProps>): ReactElement {
    const { title, backHistory, buttons, children, backLabel, badge, noHeader, isLoading, userSettings } = props;
    const [showLogout, setShowLogOut] = useState(false);
    const navigate = useNavigate();
    const buttonsLogOut = [
        {
            cta: "Cancel",
            type: ATSTypeButton.OUTPOSITIVE,
            action: () => {
                setShowLogOut(!showLogout);
            },
        },
        {
            cta: "Yes, log out",
            type: ATSTypeButton.POSITIVE,
            action: () => {
                setShowLogOut(!showLogout);
                localStorage.removeItem("jwt-token");
                navigate("../login", { replace: true });
            },
        },
    ];

    return (
        <div className={styles.ats_container}>
            {showLogout && (
                <ATSModal
                    title="Log out from ATS"
                    icon="icon-logout cool"
                    bodyText={["Are you sure?"]}
                    type={ATSTypeModal.DEFAULT}
                    bodyTextType={ATSBodyTextTypeModal.SUBTITLE}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setShowLogOut(!showLogout)}
                    buttons={buttonsLogOut}
                />
            )}
            <Sidebar handleLogOut={() => setShowLogOut(!showLogout)} userSettings={userSettings} />
            <section className={styles.ats_content}>
                {isLoading ? (
                    <div className={styles.ats_layoutSpinnerContainer}>
                        <ATSSpinner title="Fetching data..." subtitle="Please wait, while we fetch the requested data from the server." />
                    </div>
                ) : (
                    <>
                        {!noHeader && <Header {...{ title }} {...{ backHistory }} {...{ buttons }} {...{ backLabel }} {...{ badge }} />}
                        <main className={styles.ats_main}>{children}</main>
                    </>
                )}
            </section>
        </div>
    );
}

export default Layout;
