import { ReactElement } from 'react';
import styles from './ATSWidget.module.scss';

interface IATSWidgetProps {
    type: ATSWidgetType,
    days: number | string,
    content: string,
    tooltip?: ReactElement;
}

export enum ATSWidgetType {
    DEFAULT = 'cool',
    ERROR = 'danger',
    SUCCESS = 'success',
    WARNING = 'warning'
}

/**
 * Create ATS Widget component. 
 * @param type: ATSWidgetType;
 * @param days: number | string;
 * @param content: string;
 */
function ATSWidget(props: IATSWidgetProps) {
    const { type, content, days, tooltip } = props;
    return (
        <article className={`${styles.widget} ${type} hasTooltip`}>
            {tooltip &&
                (
                    <i className={`icon-info cool ${styles.widget_tooltip} hasTooltip withHover`}>
                        {tooltip}
                    </i>
                )
            }
            <p className={styles.widget_number}>{days}</p>
            <p className={styles.widget_text}>{content}</p>
        </article>
    );
}

export default ATSWidget;