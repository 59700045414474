import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import ATSButton, { IATSButtons } from "../../Shared/ATSButton";

interface ILayoutHeaderProps {
  title: string,
  backHistory: boolean,
  buttons?: Array<IATSButtons>,
  backLabel?: string,
  badge?: React.ReactNode;
}

function Header(props: ILayoutHeaderProps) {
  const { title, backHistory, buttons, backLabel, badge } = props;
  const navigate = useNavigate();
  return (
    <header className={styles.ats_header}>
      <div className={styles.ats_header_area}>
        {backHistory && <button className={`${styles.ats_header_back} print-hidden`} onClick={() => navigate(-1)}>
          <i className="icon-arrow_back"></i>
          {backLabel ?? 'Back'}
        </button>}
        <div className={styles.ats_header_wrapper_title}>
          <h1 className={styles.ats_header_wrapper_title_title}> {title} </h1>
          {badge}
        </div>
      </div>
      <div className={styles.ats_header_area}>
        {
          buttons &&
          buttons.map((button) =>
            <ATSButton {...{ ...button }} key={button.cta} />
          )
        }
      </div>
    </header>
  );
}

export default Header;