import { ReactElement, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ISinglePaymentResponse } from "../../../services/interfaces/serviceResponses/payment/ISinglePaymentResponse";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { formatComment, formatDate, formatDateFull, getStartTime, timeConvert } from "../../../helpers/helpers";
import ATSList from "../../../components/Shared/ATSList";
import Layout from "../../../components/Layout";
import PaymentService from "../../../services/entitiesServices/PaymentService";
import styles from "./SinglePayment.module.scss";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";

const MANUALPAYMENTMETHOD = 3;

export default function SinglePayment(): ReactElement {
    const paymentInit = {
        students: [],
        refunds: [],
        payment: {
            id: 0,
            dealerName: "",
            amount: 0,
            token: "",
            date: "",
            statusName: "",
            typeName: "",
            methodId: 0,
            methodName: "",
            startDate: "",
            endDate: "",
            startTime: 0,
            startTimeMinute: 0,
            price: 0,
            location: "",
            comment: "",
            slots: 0,
            courseName: "",
            refundComment: "",
            manualPaymentComment: "",
        },
    };

    const { paymentId } = useParams();
    const navigate = useNavigate();
    const studentColumnsToIgnore = ["id"];
    const refundsColumnsToIgnore = ["id", "studentId", "paymentId"];

    const [response, setResponse] = useState<ISinglePaymentResponse>(paymentInit);
    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(true);

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    useEffect(() => {
        loadPayment();
    }, []);

    const loadPayment = async () => {
        try {
            setLayoutLoading(true);
            const response = await PaymentService.getPayment(paymentId);
            if (response && response.success && response.response) {
                setResponse(response.response);
            } else {
                response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                if (response.httpStatusCode === 500) {
                    setModalConfig(singlePaymentModals.unexpectedError);
                    setToggleModal(true);
                }
            }
            setLayoutLoading(false);
        } catch (e) {
            //do something
        }
    };

    const handleCommentChange = async (value: string) => {
        const res = await PaymentService.updatePaymentComment({ paymentId: response.payment.id, comments: value });
        if (res && res.success) loadPayment();
        else {
            singlePaymentModals.error.bodyText = [res.message];
            setModalConfig(singlePaymentModals.error);
            setToggleModal(true);
        }
    };

    function onRowClickHandler(event, id, entity) {
        navigate(`/${entity}/${id}`);
    }

    function closeModalAndReload() {
        setToggleModal(false);
        loadPayment();
    }

    // Manual enrollments cannot be refunded
    function disableRefundButton() {
        return response.payment.methodId === MANUALPAYMENTMETHOD || response.payment.statusName === "Pending" || response.payment.statusName === "Refunded" || response.payment.statusName === "Rejected";
    }

    const buttons: Array<IATSButtons> = [
        {
            cta: "Refund Payment",
            type: ATSTypeButton.NEGATIVE,
            action: () => navigate("refunds", { state: { payment: response.payment, students: response.students } }),
            disabled: disableRefundButton(),
        },
    ];

    const okButtonModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            action: closeModalAndReload,
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const singlePaymentModals = {
        comment: {
            commentValue: response.payment.comment,
            type: ATSTypeModal.COMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                handleCommentChange(value);
                setToggleModal(false);
                loadPayment();
            },
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: okButtonModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                setToggleModal(false);
            },
        },
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: ["Sorry there was an unexpected error while fetching the payment.", "If the problem persist call an administrator."],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
            buttons: buttonsErrorModal,
        },
    };

    return (
        <Layout
            title={response ? response.payment.dealerName : ""}
            backHistory={true}
            backLabel="Payments"
            {...{ buttons }}
            isLoading={layoutLoading}>
            {response?.payment && (
                <div className={styles.layout}>
                    <div className={styles.layout_aside}>
                        <div className="widget cool">
                            <span>Total Billing Amount</span>
                            <h2>{dollarUS.format(response.payment.amount)}</h2>
                        </div>
                        <>
                            {(response.payment.comment && response.payment.comment?.length > 0) ||
                                (response.payment.refundComment && response.payment.refundComment?.length > 0) ||
                                (response.payment.manualPaymentComment && response.payment.manualPaymentComment?.length > 0) ? (
                                <div className="whitebox">
                                    <div className="whitebox_content">
                                        {response.payment.comment?.length > 0 && (
                                            <>
                                                <h3 className="form_label">
                                                    Comments
                                                    <button
                                                        onClick={() => {
                                                            setModalConfig(singlePaymentModals.comment);
                                                            setToggleModal(true);
                                                        }}>
                                                        <i className={`icon-edit cool ${styles.iconEditComment}`}></i>
                                                    </button>
                                                </h3>
                                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.payment.comment) }} />
                                            </>
                                        )}
                                        {response.payment.refundComment?.length > 0 && (
                                            <>
                                                <h3 className="form_label">Refund Comment</h3>
                                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.payment.refundComment) }} />
                                            </>
                                        )}
                                        {response.payment.manualPaymentComment?.length > 0 && (
                                            <>
                                                <h3 className="form_label">Manual Payment Comment</h3>
                                                <p dangerouslySetInnerHTML={{ __html: formatComment(response.payment.manualPaymentComment) }} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="whitebox centered">
                                    <ATSButton
                                        cta="Add comment"
                                        iconClass="icon-add cool"
                                        type={ATSTypeButton.FORM}
                                        action={() => {
                                            setModalConfig(singlePaymentModals.comment);
                                            setToggleModal(true);
                                        }}
                                    />
                                </div>
                            )}
                        </>
                        <div className="whitebox has_heading">
                            <div className="whitebox_heading">
                                <h2 className="whitebox_heading_title">Transaction</h2>
                            </div>
                            <div className="whitebox_content">
                                <ul className="whitebox_list">
                                    {response && (
                                        <>
                                            <ATSInfoItem
                                                title={"TOKEN"}
                                                value={response.payment.token !== "" ? response.payment.token : "MANUAL ENROLLMENT"}
                                            />
                                            <ATSInfoItem title={"PAID DATE"} value={formatDateFull(response.payment.date)} />
                                            <ATSInfoItem title={"STATUS"} value={response.payment.statusName} />
                                            <ATSInfoItem title={"DEALER"} value={response.payment.dealerName} />
                                            <ATSInfoItem title={"GATEWAY"} value={response.payment.typeName} />
                                            <ATSInfoItem title={"PAYMENT METHOD"} value={response.payment.methodName} />
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={styles.layout_main}>
                        <div className="whitebox has_heading">
                            <div className="whitebox_heading">
                                <i className="icon-credit_card"></i>
                                <h2 className="whitebox_heading_title">Purchase</h2>
                            </div>
                            <div className="whitebox_content">
                                <ul className="whitebox_list inColumns">
                                    {response.payment && (
                                        <>
                                            <ATSInfoItem title={"SEATS"} value={`x ${response.payment.slots}`} />
                                            <ATSInfoItem title={"COURSE"} value={response.payment.courseName} />
                                            <ATSInfoItem title={"START DATE"} value={formatDate(response.payment.startDate)} />
                                            <ATSInfoItem
                                                title={"TIME"}
                                                value={timeConvert(getStartTime(response.payment.startTime, response.payment.startTimeMinute))}
                                            />
                                            <ATSInfoItem title={"LOCATION"} value={response.payment.location} />
                                            <ATSInfoItem title={"PRICE"} value={dollarUS.format(response.payment.price)} />
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="whitebox has_heading">
                            <div className="whitebox_heading">
                                <i className="icon-school"></i>
                                <h2 className="whitebox_heading_title">Students</h2>
                            </div>
                            <div className="whitebox_content">
                                {response.students && response.students.length > 0 ? (
                                    <ATSList
                                        key="students"
                                        dataSet={response.students}
                                        entity="students"
                                        onClickHandler={onRowClickHandler}
                                        ignoredColumns={studentColumnsToIgnore}
                                    />
                                ) : (
                                    <span>No students available</span>
                                )}
                            </div>
                        </div>

                        <div className="whitebox has_heading">
                            <div className="whitebox_heading">
                                <i className="icon-payments"></i>
                                <h2 className="whitebox_heading_title">Refunds</h2>
                            </div>
                            <div className="whitebox_content">
                                {response.refunds && response.refunds.length > 0 ? (
                                    <ATSList
                                        key="refunds"
                                        dataSet={response.refunds}
                                        entity="refunds"
                                        onClickHandler={() => { }}
                                        ignoredColumns={refundsColumnsToIgnore}
                                    />
                                ) : (
                                    <span>No refunds associated</span>
                                )}
                            </div>
                        </div>
                        {toggleModal && <ATSModal {...{ ...modalConfig }} />}
                    </div>
                </div>
            )}
        </Layout>
    );
}
