import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../../components/Shared/ATSButton";
import ATSAlertBox, { ATSAlertBoxType } from "../../../../components/Shared/ATSAlertBox";
import Layout from "../../../../components/Layout";

function RefundStudentFail() {
    const location = useLocation();
    const navigate = useNavigate();
    const locationError = location.state as any;
    const [errorMsg, setErrorMsg] = useState<string | null>();

    useEffect(() => {
        if (locationError) setErrorMsg(locationError.error);
        else setErrorMsg("There was an error and the refund could not be completed.");
    }, []);

    const buttonGoBack: Array<IATSButtons> = [
        {
            cta: "Go back",
            type: ATSTypeButton.DANGER,
            action: () => navigate(-1),
        },
    ];

    return (
        <Layout title={"Cancel and refund"} backHistory={true}>
            <ATSAlertBox type={ATSAlertBoxType.ERROR} content={errorMsg ? errorMsg : ""} title="WE ARE SO SORRY!" buttons={buttonGoBack} />
        </Layout>
    );
}

export default RefundStudentFail;
