import { useEffect, useState } from 'react';
import { ATSSteps, IBaseStepProps } from '..';
import ATSButton, { ATSTypeButton } from '../../../../components/Shared/ATSButton';
import { copyJSON } from '../../../../helpers/helpers';
import StudentForm from './StudentForm';
import styles from './StudentsStep.module.scss';
interface IStudentStepProps extends IBaseStepProps { }

function StudentsStep(props: IStudentStepProps) {
    const { handleInputChange, state, handleErrorsChange, errors, handleStep } = props;
    const { students } = state;
    const [validatonsPassed, setValidationsPassed] = useState<boolean>(false);

    const manageStudents = (index, name, value) => {
        let mutateState = copyJSON(students);
        mutateState[index] = {
            ...mutateState[index],
            [name]: value
        };
        handleInputChange('students', mutateState);
    };

    //Validations to disable next button
    useEffect(() => {
        const hasData = students.every(x => x.firstName !== "" && x.lastName !== "" && x.phoneNumber !== "" && x.email !== "");
        const hasErrors: Array<boolean> = [];
        if (errors && errors.students) {
            errors.students.forEach(error => {
                const errorsInStudents = error ? Object.keys(error).every(x => error[x].length === 0) : true;
                hasErrors.push(errorsInStudents);
            });
        }
        (hasData && !hasErrors.includes(false)) ? setValidationsPassed(true) : setValidationsPassed(false);
    }, [students, errors]);



    return (
        <fieldset className="form_fieldset maxWidth">
            <ul className={styles.accordion}>
                {
                    students.map((_s, i) => <StudentForm {...{ manageStudents, students, errors, handleErrorsChange }} index={i} key={`student_form_${i}`} />)
                }
            </ul>
            <div className={styles.buttonsWrapper}>
                <ATSButton
                    cta="Back"
                    type={ATSTypeButton.POSITIVE}
                    action={() => handleStep(ATSSteps.BACK)}
                />
                <ATSButton
                    cta="Next"
                    type={ATSTypeButton.POSITIVE}
                    action={() => handleStep(ATSSteps.NEXT)}
                    disabled={!validatonsPassed}
                />
            </div>
        </fieldset>
    );
}

export default StudentsStep;

