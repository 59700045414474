import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ATSCurrencyInput from "../../../components/form/ATSCurrencyInput";
import { PhoneNumFormatter } from "../../../components/form/ATSPhoneInput/phonenumberinfo";
import ATSRadioInput, { ATSRadioType } from "../../../components/form/ATSRadioInput";
import ATSTextAreaInput from "../../../components/form/ATSTextAreaInput";
import Layout from "../../../components/Layout";
import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import ATSTooltip, { ATSTooltipAlignment, ATSTooltipBodyAlignment } from "../../../components/Shared/ATSTooltip";
import ATSVirtualList from "../../../components/Shared/ATSVirtualList";
import ATSWidget, { ATSWidgetType } from "../../../components/Shared/ATSWidget";
import {
    currencyDollarUS,
    formatDate,
    formatDateFull,
    getStartTime,
    IObjectValidationsProperties,
    objectValidations,
    timeConvert,
} from "../../../helpers/helpers";
import StudentService from "../../../services/entitiesServices/StudentService";
import styles from "./RefundStudent.module.scss";

function RefundStudent() {
    const location = useLocation();
    const navigate = useNavigate();
    const { response } = location.state as any;
    const [statusRefund, setStatusRefund] = useState<any>({ studentId: response.id, fullRefund: true, amount: response.pricePerUnit });
    const [paymentValue, setPaymentValue] = useState<number>(response.pricePerUnit);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errors, setErrors] = useState<any>();
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const phoneFormatter = new PhoneNumFormatter();

    const validations: Array<IObjectValidationsProperties> = [
        {
            key: "fullRefund",
            type: "boolean",
            required: true,
        },
        {
            key: "studentId",
            type: "number",
            required: true,
        },
        {
            key: "reason",
            type: "string",
            required: true,
        },
        {
            key: "amount",
            type: "number",
        },
    ];
    const statusRefundValidations = objectValidations(statusRefund, validations);

    const student = {
        id: response.id,
        studentName: response.studentName,
        email: response.email,
        statusStudentName: response.statusName,
        dealerName: response.dealerName,
        courseName: response.courseName,
        date: response.startDate,
        startTime: response.startTime,
        startTimeMinute: response.startTimeMinute,
        phone: response.phone,
    };
    const payment = {
        id: response.paymentId,
        token: response.paymentToken,
        date: response.paymentDate,
        methodId: response.methodId ?? null,
        methodName: response.methodName ?? "Missing",
        startDate: response.startDate,
        startTime: response.startTime,
        startTimeMinute: response.startTimeMinute,
        amount: response.amount,
        available: response.pricePerUnit,
        courseName: response.courseName,
        dealerName: response.dealerName,
        courseInstanceLocation: response.courseInstanceLocation ?? "Missing",
    };

    useEffect(() => {
        const payValue = statusRefund.fullRefund ? payment.available : 0;
        setPaymentValue(payValue);
    }, [statusRefund]);

    const handleSubmit = async () => {
        if (statusRefundValidations && statusRefundValidations.hasRequiredInputs && statusRefundValidations.passTypeValidations) {
            try {
                setLayoutLoading(true);
                const response = await StudentService.refundStudent(statusRefund);
                if (response.success) {
                    navigate(`success`, { state: { refundAmount: statusRefund.amount, pending: response.response["pending"] } });
                } else {
                    navigate(`fail`, { state: { error: response.message } });
                }
                setLayoutLoading(false);
            } catch {
                setErrorMessage("Unexpected Error");
                setOpenModal(true);
            }
        } else if (statusRefundValidations) {
            // show errors statusRefundValidations.inputs
            let errors;
            statusRefundValidations.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });
            setErrors(errors);
        }
    };

    const daysUntil = () => {
        const starDate = new Date(payment.startDate).getTime();
        const today = new Date().getTime();
        let difference = starDate - today;
        const daysDiff = Math.ceil(difference / (1000 * 3600 * 24));
        if (daysDiff >= 1) return daysDiff;
        return "";
    };

    const subtitleWidgetHelper = (): string => {
        const days = daysUntil();
        if (days === 1) return "day until this course begins";
        else if (days > 1) return "days until this course begins";
        else return "started this course";
    };

    const refundOptions = [
        {
            label: "Full refund",
            value: true,
            default: true,
        },
        {
            label: "Partial Refund",
            value: false,
            default: false,
        },
    ];

    const handleChange = (name: string, value: any) => {
        if (name === "fullRefund" && value === true) handleErrorsChange("amount", []);
        setStatusRefund(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const disableButton = () => {
        // Manual enrollments cannot be refunded
        if (payment.methodId === 3) return true;
        if (!statusRefund) return true;
        if (!statusRefund.fullRefund && statusRefund.amount < 0) return true;
        if (statusRefund.amount < 0) return true;
        if (!statusRefund.reason) return true;
        if (errors) {
            let has = false;
            for (const error in errors) {
                if (errors[error].length > 0) {
                    has = true;
                }
            }
            return has;
        }
        if (statusRefundValidations && statusRefundValidations.hasRequiredInputs && statusRefundValidations.passTypeValidations) return false;
        return true;
    };

    return (
        <Layout title={"Cancel and refund student"} backHistory={true} backLabel="Student" isLoading={layoutLoading}>
            <div className={styles.layout}>
                <div className={styles.layout_content}>
                    <div className={styles.layout_content_widgets}>
                        <ATSWidget
                            type={ATSWidgetType.WARNING}
                            days={`${currencyDollarUS.format(payment.available)}`}
                            content="available for this refund"
                        />
                        <ATSWidget
                            type={ATSWidgetType.DEFAULT}
                            days={daysUntil() >= 1 ? daysUntil() : `${formatDate(payment.startDate)}`}
                            content={subtitleWidgetHelper()}
                            tooltip={
                                <ATSTooltip
                                    body={[
                                        {
                                            text: `/*Fee waived 15 */ + business days before the course`,
                                            alignment: ATSTooltipBodyAlignment.LEFT,
                                        },
                                        {
                                            text: `/*50 % 14 - 10*/ business days before the course`,
                                            alignment: ATSTooltipBodyAlignment.LEFT,
                                        },
                                        {
                                            text: `/*100 % 09 - 00*/ business days before the course`,
                                            alignment: ATSTooltipBodyAlignment.LEFT,
                                        },
                                    ]}
                                    alignment={ATSTooltipAlignment.TOPRIGHT}
                                />
                            }
                        />
                    </div>

                    <section className="whitebox hasScroll">
                        <div className="whitebox_area">
                            <div className="whitebox_heading">
                                <i className="icon-school"></i>
                                <h2 className="whitebox_heading_subtitle">Students Details</h2>
                            </div>
                            <div className="whitebox_content">
                                <ul className="whitebox_list inColumns">
                                    {response && (
                                        <>
                                            <ATSInfoItem title="Name" value={student.studentName} />
                                            <ATSInfoItem title="Email" value={student.email} />
                                            <ATSInfoItem title="Phone" value={phoneFormatter.format(student.phone)} />
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="whitebox_area">
                            <div className="whitebox_heading">
                                <i className="icon-menu_book"></i>
                                <h2 className="whitebox_heading_subtitle">Course Info</h2>
                            </div>
                            <ul className="whitebox_list inColumns">
                                <ATSInfoItem title="Course" value={payment.courseName} />
                                <ATSInfoItem title="Location" value={payment.courseInstanceLocation} />
                                <ATSInfoItem
                                    title="Start Date and Time"
                                    value={`${new Date(payment.startDate).toLocaleDateString("en-US")} - ${timeConvert(
                                        getStartTime(payment.startTime, payment.startTimeMinute)
                                    )} `}
                                />
                                <ATSInfoItem title="Price" value={currencyDollarUS.format(response.pricePerUnit)} />
                            </ul>
                        </div>
                        <div className="whitebox_area">
                            <div className="whitebox_heading">
                                <i className="icon-payments"></i>
                                <h2 className="whitebox_heading_subtitle">Payment</h2>
                            </div>
                            <ul className="whitebox_list inColumns">
                                <ATSInfoItem title="Token" value={payment.token ? payment.token : "MANUAL ENROLLMENT"} />
                                <ATSInfoItem title="Paid Date" value={`${formatDateFull(payment.date)}`} />
                                <ATSInfoItem title="Dealer" value={payment.dealerName} />
                                <ATSInfoItem title="Method" value={payment.methodName} />
                                <ATSInfoItem title="Amount" value={currencyDollarUS.format(payment.amount)} />
                            </ul>
                        </div>
                    </section>
                </div>

                <div className={`whitebox ${styles.layout_aside}`}>
                    <div className="form_fieldset maxWidth">
                        <ATSRadioInput name="fullRefund" type={ATSRadioType.DEFAULT} options={refundOptions} handleChange={handleChange} />
                        <ATSCurrencyInput
                            name="amount"
                            label=""
                            handleChange={handleChange}
                            disabled={statusRefund?.fullRefund}
                            value={paymentValue}
                            required
                            error={errors?.amount}
                            setErrorMessage={handleErrorsChange}
                            maxLengthProps={11}
                            maxValueProps={payment.available}
                            allowZero={true}
                        />
                        <ATSTextAreaInput
                            name="reason"
                            label="Reason for refund"
                            handleChange={handleChange}
                            required
                            value={statusRefund?.reason}
                            error={errors?.reason}
                            setErrorMessage={handleErrorsChange}
                            maxLengthProps={1000}
                        />
                        <ATSButton cta="Refund" type={ATSTypeButton.POSITIVE} action={handleSubmit} disabled={disableButton()} />
                    </div>
                </div>

            </div>
            {openModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={["Sorry, your refund was not processed successfully.", errorMessage]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setOpenModal(!openModal)}
                    buttons={[
                        {
                            cta: "Ok",
                            type: ATSTypeButton.DANGER,
                            action: () => setOpenModal(!openModal),
                        },
                    ]}
                />
            )}
        </Layout>
    );
}

export default RefundStudent;
