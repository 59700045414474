import React from "react";
import { ReactElement, useEffect, useState } from "react";
import { dictionary } from "../../../helpers/dictionary";
import { copyJSON, formatDate, getStartTime, timeConvert } from "../../../helpers/helpers";
import { PhoneNumFormatter } from "../../form/ATSPhoneInput/phonenumberinfo";
import styles from "./ATSList.module.scss";

interface IATSListProps<T> {
    dataSet: T[];
    entity: string;
    onClickHandler: (event: React.MouseEvent<HTMLElement>, id: number, entity?: string) => void;
    ignoredColumns?: string[];
}

/**
 * Set new ATSList component, a example-usage file was provided. See the component props for more information .
 * @param dataSet:T[ ];
 * @param entity: string;
 * @param onClickHandler: function
 * @param ignoredColumns: string[] -> optional
 */

export default function ATSList<T>(atsListProps: IATSListProps<T>): ReactElement {
    const { dataSet, entity, ignoredColumns } = atsListProps;

    const titles = Object.keys(dataSet[0]);
    const phoneFormatter = new PhoneNumFormatter();
    const [afterRender, setAfterRender] = useState<Boolean>(false);

    useEffect(() => {
        setAfterRender(true);
    }, []);

    const dataCopy = copyJSON(dataSet);
    const dataCleaned = cleanData(dataCopy);

    function cleanData(parameter: T[]): T[] {
        parameter.map(data =>
            ignoredColumns?.forEach(ign => {
                delete data[ign];
            })
        );
        return parameter;
    }

    function clickHandlerWrapper(e, index: number, entity: string) {
        const element = dataSet[index] as any;
        if (element?.id) {
            atsListProps.onClickHandler(e, element.id, entity);
        } else {
            console.log("Element id not found");
        }
    }

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const paymentStatus = {
        Paid: <i className={`icon-check_circle ${styles.iconPayment} ${styles.paid}`}></i>,
        Pending: <i className={`icon-pending ${styles.iconPayment} ${styles.pending}`}></i>,
        Refunded: <i className={`icon-undo ${styles.iconPayment} ${styles.refunded}`}></i>,
        "Partial Refund": <i className={`icon-undo ${styles.iconPayment} ${styles.pending}`}></i>,
        Rejected: <i className={`icon-cancel ${styles.iconPayment} ${styles.refunded}`}></i>,
    };

    return (
        <>
            {afterRender && (
                <table className="ats_table">
                    <caption></caption>
                    <thead>
                        <tr>
                            {dataSet &&
                                titles &&
                                titles.length > 0 &&
                                titles.map((title: string, i: number) => {
                                    if (entity === "payments" && title === "courseName") return <th key={`${title}_${i}`}>PURCHASED</th>;
                                    else if (entity === "payments" && title === "methodName") return <th key={`${title}_${i}`}>METHOD</th>;
                                    else if (entity === "students" && title === "date") return <th key={`${title}_${i}`}>START DATE</th>;
                                    else if (!ignoredColumns?.some(e => e === title))
                                        return <th key={`${title}_${i}`}>{dictionary[title] ? dictionary[title] : title}</th>;
                                    else return <React.Fragment key={`${title}_${i}`}></React.Fragment>;
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {dataSet &&
                            dataCleaned &&
                            dataCleaned.length > 0 &&
                            dataCleaned.map((data: any, indexRow: number) => (
                                <tr key={`${entity}_${indexRow}`} onClick={e => clickHandlerWrapper(e, indexRow, entity)}>
                                    {data &&
                                        Object.keys(data).map((key: string, indexColumn: number) => {
                                            if (entity === "payments" && key === "statusName") {
                                                return (
                                                    <td key={`ATSList_row${indexRow}_column${indexColumn}`}>
                                                        {paymentStatus[`${data[key]}`]}
                                                        {data[key]}
                                                    </td>
                                                );
                                            } else if (entity === "payments" && key === "amount") {
                                                return <td key={`ATSList_row${indexRow}_column${indexColumn}`}>{dollarUS.format(data[key])}</td>;
                                            } else if (entity === "payments" && key === "courseName") {
                                                return (
                                                    <td key={`ATSList_row${indexRow}_column${indexColumn}`}>
                                                        {dataSet[indexRow]["slots"]} x {data[key]}
                                                    </td>
                                                );
                                            } else if (
                                                entity === "payments" &&
                                                key === "token" &&
                                                data["token"] === "" &&
                                                data["methodName"].toLowerCase() === "manual"
                                            ) {
                                                return <td key={`${data["id"]}-${key}`}>MANUAL ENROLLMENT</td>;
                                            } else if ((entity === "dealers" || entity === "refunds") && key === "amount") {
                                                return <td key={`ATSList_row${indexRow}_column${indexColumn}`}>{dollarUS.format(data[key])}</td>;
                                            } else if (entity === "students" && key === "statusName" && data["statusName"] === "Rejected") {
                                                return (
                                                    <td
                                                        key={`ATSList_row${indexRow}_column${indexColumn}`}
                                                        className={`${styles.rejected} ${styles.dot} ${styles.boldUppercase}`}>
                                                        {data[key]}
                                                    </td>
                                                );
                                            } else if (entity === "students" && key === "statusName" && data["statusName"] === "Pending") {
                                                return (
                                                    <td
                                                        key={`ATSList_row${indexRow}_column${indexColumn}`}
                                                        className={`${styles.pending} ${styles.dot} ${styles.boldUppercase}`}>
                                                        {data[key]}
                                                    </td>
                                                );
                                            } else if (entity === "students" && key === "statusName" && data["statusName"] === "Approved") {
                                                return (
                                                    <td
                                                        key={`ATSList_row${indexRow}_column${indexColumn}`}
                                                        className={`${styles.approved} ${styles.dot} ${styles.boldUppercase}`}>
                                                        {data[key]}
                                                    </td>
                                                );
                                            } else if (entity === "students" && key === "statusName" && data["statusName"] === "Refunded") {
                                                return (
                                                    <td
                                                        key={`ATSList_row${indexRow}_column${indexColumn}`}
                                                        className={`${styles.refunded} ${styles.dot} ${styles.boldUppercase}`}>
                                                        {data[key]}
                                                    </td>
                                                );
                                            } else if (entity === "students" && key === "startTime") {
                                                return (
                                                    <td key={`ATSList_row${indexRow}_column${indexColumn}`}>
                                                        {timeConvert(getStartTime(data[key], dataSet[indexRow]["startTimeMinute"]))}
                                                    </td>
                                                );
                                            } else if (key === "date") {
                                                return <td key={`ATSList_row${indexRow}_column${indexColumn}`}>{formatDate(data[key])}</td>;
                                            } else if (key === "phone") {
                                                return (
                                                    <td key={`ATSList_row${indexRow}_column${indexColumn}`}>
                                                        {phoneFormatter.format(data[key])}
                                                    </td>
                                                );
                                            } else {
                                                return <td key={`ATSList_row${indexRow}_column${indexColumn}`}>{data[key]}</td>;
                                            }
                                        })}
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </>
    );
}
