import ApiService from "./ApiService";
import { AxiosInstance } from "axios";

export default class ApiSecuredService extends ApiService {
    // -- Override the instance
    protected static axiosInstance: AxiosInstance = this.getInstance();

    protected static getInstance(): AxiosInstance {
        let baseInstance = super.getInstance();

        baseInstance.interceptors.request.use(function (reqConfig : any) {
            // -- Get token from local storage
            const token = localStorage.getItem("jwt-token") as string;
            // -- Add bearer
            const bearerToken = "bearer " + token;
            // -- Set the full token
            reqConfig.headers["x-auth"] = bearerToken;

            return reqConfig;
        });

        baseInstance.interceptors.response.use(function (resConfig) {
            // -- Get the new token
            let token = resConfig.headers["x-auth"];

            if (token) {
                // -- Set the full token
                localStorage.setItem("jwt-token", token);
            }

            return resConfig;
        }/*, function (error) {            
            throw error;
        }*/);

        return baseInstance;
    }
}
