import axios, { AxiosResponse } from "axios";
import ApiBaseService, { IResponse, IResponseForm } from "../apiServices/ApiBaseService";
import { IAddCourseInstance } from "../interfaces/servicePostBody/course/ICourseAdd";
import { ICourseCancelRequest } from "../interfaces/servicePostBody/course/ICourseCancel";
import { ICourseInstanceEditRequest } from "../interfaces/servicePostBody/course/ICourseInstanceEditRequest";
import { ICourseUpdateCommentRequest } from "../interfaces/servicePostBody/course/ICourseUpdateComment";
import { IRescheduleCourseRequest } from "../interfaces/servicePostBody/course/IRescheduleCourseRequest";
import { ICourseCancelResponse } from "../interfaces/serviceResponses/course/ICourseCancelResponse";
import { ICourseInstanceEditResponse } from "../interfaces/serviceResponses/course/ICourseInstanceEditResponse";
import { ICourseInstanceRescheduleResponse } from "../interfaces/serviceResponses/course/ICourseInstanceRescheduleResponse";
import { ICourseLocationsResponse } from "../interfaces/serviceResponses/course/ICourseLocationsResponse";
import { AddCourseInstanceResponse, ICoursesResponse } from "../interfaces/serviceResponses/course/ICoursesResponse";
import { ICourseStatusResponse } from "../interfaces/serviceResponses/course/ICourseStatusResponse";
import { ICourseTypesResponse } from "../interfaces/serviceResponses/course/ICourseTypesResponse";
import { ICourseUpdateCommentsResponse } from "../interfaces/serviceResponses/course/ICourseUpdateCommentResponse";
import { IGetCourseInstanceDatesResponse } from "../interfaces/serviceResponses/course/IGetCourseInstanceDateResponse";
import { ISingleCourseData } from "../interfaces/serviceResponses/course/ISingleCourseResponse";

class CourseService extends ApiBaseService {
    public static async getCourses(limit, offset): Promise<IResponse<ICoursesResponse>> {
        const response = await this.securedPost<ICoursesResponse>(`course-instance`, { limit, offset });
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getFilteredCourses(filtersApplied): Promise<IResponse<ICoursesResponse>> {
        const response = await this.securedPost<ICoursesResponse>(`course-instance/courseFiltered`, filtersApplied);

        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getCourse(id): Promise<IResponse<ISingleCourseData>> {
        const response = await this.securedGet<ISingleCourseData>(`course-instance/single/${id}`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async createCourse(body: IAddCourseInstance): Promise<IResponseForm<AddCourseInstanceResponse>> {
        const response = await this.securedPost<AddCourseInstanceResponse>(`course-instance/add`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async editCourse(body: ICourseInstanceEditRequest): Promise<IResponseForm<ICourseInstanceEditResponse>> {
        const response = await this.securedPost<ICourseInstanceEditResponse>(`course-instance/edit`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getCourseTypes(): Promise<IResponse<ICourseTypesResponse>> {
        const response = await this.securedGet<ICourseTypesResponse>(`course`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getCourseStatus(): Promise<IResponse<ICourseStatusResponse>> {
        const response = await this.securedGet<ICourseStatusResponse>(`course-status`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getCourseLocation(): Promise<IResponse<ICourseLocationsResponse>> {
        const response = await this.securedGet<ICourseLocationsResponse>(`location`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async cancelCourse(body: ICourseCancelRequest): Promise<IResponse<ICourseCancelResponse>> {
        const response = await this.securedPost<ICourseCancelResponse>(`course-instance/cancel`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async rescheduleCourse(body: IRescheduleCourseRequest): Promise<IResponseForm<ICourseInstanceRescheduleResponse>> {
        const response = await this.securedPost<ICourseInstanceRescheduleResponse>(`course-instance/reschedule`, body);
        if (response.success) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getCourseDates(id: number): Promise<IResponse<IGetCourseInstanceDatesResponse>> {
        const response = await this.securedGet<IGetCourseInstanceDatesResponse>(`course-instance/reschedule/${id}`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async updateCourseComment(body: ICourseUpdateCommentRequest): Promise<IResponse<ICourseUpdateCommentsResponse>> {
        const response = await this.securedPost<ICourseUpdateCommentsResponse>(`course-instance/comments`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getCourseReport(body: any): Promise<IResponse<Blob>> {
        const response = await this.securedPost<Blob>(`report/courseReport`, body);
        if (response != null && !response.message) {
            return response;
        } else if (response.message) {
            console.error("Error: ", response.message);
            return response;
        } else {
            console.error("Error: Something unexpected happened");
            return response;
        }
    }
}

export default CourseService;
