import { DateTime } from "luxon";
import { ReactElement, useEffect, useState } from "react";
import ATSDatePicker from "../../components/form/ATSDatePicker";
import ATSSelect, { SelectOption } from "../../components/form/ATSSelect";
import Layout from "../../components/Layout";
import ATSButton, { ATSTypeButton } from "../../components/Shared/ATSButton";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../components/Shared/ATSModal";
import CourseService from "../../services/entitiesServices/CourseService";
import DealerService from "../../services/entitiesServices/DealerService";
import StatesService from "../../services/entitiesServices/StatesService";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import styles from "./Reports.module.scss";
import ReportsService from "../../services/entitiesServices/ReportsService";
import CountriesService from "../../services/entitiesServices/CountriesService";

enum ATSReportsType {
    GENERAL = "general",
    DEALER = "dealer",
    STATE = "state",
}

const MAXDATERANGE = 366;

export default function Reports(): ReactElement {
    const reportFormInit = {};
    const [report, setReport] = useState<string>(ATSReportsType.GENERAL);
    //const [active, setActive] = useState<string>("");
    const [dealersList, setDealersList] = useState<SelectOption[]>([]);
    const [courseTypesList, setCourseTypesList] = useState<SelectOption[]>([]);
    const [statusList, setStatusList] = useState<SelectOption[]>([]);
    const [countriesList, setCountriesList] = useState<SelectOption[]>();
    const [stateList, setStateList] = useState<SelectOption[]>();
    const [resetStates, setResetStates] = useState<boolean>(false);
    const [errors, setErrors] = useState<Record<string, Array<string>>>({});
    const [reportForm, setReportForm] = useState<any>(reportFormInit);

    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);

    useEffect(() => {
        getDealerList();
        getCourseTypeList();
        getStatusList();
        getCountriesList();
    }, []);

    useEffect(() => {
        if (!reportForm.countryId) return;
        setResetStates(true);
        setStateList([]);
        const { state, ...rest } = reportForm;
        setReportForm({ ...rest });
        getStateList(reportForm.countryId);
    }, [reportForm.countryId]);

    async function getDealerList() {
        const res = await DealerService.getDealers(1000, 0);
        if (res && res.success) {
            if (res.response.dealers.length > 1) {
                setDealersList(res.response.dealers.map(dealer => ({ value: dealer.id, label: dealer.name })));
            }
            else {
                setDealersList(res.response.dealers.map(dealer => ({ value: dealer.id, label: dealer.name, default: true })));
            }
        } else {
            reportsModals.unexpectedErrorModal.bodyText = [
                `Sorry we had some issues to retrieve Dealers data: 
                ${res.message}. If this problem persists please contact website admin`,
            ];
            setModalConfig(reportsModals.unexpectedErrorModal);
            setToggleModal(true);
        }
    }

    async function getCourseTypeList() {
        const res = await CourseService.getCourseTypes();
        if (res && res.success) {
            if (res.response.items.length > 1) {
                setCourseTypesList(res.response.items.map(courseType => ({ value: courseType.id, label: courseType.name })));
            }
        } else {
            reportsModals.unexpectedErrorModal.bodyText = [
                `Sorry we had some issues to retrieve courses data: 
                ${res.message}. If this problem persists please contact website admin`,
            ];
            setModalConfig(reportsModals.unexpectedErrorModal);
            setToggleModal(true);
        }
    }

    async function getStatusList() {
        const res = await CourseService.getCourseStatus();
        if (res && res.success) {
            if (res.response.items.length > 1) {
                setStatusList(res.response.items.map(status => ({ value: status.id, label: status.name })));
            }
        } else {
            reportsModals.unexpectedErrorModal.bodyText = [
                `Sorry we had some issues to retrieve course status: 
                ${res.message}. If this problem persists please contact website admin`,
            ];
            setModalConfig(reportsModals.unexpectedErrorModal);
            setToggleModal(true);
        }
    }

    async function getStateList(countryId) {

        const res = await StatesService.getByCountry(countryId);
        if (res && res.success) {
            setResetStates(false);
            setErrors({
                ...errors,
                state: []
            });

            if (res.response.items.length > 1) {
                setStateList(res.response.items.map(state => ({ value: state.code, label: state.name })));
            } else {
                setStateList(res.response.items.map(state => ({ value: state.code, label: state.name, default: true })));
            }
        } else {
            reportsModals.unexpectedErrorModal.bodyText = [
                `Sorry we had some issues to retrieve the states:
                ${res.message}. If this problem persists please contact website admin`,
            ];
            setModalConfig(reportsModals.unexpectedErrorModal);
            setToggleModal(true);
        }
    }


    async function getCountriesList() {
        const res = await CountriesService.getCountries();

        if (res && res.success) {
            if (res.response.items.length > 1) {
                setCountriesList(res.response.items.map(country => ({ value: country.id, label: country.name })));
            } else {
                setCountriesList(res.response.items.map(country => ({ value: country.id, label: country.name, default: true })));
            }
        }
    }

    const handleInputChange = (name: string, value: string | number | boolean): void => {
        setReportForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const getFileName = () => {
        return DateTime.local().toISODate() + `_${report}-report.csv`;
    };

    function setActiveReport(report: string) {
        setReport(report);
        setReportForm({});
        setErrors({});
        setStateList([]);

    }

    function preDownloadingReport() {
        setModalConfig(reportsModals.loadingReport);
        setToggleModal(true);
    }

    function onDownloadError() {
        console.log("Error downloading file.");
        setToggleModal(false);
    }

    const reportCalls = {
        general: useDownloadFile({
            apiDefinition: () => ReportsService.getGeneralReport({ ...reportForm }),
            preDownloading: () => preDownloadingReport(),
            postDownloading: () => setToggleModal(false),
            onError: () => onDownloadError(),
            getFileName,
        }),
        dealer: useDownloadFile({
            apiDefinition: () => ReportsService.getDealerReport({ ...reportForm }),
            preDownloading: () => preDownloadingReport(),
            postDownloading: () => setToggleModal(false),
            onError: () => onDownloadError(),
            getFileName,
        }),
        state: useDownloadFile({
            apiDefinition: () => ReportsService.getStateReport({ ...reportForm }),
            preDownloading: () => preDownloadingReport(),
            postDownloading: () => setToggleModal(false),
            onError: () => onDownloadError(),
            getFileName,
        }),
    };

    async function handleReportDownload(dateFromParam: string, dateToParam: string) {
        if (dateFromParam && dateToParam) {
            // make strings a valid date data
            const date1 = new Date(dateFromParam);
            const date2 = new Date(dateToParam);
            // get the difference between them in milliseconds
            const difference = date2.getTime() - date1.getTime();
            // convert that difference into days
            const differenceDays = Math.ceil(difference / (1000 * 3600 * 24));
            // check if the start is greater than the end date
            const greater = date1.getTime() > date2.getTime();

            if (differenceDays > MAXDATERANGE) {
                handleErrorsChange("dateTo", ["You must enter dates that are no more than 60 days apart"]);
            } else if (greater) {
                handleErrorsChange("dateFrom", ["The start date must not be greater than the end date of the range"]);
            } else {
                setErrors(prev => ({
                    ...prev,
                    dateTo: [""],
                    dateFrom: [""],
                }));
                await reportCalls[report].download();
            }
        } else {
            if (!dateFromParam && !dateToParam) {
                setErrors(prev => ({
                    ...prev,
                    dateFrom: ["You must enter a valid start date"],
                    dateTo: ["You must enter a valid end date"],
                }));
            } else if (!dateFromParam) {
                handleErrorsChange("dateFrom", ["You must enter a valid start date"]);
            } else {
                handleErrorsChange("dateTo", ["You must enter a valid end date"]);
            }
        }
    }

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => {
                setToggleModal(false);
            },
        },
    ];

    const reportsModals = {
        unexpectedErrorModal: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: [""],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                setToggleModal(false);
                reportsModals.unexpectedErrorModal.bodyText = [""];
            },
            buttons: buttonsErrorModal,
        },
        loadingReport: {
            tilte: "Loading...",
            type: ATSTypeModal.LOADING,
            bodyText: ["Please wait while the report is prepared"],
            noClose: true,
        },
    };

    return (
        <Layout title={"Reports"} backHistory={false}>
            <div className={styles.layout}>
                <div className={styles.layout_aside}>
                    <div className="whitebox noPadding">
                        <ul className={styles.list_menu}>
                            <li>
                                <ATSButton
                                    cta="General report"
                                    isActive={report === ATSReportsType.GENERAL}
                                    iconClass="icon-summarize cool"
                                    type={ATSTypeButton.LIST}
                                    action={() => { setActiveReport(ATSReportsType.GENERAL); }}
                                />
                            </li>
                            <li>
                                <ATSButton
                                    cta="Dealer report"
                                    isActive={report === ATSReportsType.DEALER}
                                    iconClass="icon-group cool"
                                    type={ATSTypeButton.LIST}
                                    action={() => { setActiveReport(ATSReportsType.DEALER); }}
                                />
                            </li>
                            <li>
                                <ATSButton
                                    cta="State report"
                                    isActive={report === ATSReportsType.STATE}
                                    iconClass="icon-pin_drop cool"
                                    type={ATSTypeButton.LIST}
                                    action={() => { setActiveReport(ATSReportsType.STATE); }}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.layout_main}>
                    <div className="whitebox hasScroll">
                        <div className="whitebox_heading">
                            <a
                                href={reportCalls[report].url}
                                ref={reportCalls[report].ref}
                                className="hidden"
                                download={reportCalls[report].name}
                            />

                            <a className={styles.downloadLink} onClick={() => handleReportDownload(reportForm.dateFrom, reportForm.dateTo)}>
                                Download
                            </a>
                        </div>
                        <form className="form">
                            {report && report === ATSReportsType.GENERAL && (
                                <fieldset className="form_fieldset maxWidth">
                                    {dealersList && (
                                        <ATSSelect
                                            name="dealerId"
                                            options={dealersList}
                                            placeholder="Select dealer"
                                            label="Dealer"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.dealerId ? errors.dealerId : []}
                                        />
                                    )}
                                    {courseTypesList && (
                                        <ATSSelect
                                            name="courseId"
                                            options={courseTypesList}
                                            placeholder="Select course type"
                                            label="Course"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.courseId ? errors.courseId : []}
                                        />
                                    )}
                                    {statusList && (
                                        <ATSSelect
                                            name="statusId"
                                            options={statusList}
                                            placeholder="Select status"
                                            label="Status"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.statusId ? errors.statusId : []}
                                        />
                                    )}

                                    <div className="form_item">
                                        <h4 className={`form_label ${styles.checkboxLabel}`}>
                                            <input
                                                type="checkbox"
                                                name="onlyApproved"
                                                id="onlyApproved"
                                                checked={reportForm.onlyApproved}
                                                onChange={e => { handleInputChange("onlyApproved", e.target.checked); }}
                                            /><span>Only Approved Students</span></h4>


                                    </div>

                                    <div className="form_fieldset nopad row colx2">
                                        <div className="form_item">
                                            <h4 className="form_label">START COURSE DATE FROM</h4>

                                            <ATSDatePicker
                                                name="dateFrom"
                                                value=""
                                                placeholder="mm/dd/yyyy"
                                                handleChange={handleInputChange}
                                                setErrorMessage={handleErrorsChange}
                                                error={errors && errors.dateFrom ? errors.dateFrom : []}
                                                required
                                            />
                                        </div>
                                        <div className="form_item">
                                            <h4 className="form_label">START COURSE DATE TO</h4>

                                            <ATSDatePicker
                                                name="dateTo"
                                                value=""
                                                placeholder="mm/dd/yyyy"
                                                handleChange={handleInputChange}
                                                setErrorMessage={handleErrorsChange}
                                                error={errors && errors.dateTo ? errors.dateTo : []}
                                                required
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                            {report && report === ATSReportsType.DEALER && (
                                <fieldset className="form_fieldset maxWidth">
                                    {dealersList && (
                                        <ATSSelect
                                            name="dealerId"
                                            options={dealersList}
                                            placeholder="Select dealer"
                                            label="Dealer"
                                            handleChange={handleInputChange}
                                            setErrorMessage={handleErrorsChange}
                                            error={errors && errors.dealerId ? errors.dealerId : []}
                                        />
                                    )}
                                    <div className="form_fieldset nopad row colx2">
                                        <div className="form_item">
                                            <h4 className="form_label">START COURSE DATE FROM</h4>
                                            <ATSDatePicker
                                                name="dateFrom"
                                                value=""
                                                placeholder="mm/dd/yyyy"
                                                handleChange={handleInputChange}
                                                setErrorMessage={handleErrorsChange}
                                                error={errors && errors.dateFrom ? errors.dateFrom : []}
                                            />
                                        </div>
                                        <div className="form_item">
                                            <h4 className="form_label">START COURSE DATE TO</h4>
                                            <ATSDatePicker
                                                name="dateTo"
                                                value=""
                                                placeholder="mm/dd/yyyy"
                                                handleChange={handleInputChange}
                                                error={errors && errors.dateTo ? errors.dateTo : []}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                            {report && report === ATSReportsType.STATE && (
                                <fieldset className="form_fieldset maxWidth">
                                    <ATSSelect
                                        name="countryId"
                                        options={countriesList ?? []}
                                        placeholder="Select Country"
                                        label="Country"
                                        handleChange={handleInputChange}
                                        setErrorMessage={handleErrorsChange}
                                        error={errors && errors.country ? errors.country : []}
                                    />
                                    <ATSSelect
                                        name="state"
                                        options={stateList ?? []}
                                        placeholder="Select State"
                                        label="State"
                                        handleChange={handleInputChange}
                                        setErrorMessage={handleErrorsChange}
                                        error={errors && errors.state ? errors.state : []}
                                        resetValues={resetStates}
                                    />
                                    <div className="form_fieldset nopad row colx2">
                                        <div className="form_item">
                                            <h4 className="form_label">START COURSE DATE FROM</h4>
                                            <ATSDatePicker
                                                name="dateFrom"
                                                value=""
                                                placeholder="mm/dd/yyyy"
                                                handleChange={handleInputChange}
                                                error={errors && errors.dateFrom ? errors.dateFrom : []}
                                            />
                                        </div>
                                        <div className="form_item">
                                            <h4 className="form_label">START COURSE DATE TO</h4>
                                            <ATSDatePicker
                                                name="dateTo"
                                                value=""
                                                placeholder="mm/dd/yyyy"
                                                handleChange={handleInputChange}
                                                error={errors && errors.dateTo ? errors.dateTo : []}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}
