import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { IDashboardDataResponse } from "../interfaces/IDashboard";

class DashboardService extends ApiBaseService {
    public static async getDashboardData(limit): Promise<IResponse<IDashboardDataResponse>> {
        const response = await this.securedPost<IDashboardDataResponse>(`dashboard`, { limit });
        if (response.success && response != null && response.response) {
            return response;
        } else {
            return response;
        }
    }
}

export default DashboardService;


