import React, { useEffect } from 'react';
import { RadioOption } from '..';
import styles from './SimpleRadioButton.module.scss';

interface RadioCardComponent {
    name: string;
    option: RadioOption,
    handleChange: (name: string, value: number) => void;
}

function SimpleRadioButtons(props: any) {
    const { option, handleChange, name } = props;

    useEffect(() => {
        if (!option.default) return;
        handleChange(name, option.value);
    }, []);

    return (
        <label htmlFor={`${option.value}`} className={styles.simpleRadioButton} onPointerDown={() => handleChange(name, option.value)} >
            <input id={`${option.value}`} type="radio" name={name} defaultChecked={option.default} />
            <span className='form_label'>{option.label}</span>
        </label>
    );
}

export default SimpleRadioButtons;