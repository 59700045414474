import React from "react";
import ATSInfoItem from "../../../../../components/Shared/ATSInfoItem";

function TransactionComponent(props: any) {
    const { transactionData } = props;
    return (
        <div className="whitebox has_heading">
            <div className="whitebox_heading">
                <h3 className="whitebox_heading_title">Transaction</h3>
            </div>
            <div className="whitebox_content">
                <ul className="whitebox_list inColumns">
                    {transactionData &&
                        transactionData.map((data, i) => {
                            if (data.value === "") return <React.Fragment key={`payment_${data.label}_${i}`}></React.Fragment>;
                            else return <ATSInfoItem title={data.label} value={data.value} key={`payment_${data.label}_${i}`} />;
                        })}
                </ul>
            </div>
        </div>
    );
}

export default TransactionComponent;
