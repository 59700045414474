import { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import ATSNumberInput from "../../../components/form/ATSNumberInput";
import ATSCurrencyInput from "../../../components/form/ATSCurrencyInput";
import ATSSelect, { SelectOption } from "../../../components/form/ATSSelect";
import CourseService from "../../../services/entitiesServices/CourseService";
import styles from "./CreateCourse.module.scss";
import InstanceDate, { ICourseInstances } from "./InstanceDate";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { IAddCourseInstance } from "../../../services/interfaces/servicePostBody/course/ICourseAdd";
import { copyJSON, IObjectValidationsProperties, objectValidations } from "../../../helpers/helpers";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import ATSErrorMsg from "../../../components/form/ATSErrorMsg";
import React from "react";
import { useNavigate } from "react-router-dom";

const RESCHEDULED_ID = 4;
const CANCEL_ID = 2;

function CreateCourse() {
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();

    const [courseForm, setCreateCourseForm] = useState<any>({
        seats: 1,
        minimumSeats: 0,
    });
    const [courseLocation, setCourseLocation] = useState<SelectOption[]>();
    const [courseList, setCourseList] = useState<SelectOption[]>();
    const [courseStatus, setCourseStatus] = useState<SelectOption[]>();
    const [courseInstances, setCourseInstances] = useState<ICourseInstances[]>([{}]);
    const [errorsValidations, setErrors] = useState<any>();
    const [errorInstances, setErrorInstances] = useState<any>([]);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    //#region get select options

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        setLayoutLoading(true);
        await Promise.allSettled([
            CourseService.getCourseLocation().then(function (res) {
                if (res && res.success) setCourseLocation(res.response.items.map(location => ({ value: location.id, label: location.name })));
            }),
            CourseService.getCourseTypes().then(function (res) {
                if (res && res.success) setCourseList(res.response.items.map(courseType => ({ value: courseType.id, label: courseType.name })));
            }),
            CourseService.getCourseStatus().then(function (res) {
                if (res && res.success) {
                    const statusFiltered: SelectOption[] = [];
                    res.response.items.forEach(status => {
                        //rescheduled and cancel course status removed from options
                        if (status.id === RESCHEDULED_ID || status.id === CANCEL_ID) return;
                        statusFiltered.push({ value: status.id, label: status.name });
                    });
                    setCourseStatus(statusFiltered);
                }
            }),
        ]);
        setLayoutLoading(false);
    }

    //#endregion

    useEffect(() => {
        setCreateCourseForm(prev => ({
            ...prev,
            courseInstanceDates: courseInstances,
        }));
        handleErrorsChange("courseInstanceDates", []);
    }, [courseInstances]);

    const handleInputChange = (name: string, value: string | number): void => {
        setCreateCourseForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleNewCoursesInstances = () => {
        setCourseInstances(prev => [
            ...prev,
            {
                date: ``,
            },
        ]);
    };

    const deleteSpecificCourseInstance = (event: any, index: number) => {
        event.preventDefault();
        let mutateState = copyJSON(courseInstances);
        mutateState.splice(index, 1);
        setCourseInstances(mutateState);
        manageErrorInstances(index, []);
    };

    const manageInstances = (index, dataset) => {
        let mutateState = copyJSON(courseInstances);
        mutateState[index] = dataset;
        setCourseInstances(mutateState);
        // -- Remove backend errors
        if (errorsValidations && errorsValidations.courseInstanceDates) {
            setErrors({ errorsValidations, ...{ courseInstanceDates: [] } });
        }
    };

    const manageErrorInstances = (index, dataset) => {
        let mutateState = copyJSON(errorInstances);
        mutateState[index] = dataset;
        setErrorInstances(mutateState);
    };

    const handleSubmit = async () => {
        const validations: Array<IObjectValidationsProperties> = [
            {
                key: "courseId",
                type: "number",
                required: true,
            },
            {
                key: "locationId",
                type: "number",
                required: true,
            },
            {
                key: "price",
                type: "number",
                required: true,
            },
            {
                key: "courseStatusId",
                type: "number",
                required: true,
            },
            {
                key: "seats",
                type: "number",
                required: true,
            },
            {
                key: "minimumSeats",
                type: "number",
                required: true,
            },
            {
                key: "courseInstanceDates",
                type: "array",
                required: true,
            },
        ];
        const properties = objectValidations(courseForm, validations);
        const instances = errorInstances.length > 0 && errorInstances.every(x => x.length);
        const datesValue = courseForm.courseInstanceDates.every(x => x.date !== "" && JSON.stringify(x) !== "{}");
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations && !instances && datesValue) {
            try {
                console.log(courseForm);
                const response = await CourseService.createCourse(courseForm as IAddCourseInstance);
                if (response.success) {
                    setModalConfig(createCourseModals.success);
                    setToggleModal(true);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(errors);
                    response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                    if (response.httpStatusCode === 500) {
                        setModalConfig(createCourseModals.unexpectedError);
                        setToggleModal(true);
                    }
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            // -- Show default error if there is an invalid date but not errors populated
            if (!datesValue && (!errorInstances || errorInstances.every(x => x.length === 0))) {
                errors = {
                    ...errors,
                    courseInstanceDates: ["Please complete the remaining date"],
                };
            }

            /* if (errors) {
                createCourseModals.error.bodyText = { ...errors };
                setModalConfig(createCourseModals.error);
                setToggleModal(true);
            } */

            setErrors(errors);
            console.error("validations not passed");
        }
    };

    const buttons: IATSButtons[] = [
        {
            cta: "Create",
            type: ATSTypeButton.NEGATIVE,
            action: handleSubmit,
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: "/courses",
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const createCourseModals = {
        comment: {
            commentValue: courseForm.comments,
            type: ATSTypeModal.COMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: commentValue => {
                handleInputChange("comments", commentValue);
                setToggleModal(false);
            },
        },
        success: {
            title: "Successfully created",
            icon: "icon-check_circle_fill success",
            bodyText: ["The course and instances were successfully created."],
            type: ATSTypeModal.DEFAULT,
            buttons: buttonsModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => navigate("/courses", { replace: true }),
        },
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [
                "Sorry, your course and instances were NOT created successfully.",
                "There was an unexpected error , check the information and try again.",
                " If the problem persist call an administrator",
            ],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        error: {
            title: "Error",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };
    //#endregion

    return (
        <Layout title={"Create Course"} backHistory={true} buttons={buttons} isLoading={layoutLoading}>
            <div className={styles.layout}>
                <div className={styles.layout_area}>
                    <div className="whitebox centered">
                        {!courseForm.comments && (
                            <ATSButton
                                cta="+ Add comment"
                                type={ATSTypeButton.FORM}
                                action={() => {
                                    setModalConfig(createCourseModals.comment);
                                    setToggleModal(true);
                                }}
                            />
                        )}
                        {courseForm.comments && (
                            <div className="whitebox_content">
                                <h3 className="form_label">
                                    Comments
                                    <button
                                        onClick={() => {
                                            setModalConfig(createCourseModals.comment);
                                            setToggleModal(true);
                                        }}>
                                        <i className={`icon-edit cool ${styles.iconEditComment}`}></i>
                                    </button>
                                </h3>
                                <p>{courseForm.comments}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.layout_area}>
                    <div className="whitebox hasScroll">
                        <form className="form">
                            <h2 className="form_title">General Information</h2>

                            <fieldset className="form_fieldset maxWidth">
                                {courseList && (
                                    <ATSSelect
                                        name={"courseId"}
                                        options={courseList}
                                        placeholder={"Select Course"}
                                        label={"Course name"}
                                        handleChange={handleInputChange}
                                        required
                                        setErrorMessage={handleErrorsChange}
                                        error={errorsValidations?.courseId}
                                    />
                                )}
                                {courseLocation && (
                                    <ATSSelect
                                        name={"locationId"}
                                        options={courseLocation}
                                        placeholder={"Select Location"}
                                        label={"Location"}
                                        handleChange={handleInputChange}
                                        required
                                        setErrorMessage={handleErrorsChange}
                                        error={errorsValidations?.locationId}
                                    />
                                )}
                                <div className="form_fieldset nopad row colx2">
                                    <ATSCurrencyInput
                                        name={"price"}
                                        label={"Price"}
                                        handleChange={handleInputChange}
                                        required
                                        setErrorMessage={handleErrorsChange}
                                        error={errorsValidations?.price}
                                        maxLengthProps={11}
                                    />
                                    {courseStatus && (
                                        <ATSSelect
                                            name={"courseStatusId"}
                                            options={courseStatus}
                                            placeholder={"Select Status"}
                                            label={"Status"}
                                            handleChange={handleInputChange}
                                            required
                                            setErrorMessage={handleErrorsChange}
                                            error={errorsValidations?.courseStatusId}
                                        />
                                    )}
                                </div>
                                <div className="form_fieldset nopad row colx2">
                                    <ATSNumberInput
                                        name={"seats"}
                                        label={"Total Seats"}
                                        handleChange={handleInputChange}
                                        min={1}
                                        setErrorMessage={handleErrorsChange}
                                        error={errorsValidations?.seats}
                                        maxLengthProps={4}
                                    />
                                    <ATSNumberInput
                                        name={"minimumSeats"}
                                        label={"Minimum Seats"}
                                        handleChange={handleInputChange}
                                        max={courseForm.seats ?? 0}
                                        setErrorMessage={handleErrorsChange}
                                        error={errorsValidations?.minimumSeats}
                                        maxLengthProps={4}
                                    />
                                </div>
                            </fieldset>
                            <h2 className="form_title">Course Schedule</h2>
                            <fieldset className="form_fieldset maxWidth">
                                {courseInstances.map((_instance, i) => (
                                    <React.Fragment key={`instances_${i}`}>
                                        <div className={styles.layout_headingDate}>
                                            <h3 className="form_label">Date {i + 1}</h3>
                                            {i !== 0 && (
                                                <button
                                                    className={styles.layout_headingDate_close}
                                                    onClick={e => deleteSpecificCourseInstance(e, i)}>
                                                    <i className="icon-close" />
                                                </button>
                                            )}
                                        </div>
                                        <InstanceDate
                                            key={i}
                                            index={i}
                                            parentInstance={courseInstances[i]}
                                            handleData={manageInstances}
                                            error={errorInstances[i]}
                                            setErrorInstances={manageErrorInstances}
                                            required
                                        />
                                    </React.Fragment>
                                ))}
                                <div className="form_item showError">
                                    {errorsValidations?.courseInstanceDates && <ATSErrorMsg errors={errorsValidations.courseInstanceDates} />}
                                </div>
                                <ATSButton type={ATSTypeButton.FORM} cta={"+ Additional Date"} action={handleNewCoursesInstances} />
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default CreateCourse;
