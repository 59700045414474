import React from "react";
import { ReactElement, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { copyJSON, formatDate, formatDateSlash, getStartTime, objectValidations, timeConvert } from "../../../helpers/helpers";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import { ICourse } from "../../../services/interfaces/ICourse";
import {
    ICourseInstanceDateRequest,
    IRescheduleCourseRequest,
} from "../../../services/interfaces/servicePostBody/course/IRescheduleCourseRequest";
import InstanceDate from "../CreateCourse/InstanceDate";
import Layout from "../../../components/Layout";
import ATSSpinner from "../../../components/Shared/ATSSpinner";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import CourseService from "../../../services/entitiesServices/CourseService";
import ATSErrorMsg from "../../../components/form/ATSErrorMsg";
import styles from "./RescheduleCourse.module.scss";
import { ICourseInstanceDate } from "../../../services/interfaces/serviceResponses/course/ISingleCourseResponse";

function RescheduleCourse(): ReactElement {
    const courseInit = {
        id: 0,
        courseName: "",
        date: "",
        startTime: 0,
        startTimeMinute: 0,
        location: "",
        seats: 0,
        seatsOccupied: 0,
        price: 0,
        courseStatusName: "",
        days: 0,
        comments: "",
        cancelComments: "",
        rescheduleComments: [],
        minimumSeats: 0,
    };

    const courseRescheduleFormInit = {
        courseInstanceId: 0,
        comments: "",
        dates: [],
    };

    const { courseId } = useParams();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();
    const [rescheduleForm, setRescheduleForm] = useState<IRescheduleCourseRequest>(courseRescheduleFormInit);
    const [dateList, setDatesList] = useState<ICourseInstanceDate[]>([]);
    const [course, setCourse] = useState<ICourse>(courseInit);
    const [errorsValidations, setErrors] = useState<any>();
    const [errorInstances, setErrorInstances] = useState<any>([]);
    const [courseInstances, setCourseInstances] = useState<ICourseInstanceDateRequest[]>([]);
    const [rescheduleComment, setRescheduleComment] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadCourse();
    }, []);

    useEffect(() => {
        handleDateChange(courseInstances);
    }, [courseInstances]);

    useEffect(() => {
        handleCommentChange(rescheduleComment);
    }, [rescheduleComment]);

    const loadCourse = async () => {
        setLoading(true);
        let id = courseId ? +courseId : 0;

        await Promise.allSettled([
            CourseService.getCourse(id).then(function (res) {
                if (res && res.success) {
                    setCourse(res.response.courseInstanceDataView);
                    setDatesList(res.response.courseInstanceDateDataViews);
                    let formattedDate = res.response.courseInstanceDateDataViews.map(date => {
                        let formatted = {
                            date: formatDateSlash(date.date.toString()),
                            endTime: date.endTime,
                            endTimeMinute: date.endTimeMinute,
                            startTime: date.startTime,
                            startTimeMinute: date.startTimeMinute,
                        };
                        return formatted;
                    });
                    setCourseInstances(formattedDate);
                    setRescheduleForm(prev => ({
                        ...prev,
                        courseInstanceId: res.response.courseInstanceDataView.id,
                    }));

                    setRescheduleForm(prev => ({
                        ...prev,
                        dates: formattedDate,
                    }));
                }
            }),
        ]);

        setLoading(false);
    };

    const handleDateChange = (courseInstancesDates: ICourseInstanceDateRequest[]): void => {
        setRescheduleForm(prev => ({
            ...prev,
            dates: courseInstancesDates,
        }));
    };

    const handleCommentChange = (comments: string): void => {
        setRescheduleForm(prev => ({
            ...prev,
            comments,
        }));
    };

    const handleErrorsChange = (errors: string[]): void => {
        setErrors(prev => ({
            ...prev,
            errors,
        }));
    };

    const handleNewCoursesInstances = () => {
        setCourseInstances(prev => [
            ...prev,
            {
                date: "",
                endTime: 0,
                endTimeMinute: 0,
                startTime: 0,
                startTimeMinute: 0,
            },
        ]);
    };

    const deleteSpecificCourseInstance = (event: any, index: number) => {
        event.preventDefault();
        let mutateState = copyJSON(courseInstances);
        mutateState.splice(index, 1);
        setCourseInstances(mutateState);
    };

    const manageInstances = (index, dataset) => {
        let mutateState = copyJSON(courseInstances);
        mutateState[index] = dataset;
        setCourseInstances(mutateState);
    };

    const manageErrorInstances = (index, dataset) => {
        let mutateState = copyJSON(errorInstances);
        mutateState[index] = dataset;
        setErrorInstances(mutateState);
    };

    async function handleSubmit() {
        const validations = [
            {
                key: "dates",
                type: "array",
                required: true,
            },
        ];
        const properties = objectValidations(rescheduleForm, validations);
        const instances = errorInstances && errorInstances?.length > 0 && errorInstances?.every(x => x?.length);
        const datesValue = rescheduleForm.dates.every(x => x.date !== "");
        const rescheduleComment = rescheduleForm.comments && rescheduleForm.comments.length > 0 ? true : false;
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations && !instances && datesValue && rescheduleComment) {
            try {
                const response = await CourseService.rescheduleCourse(rescheduleForm as IRescheduleCourseRequest);
                if (response.success) {
                    setModalConfig(rescheduleModals.success);
                    setToggleModal(true);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(errors);

                    if (response.httpStatusCode === 500) {
                        response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                        setModalConfig(rescheduleModals.unexpectedError);
                        setToggleModal(true);
                    } else {
                        Object.entries(errors).map(error => rescheduleModals.validateError.bodyText.push(`${error[1]} `));
                        setModalConfig(rescheduleModals.validateError);
                        setToggleModal(true);
                    }
                }
            } catch (e) {
                //do something
            }
        } else if (rescheduleComment === null || rescheduleComment === undefined || rescheduleComment === false) {
            setModalConfig(rescheduleModals.comment);
            setToggleModal(true);
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });

            if (!datesValue) {
                errors = {
                    ...errors,
                    courseInstanceDates: ["Please complete the remaining date"],
                };
            } else {
                errors = {
                    ...errors,
                    courseInstanceDates: errorInstances,
                };
            }
            handleErrorsChange(errors);
            let hasErrors = false;
            for (let key in errors) {
                if (errors[key].length > 0) {
                    hasErrors = true;
                    break;
                }
            }
            if (hasErrors) {
                Object.entries(errors).map(error => rescheduleModals.validateError.bodyText.push(`${error[1]} `));
                setModalConfig(rescheduleModals.validateError);
                setToggleModal(true);
            } else {
            }
        }
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Reschedule",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: `/courses/${courseId}`,
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const rescheduleModals = {
        validateError: {
            title: "Reschedule course",
            icon: "icon-cancel danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [""],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
        comment: {
            commentValue: rescheduleForm.comments,
            type: ATSTypeModal.COMMENT,
            handleClose: () => setToggleModal(false),
            handleSetComment: value => {
                setRescheduleComment(value);
                setToggleModal(false);
            },
        },
        success: {
            title: "Successfully updated",
            icon: "icon-check_circle_fill success",
            bodyText: ["The course was successfully updated."],
            type: ATSTypeModal.DEFAULT,
            buttons: buttonsModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => {
                navigate(`/courses/${courseId}`);
                //setToggleModal(false)
            },
        },
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            type: ATSTypeModal.DEFAULT,
            bodyText: [
                "Sorry, the course was NOT updated.",
                "There was an unexpected error, check the information and try again.",
                "If the problem persist call an administrator",
            ],
            bodyTextType: ATSBodyTextTypeModal.SUBTITLE,
            buttons: buttonsErrorModal,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
        },
    };

    //#endregion

    return (
        <Layout title={"Reschedule Course"} backHistory={true} buttons={buttons}>
            {!loading ? (
                <div className={styles.layout}>
                    <div className={styles.layout_aside}>
                        <div className="whitebox has_heading">
                            <div className="whitebox_heading">
                                <h2 className="whitebox_heading_title">Course information</h2>
                            </div>
                            <div className="whitebox_content">
                                <ul className="whitebox_list">
                                    {course && (
                                        <>
                                            <ATSInfoItem title={"COURSE"} value={course.courseName} />
                                            <ATSInfoItem title={"LOCATION"} value={course.location} />
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="whitebox has_heading">
                            <div className="whitebox_heading">
                                <h2 className="whitebox_heading_title">Previous dates</h2>
                            </div>
                            <div className="whitebox_content">
                                <ul className="whitebox_list">
                                    {dateList &&
                                        dateList.map((date, i) => (
                                            <div key={"datelist" + i} className={styles.infoWrapper}>
                                                <ATSInfoItem title={`DATE ${i + 1}`} value={formatDate(date.date.toString())} />
                                                <ATSInfoItem
                                                    title={"FROM"}
                                                    value={timeConvert(getStartTime(date.startTime, date.startTimeMinute))}
                                                />
                                                <ATSInfoItem title={"TO"} value={timeConvert(getStartTime(date.endTime, date.endTimeMinute))} />
                                            </div>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        <div className="whitebox centered">
                            {!rescheduleForm.comments && (
                                <ATSButton
                                    cta="+ Add reschedule comment"
                                    type={ATSTypeButton.FORM}
                                    action={() => {
                                        setModalConfig(rescheduleModals.comment);
                                        setToggleModal(true);
                                    }}
                                />
                            )}
                            {rescheduleForm.comments && (
                                <div className="whitebox_content">
                                    <h3 className="form_label">
                                        Reschedule Comments
                                        <button
                                            onClick={() => {
                                                setModalConfig(rescheduleModals.comment);
                                                setToggleModal(true);
                                            }}>
                                            <i className={`icon-edit cool ${styles.iconEditComment}`}></i>
                                        </button>
                                    </h3>
                                    <p>{rescheduleForm.comments}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.layout_main}>
                        <div className="whitebox hasScroll">
                            <form className="form">
                                <div className="whitebox_heading">
                                    <i className="icon-update"></i>
                                    <h2 className="whitebox_heading_title">Process of rescheduling</h2>
                                </div>
                                <fieldset className="form_fieldset maxWidth">
                                    <p>Select a new date and time for this course</p>
                                    {errorsValidations?.courseInstanceDates && <ATSErrorMsg errors={errorsValidations.courseInstanceDates} />}
                                    {courseInstances.map((_instance, i) => (
                                        <React.Fragment key={`instances_${i}`}>
                                            <div className={styles.layout_headingDate}>
                                                <h3 className="form_label">Date {i + 1}</h3>
                                                {i !== 0 && (
                                                    <button
                                                        className={styles.layout_headingDate_close}
                                                        onClick={e => deleteSpecificCourseInstance(e, i)}>
                                                        <i className="icon-close" />
                                                    </button>
                                                )}
                                            </div>
                                            <InstanceDate
                                                key={i}
                                                index={i}
                                                parentInstance={courseInstances[i]}
                                                handleData={manageInstances}
                                                error={errorInstances[i]}
                                                setErrorInstances={manageErrorInstances}
                                                required
                                            />
                                        </React.Fragment>
                                    ))}
                                    <div className="form_fieldset group">
                                        <ATSButton type={ATSTypeButton.FORM} cta={"+ Additional Date"} action={handleNewCoursesInstances} />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <ATSSpinner />
            )}
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default RescheduleCourse;
