import { useState } from "react";
import { IRoute, routes, sideBarPosition } from "../../../navigation/routes";
import ButtonNav from "./ButtonNav";
import styles from "./Sidebar.module.scss";
import logo from "../../../assets/images/logo.jpg";

interface ISideBarProps {
    handleLogOut: () => void;
    userSettings?: boolean;
}

function Sidebar(props: ISideBarProps) {
    const { handleLogOut, userSettings } = props;
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [showUserOptions, setShowUserOptions] = useState<boolean>(userSettings ?? false);



    const handleColapse = () => {
        setCollapsed(!collapsed);
    };

    return (
        <aside className={`${styles.ats_aside} ${collapsed && styles.collapsed} print-hidden`}>
            <header className={styles.ats_aside_header}>
                <div className={styles.ats_aside_header_identity}>
                    <img src={logo} alt="ats-logo" />
                </div>
            </header>
            <nav className={styles.ats_aside_nav}>
                <ul className={styles.ats_aside_menu}>
                    {routes.map((route: IRoute) =>
                        route.showInSidebar &&
                        route.positionSidebar === sideBarPosition.TOP &&
                        <ButtonNav url={route.url} label={route.label || route.url} iconClass={route.iconClass || ""} key={route.url} />
                    )}
                </ul>
            </nav>
            <footer className={styles.ats_aside_footer}>
                <ul className={styles.ats_aside_menu}>
                    <li className={`${showUserOptions ? styles.showOptions : ''}`}>
                        <button className={`${styles.ats_aside_menu_item}`} onClick={() => { setShowUserOptions(!showUserOptions); }}>
                            <i className="icon-account_circle"></i>
                            <span>User</span>
                            <i className={`icon-expand_less ${styles.ats_aside_showbtn}`}></i>
                        </button>
                        <ul className={styles.ats_aside_submenu}>
                            {routes.map((route: IRoute) =>
                                route.showInSidebar &&
                                route.positionSidebar === sideBarPosition.BOTTOM &&
                                <ButtonNav url={route.url} label={route.label || route.url} iconClass={route.iconClass || ""} key={route.url} />
                            )}
                            <li>
                                <button className={styles.ats_aside_menu_item} onClick={handleLogOut}>
                                    <i className="icon-logout"></i>
                                    <span>Log out</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <button className={`${styles.ats_aside_menu_item} ${styles.collapse}`} onClick={handleColapse}>
                            <i className={`${collapsed ? "icon-menu_open" : "icon-menu_close "}`}></i>
                        </button>
                    </li>
                </ul>
            </footer>
        </aside>
    );
}

export default Sidebar;