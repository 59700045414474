import ApiService from "./ApiService";
import ApiSecuredService from "./ApiSecuredService";

export interface IResponse<T> {
    success: boolean;
    message: string;
    httpStatusCode: number;
    response: T;
}

export interface IResponseForm<T> {
    success: boolean;
    message: string;
    httpStatusCode: number;
    response: T;
    validationErrors?: IErrorMessage[];
}

export interface IErrorMessage {
    field: string;
    messages: string[];
}

class ApiBaseService {
    private static readonly isWindow: any = typeof window !== "undefined" ? window : null;
    private static readonly token: string = this.isWindow ? (localStorage.getItem("jwt-token") as string) : "";

    // ********************************************** //
    // ************* Secured  methods *************** //
    // ********************************************** //
    //#region Secured
    // -- Secured get
    protected static async securedGet<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiSecuredService.get(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error: any) {
            return { success: false, message: "Unexpected error", httpStatusCode: error.response?.status, response: null as any };
        }
    }

    // -- Secured post
    protected static async securedPost<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiSecuredService.post(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error: any) {
            return { success: false, message: "Unexpected error", httpStatusCode: error.response?.status, response: null as any };
        }
    }

    // -- Secured put
    private static async securedPut<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiSecuredService.put(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error: any) {
            return { success: false, message: "Unexpected error", httpStatusCode: error.response?.status, response: null as any };
        }
    }
    // ********************************************** //
    // ************* Secured  methods *************** //
    // ********************************************** //
    //#endregion

    // ********************************************** //
    // ************ Unsecured  methods ************** //
    // ********************************************** //
    //#region unsecured
    // -- Unsecured get
    private static async unsecuredGet<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiService.get(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error) {
            return { success: false, message: "Unexpected error", httpStatusCode: 0, response: null as any };
        }
    }

    // -- Unsecured post
    protected static async unsecuredPost<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiService.post(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error) {
            return { success: false, message: "Unexpected error", httpStatusCode: 0, response: null as any };
        }
    }

    // -- Unsecured get
    private static async unsecuredPut<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiService.put(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error) {
            return { success: false, message: "Unexpected error", httpStatusCode: 0, response: null as any };
        }
    }

    // ********************************************** //
    // ************ Unsecured  methods ************** //
    // ********************************************** //
    //#endregion
}
export default ApiBaseService;
