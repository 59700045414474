import ApiBaseService, { IResponse, IResponseForm } from "../apiServices/ApiBaseService";
import { IDealerCommentUpdateRequest } from "../interfaces/servicePostBody/dealer/ICommentUpdateRequest";
import { ICreateDealerRequest } from "../interfaces/servicePostBody/dealer/ICreateDealerRequest";
import { IEditDealerRequest } from "../interfaces/servicePostBody/dealer/IEditDealerRequest";
import { IGenerateDealerTokenRequest } from "../interfaces/servicePostBody/dealer/IGenerateDealerTokenRequest";
import { ICreateDealerResponse } from "../interfaces/serviceResponses/dealer/ICreateDealerResponse";
import { IDealerCommentUpdateResponse } from "../interfaces/serviceResponses/dealer/IDealerCommentUpdateResponse";
import { IDealersResponse } from "../interfaces/serviceResponses/dealer/IDealersResponse";
import { IEditDealerResponse } from "../interfaces/serviceResponses/dealer/IEditDealerResponse";
import { IGenerateDealerTokenResponse } from "../interfaces/serviceResponses/dealer/IGenerateDealerResponse";
import { ISingleDealerData } from "../interfaces/serviceResponses/dealer/ISingleDealerResponse";

class DealerService extends ApiBaseService {
    public static async getDealers(limit, offset): Promise<IResponse<IDealersResponse>> {
        const response = await this.securedPost<IDealersResponse>(`dealer`, { limit, offset });
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getFilteredDealers(filtersApplied): Promise<IResponse<IDealersResponse>> {
        const response = await this.securedPost<IDealersResponse>(`dealer/dealerFiltered`, filtersApplied);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getDealer(id): Promise<IResponse<ISingleDealerData>> {
        const response = await this.securedGet<ISingleDealerData>(`dealer/single/${id}`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async updateDealerComment(body: IDealerCommentUpdateRequest): Promise<IResponse<IDealerCommentUpdateResponse>> {
        const response = await this.securedPost<IDealerCommentUpdateResponse>(`dealer/comments`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async createDealer(body: ICreateDealerRequest): Promise<IResponseForm<ICreateDealerResponse>> {
        const response = await this.securedPost<ICreateDealerResponse>(`dealer/insert`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async editDealer(body: IEditDealerRequest): Promise<IResponseForm<IEditDealerResponse>> {
        const response = await this.securedPost<IEditDealerResponse>(`dealer/edit`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async generateToken(body: IGenerateDealerTokenRequest): Promise<IResponse<IGenerateDealerTokenResponse>> {
        const response = await this.securedPost<IGenerateDealerTokenResponse>(`dealer/generateToken`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }
}

export default DealerService;
