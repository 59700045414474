import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { IStatesResponse } from "../interfaces/serviceResponses/states/IStatesResponse";

class StatesService extends ApiBaseService {
    public static async getStates(): Promise<IResponse<IStatesResponse>> {
        const response = await this.securedGet<IStatesResponse>(`state`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }

    public static async getByCountry(countryId: number): Promise<IResponse<IStatesResponse>> {
        const response = await this.securedGet<IStatesResponse>(`state/country/${countryId}`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.log(`Error: ${response.message}`);
            return response;
        }
    }
}

export default StatesService;
