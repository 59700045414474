import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ATSRadioInput, { ATSRadioType } from "../../../components/form/ATSRadioInput";
import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import ATSTooltip, { ATSTooltipAlignment, ATSTooltipBodyAlignment } from "../../../components/Shared/ATSTooltip";
import ATSWidget, { ATSWidgetType } from "../../../components/Shared/ATSWidget";
import {
    currencyDollarUS,
    formatDate,
    formatDateFull,
    getStartTime,
    IObjectValidationsProperties,
    objectValidations,
    timeConvert,
} from "../../../helpers/helpers";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import ATSCurrencyInput from "../../../components/form/ATSCurrencyInput";
import ATSTextAreaInput from "../../../components/form/ATSTextAreaInput";
import Layout from "../../../components/Layout";
import ATSVirtualList from "../../../components/Shared/ATSVirtualList";
import PaymentService from "../../../services/entitiesServices/PaymentService";
import styles from "./RefundPayment.module.scss";

function RefundPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const { students, payment } = location.state as any;
    const [statusRefund, setStatusRefund] = useState<any>({ paymentId: payment.id, fullRefund: true, amount: payment.available });
    const [paymentValue, setPaymentValue] = useState<number>(payment.available);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errors, setErrors] = useState<any>();
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const validations: Array<IObjectValidationsProperties> = [
        {
            key: "fullRefund",
            type: "boolean",
            required: true,
        },
        {
            key: "paymentId",
            type: "number",
            required: true,
        },
        {
            key: "reason",
            type: "string",
            required: true,
        },
        {
            key: "amount",
            type: "number",
        },
    ];

    useEffect(() => {
        const payValue = statusRefund.fullRefund ? payment.available : 0;
        setPaymentValue(payValue);
    }, [statusRefund]);

    const statusRefundValidations = objectValidations(statusRefund, validations);

    const handleSubmit = async () => {
        if (statusRefundValidations && statusRefundValidations.hasRequiredInputs && statusRefundValidations.passTypeValidations) {
            try {
                setLayoutLoading(true);
                const response = await PaymentService.refundPayment(statusRefund);
                if (response.success) {
                    navigate(`success`, { state: { refundAmount: statusRefund.amount, pending: response.response["pending"] } });
                } else {
                    navigate(`fail`, { state: { error: response.message } });
                }
                setLayoutLoading(false);
            } catch {
                setErrorMessage("Unexpected Error");
                setOpenModal(true);
            }
        } else if (statusRefundValidations) {
            // show errors statusRefundValidations.inputs
            let errors;
            statusRefundValidations.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });
            setErrors(errors);
        }
    };

    const daysUntil = () => {
        const starDate = new Date(payment.startDate).getTime();
        const today = new Date().getTime();
        let difference = starDate - today;
        const daysDiff = Math.ceil(difference / (1000 * 3600 * 24));
        if (daysDiff >= 1) return daysDiff;
        return "";
    };

    const subtitleWidgetHelper = (): string => {
        const days = daysUntil();
        if (days === 1) return "day until this course begins";
        else if (days > 1) return "days until this course begins";
        else return "started this course";
    };

    const refundFullOptions = [
        {
            label: "Full refund",
            value: true,
            default: true,
        },
        {
            label: "Partial Refund",
            value: false,
            default: false,
        },
    ];

    const refundOnlyPartialOptions = [
        {
            label: "Partial Refund",
            value: false,
            default: true,
        },
    ];

    const handleChange = (name: string, value: any) => {
        if (name === "fullRefund" && value === true) handleErrorsChange("amount", []);
        setStatusRefund(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const disableButton = () => {
        // Manual enrollments cannot be refunded
        if (payment.methodId === 3) return true;
        if (!statusRefund) return true;
        if (!statusRefund.fullRefund && !statusRefund.amount) return true;
        if (!statusRefund.amount) return true;
        if (!statusRefund.reason) return true;
        if (errors) {
            let has = false;
            for (const error in errors) {
                if (errors[error].length > 0) {
                    has = true;
                }
            }
            return has;
        }
        if (statusRefundValidations && statusRefundValidations.hasRequiredInputs && statusRefundValidations.passTypeValidations) return false;
        return true;
    };

    return (
        <Layout title={"Refund Payment"} backHistory={true} backLabel="Payment" isLoading={layoutLoading}>
            <div className={styles.layout}>
                <div className={styles.layout_content}>
                    <div className={styles.layout_content_widgets}>
                        <ATSWidget
                            type={ATSWidgetType.WARNING}
                            days={`${currencyDollarUS.format(payment.available)}`}
                            content="available for this refund"
                        />
                        <ATSWidget
                            type={ATSWidgetType.DEFAULT}
                            days={daysUntil() >= 1 ? daysUntil() : `${formatDate(payment.startDate)}`}
                            content={subtitleWidgetHelper()}
                            tooltip={
                                <ATSTooltip
                                    body={[
                                        {
                                            text: `/*Fee waived 15 */ + business days before the course`,
                                            alignment: ATSTooltipBodyAlignment.LEFT,
                                        },
                                        { text: `/*50 % 14 - 10*/ business days before the course`, alignment: ATSTooltipBodyAlignment.LEFT },
                                        { text: `/*100 % 09 - 00*/ business days before the course`, alignment: ATSTooltipBodyAlignment.LEFT },
                                    ]}
                                    alignment={ATSTooltipAlignment.TOPRIGHT}
                                />
                            }
                        />
                    </div>

                    <section className="whitebox hasScroll">
                        <div className="whitebox_area has_heading">
                            <div className="whitebox_heading">
                                <i className="icon-school"></i>
                                <h2 className="whitebox_heading_subtitle">Students Details</h2>
                            </div>
                            <div className="whitebox_content">
                                {students && (
                                    <ATSVirtualList
                                        dataSet={students}
                                        onRowClickHandler={() => { }}
                                        noData={false}
                                        resetFilter={() => { }}
                                        entity="students"
                                        ignoreColumns={["id", "statusName"]}
                                        rowHeight={35}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="whitebox_area has_heading">
                            <div className="whitebox_heading">
                                <i className="icon-menu_book"></i>
                                <h2 className="whitebox_heading_subtitle">Course Info</h2>
                            </div>
                            <ul className="whitebox_list inColumns">
                                <ATSInfoItem title="Course" value={payment.courseName} />
                                <ATSInfoItem title="Location" value={payment.location} />
                                <ATSInfoItem
                                    title="Start Date and Time"
                                    value={`${formatDate(payment.startDate)} - ${timeConvert(
                                        getStartTime(payment.startTime, payment.startTimeMinute)
                                    )}`}
                                />
                                <ATSInfoItem title="Seats" value={`x ${payment.slots}`} />
                                <ATSInfoItem title="Price" value={currencyDollarUS.format(payment.price)} />
                            </ul>
                        </div>
                        <div className="whitebox_area has_heading">
                            <div className="whitebox_heading">
                                <i className="icon-payments"></i>
                                <h2 className="whitebox_heading_subtitle">Payment</h2>
                            </div>
                            <ul className="whitebox_list inColumns">
                                <ATSInfoItem title="Token" value={payment.token ? payment.token : "MANUAL ENROLLMENT"} />
                                <ATSInfoItem title="Paid date" value={`${formatDateFull(payment.date)}`} />
                                <ATSInfoItem title="Amount" value={`${currencyDollarUS.format(payment.amount)}`} />
                                <ATSInfoItem title="Dealer" value={payment.dealerName} />
                                <ATSInfoItem title="Method" value={payment.methodName} />
                            </ul>
                        </div>
                    </section>
                </div>
                <div className={`whitebox ${styles.layout_aside}`}>
                    <div className="form_fieldset maxWidth">
                        <ATSRadioInput name="fullRefund" type={ATSRadioType.DEFAULT} options={payment.statusName !== "Partial Refund" ? refundFullOptions : refundOnlyPartialOptions} handleChange={handleChange} />
                        <ATSCurrencyInput
                            name="amount"
                            label=""
                            handleChange={handleChange}
                            disabled={statusRefund?.fullRefund}
                            required
                            error={errors?.amount}
                            setErrorMessage={handleErrorsChange}
                            value={paymentValue}
                            maxLengthProps={11}
                            minValueProps={1}
                            maxValueProps={payment.available}
                        />
                        <ATSTextAreaInput
                            name="reason"
                            label="Reason for refund"
                            handleChange={handleChange}
                            required
                            value={statusRefund?.reason}
                            error={errors?.reason}
                            setErrorMessage={handleErrorsChange}
                            maxLengthProps={1000}
                        />
                        <ATSButton cta="Refund" type={ATSTypeButton.POSITIVE} action={handleSubmit} disabled={disableButton()} />
                    </div>
                </div>
            </div>
            {openModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={["Sorry, your refund was not processed successfully.", errorMessage]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setOpenModal(!openModal)}
                    buttons={[
                        {
                            cta: "Ok",
                            type: ATSTypeButton.DANGER,
                            action: () => setOpenModal(!openModal),
                        },
                    ]}
                />
            )}
        </Layout>
    );
}

export default RefundPayment;
