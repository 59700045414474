import { ReactElement, useState } from "react";
import AuthService from "../../services/authServices/AuthService";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./login.module.scss";
import logo from "../../assets/images/logo.jpg";
import ATSButton, { ATSTypeButton } from "../../components/Shared/ATSButton";
import ATSModal, { ATSTypeModal } from "../../components/Shared/ATSModal";

export default function Login(props: any): ReactElement {
    const { pageData } = props;
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState<string | null>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<any>();

    const [formRegisterValues, setFormRegisterValues] = useState({
        userName: "",
        password: "",
    });

    const loginListModals = {
        loading: {
            tilte: "Loading...",
            type: ATSTypeModal.LOADING,
            bodyText: ["Please wait ..."],
            noClose: true,
        },
    };
    const handleChange = event => {
        const { name, value } = event.target;
        setFormRegisterValues({ ...formRegisterValues, [name]: value });
        setLoginError(null);
    };

    function preLoadingLogin() {
        setModalConfig(loginListModals.loading);
        setToggleModal(true);
    }

    const handleSubmit = async () => {
        preLoadingLogin();
        const formData = { ...formRegisterValues };
        try {
            const response = await AuthService.login({
                userName: formData.userName,
                password: formData.password,
            });
            if (response.success) {
                navigate("/dashboard");
            } else {
                setToggleModal(false);
                console.log(response.message);
                setLoginError(response.message);
            }
        } catch (event) {
            //do something
        }
    };

    return (
        <main className={styles.login_main}>
            <div className={styles.login_box}>
                <header className={styles.login_box_header}>
                    <div className={styles.login_box_identity}>
                        <img src={logo} alt="ats-logo" />
                    </div>
                    <div className={styles.login_box_heading}>
                        <i className="icon-login success"></i>
                        <h1 className={styles.login_box_title}>Log in</h1>
                    </div>
                </header>
                <section className={styles.login_box_content}>
                    <form className={`form ${styles.login_form}`}>
                        <div className="form_item">
                            <label htmlFor="userName">username</label>
                            <input
                                placeholder="Username"
                                onChange={handleChange}
                                name="userName"
                                value={formRegisterValues.userName}
                                type="text"
                                id="userName"
                                className={`${styles.input}`}
                                maxLength={200}
                            />
                        </div>
                        <div className="form_item">
                            <label htmlFor="confirm password">password</label>
                            <input
                                placeholder="Password"
                                onChange={handleChange}
                                name="password"
                                value={formRegisterValues.password}
                                type="password"
                                id="password"
                                className={`${styles.input}`}
                                maxLength={200}
                            />
                        </div>
                        {loginError && (
                            <p className={styles.login_error}>
                                <i className="icon-error"></i>
                                <span>{loginError}</span>
                            </p>
                        )}
                        <Link className={styles.login_link} to="/password/forgot">
                            Forgot your password?
                        </Link>

                        <div className={styles.login_btn_group}>
                            <ATSButton
                                cta="Login"
                                type={ATSTypeButton.POSITIVE}
                                disabled={!formRegisterValues.userName && !formRegisterValues.password ? true : false}
                                action={handleSubmit}
                            />
                        </div>
                    </form>
                    {toggleModal && <ATSModal {...{ ...modalConfig }} />}
                </section>
            </div>
        </main>
    );
}
