import ATSTextInput from "../../../../../components/form/ATSTextInput";
import { copyJSON } from "../../../../../helpers/helpers";

interface ManualProps {
    state: any;
    errors?: any;
    handleInputChange: (name: string, value: string | number, _indexed?: string, _index?: number) => void;
    handleErrorsChange?: (name: string, value: string[], _indexed?: string, _index?: number) => void;
}
function Manual(props: ManualProps) {
    const { handleInputChange, state, errors, handleErrorsChange } = props;

    const manageManual = (name, value) => {
        let mutateState = copyJSON(state.paymentData);
        mutateState = {
            ...mutateState,
            [name]: value,
        };
        handleInputChange("paymentData", mutateState);
    };

    const manageErrorManual = (name, value) => {
        if (!handleErrorsChange) return;
        let mutateState = errors?.manual ? copyJSON(errors.manual) : {};
        mutateState = {
            ...mutateState,
            [name]: value,
        };
        handleErrorsChange("manual", mutateState);
    };

    return (
        <fieldset className="form_fieldset maxWidth">
            <ATSTextInput
                name={"title"}
                label={`Method`}
                value={state.paymentData.title}
                handleChange={manageManual}
                error={errors && errors.manual && errors.manual.title ? errors.manual.title : []}
                setErrorMessage={manageErrorManual}
                maxLengthProps={50}
                required
            />
            <ATSTextInput
                name={"comment"}
                label={`Comment`}
                value={state.paymentData.comment}
                handleChange={manageManual}
                error={errors && errors.manual && errors.manual.comment ? errors.manual.comment : []}
                setErrorMessage={manageErrorManual}
                maxLengthProps={200}
                required
            />
        </fieldset>
    );
}

export default Manual;
