import styles from "./ATSProgressBar.module.scss";

interface IATSProgressBarProps {
    entity: string;
    current: number;
    total: number;
}

/**
 * Set new ATSProgressBar component.
 * @param entity: string;
 * @param current: number; -> actual quantity
 * @param total: number; -> total (100%)
 */

export function ATSProgressBar(props: IATSProgressBarProps) {
    let { current, total, entity } = props;

    const percentage: string = ((current * 100) / total).toFixed();

    return (
        <div className={styles.progressBar}>
            <h2 className={styles.progressBar_title}> {`${entity} at ${percentage}%`} </h2>
            <div className={styles.progressBar_tracker}>
                <div className={styles.progressBar_track} style={{ ["--percentage" as string]: `${percentage}%` }} />
            </div>

            <p className={styles.progressBar_info}>
                {current} of {total} seats used
            </p>
        </div>
    );
}
