import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { IRefundPaymentRequest, IRefundPaymentResponse } from "../interfaces/servicePostBody/payment/IPaymentRefund";
import { IPaymentUpdateCommentRequest } from "../interfaces/servicePostBody/payment/IPaymentUpdateComment";
import { IPaymentResponse } from "../interfaces/serviceResponses/payment/IPaymentResponse";
import { IPaymentStatusResponse } from "../interfaces/serviceResponses/payment/IPaymentStatusResponse";
import { IPaymentUpdateCommentResponse } from "../interfaces/serviceResponses/payment/IPaymentUpdateCommentResponse";
import { ISinglePaymentResponse } from "../interfaces/serviceResponses/payment/ISinglePaymentResponse";

class PaymentService extends ApiBaseService {
    public static async getPayments(limit, offset): Promise<IResponse<IPaymentResponse>> {
        const response = await this.securedPost<IPaymentResponse>(`payment`, { limit, offset });
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getFilteredPayments(filtersApplied): Promise<IResponse<IPaymentResponse>> {
        const response = await this.securedPost<IPaymentResponse>(`payment/filtered`, filtersApplied);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getPayment(id): Promise<IResponse<ISinglePaymentResponse>> {
        const response = await this.securedGet<ISinglePaymentResponse>(`payment/single/${id}`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async getPaymentStatus(): Promise<IResponse<IPaymentStatusResponse>> {
        const response = await this.securedGet<IPaymentStatusResponse>(`payment-status`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async updatePaymentComment(body: IPaymentUpdateCommentRequest): Promise<IResponse<IPaymentUpdateCommentResponse>> {
        const response = await this.securedPost<IPaymentUpdateCommentResponse>(`payment/comments`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async refundPayment(body: IRefundPaymentRequest): Promise<IResponse<IRefundPaymentResponse>> {
        const response = await this.securedPost<IRefundPaymentResponse>(`payment/refund`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }
    /*
    public static async refundStudent(body: IRefundStudentRequest): Promise<IResponse<IRefundPaymentResponse>> {
        const response = await this.securedPost<IRefundStudentRequest>(`payment/refund`, body);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }
    */
}

export default PaymentService;
