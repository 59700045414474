import { FormEvent, useState, KeyboardEvent, useEffect } from "react";
import { currencyDollarUS, stringCounter } from "../../../helpers/helpers";
import ATSErrorMsg from "../ATSErrorMsg";
import ATSWarningMsg from "../ATSWarningMsg";
import styles from "./ATSCurrencyInput.module.scss";
interface IATSCurrencyInput {
    name: string;
    label: string;
    required?: boolean;
    error?: Array<string>;
    disabled?: boolean;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange: (name: string, value: number) => void;
    value?: number;
    maxLengthProps?: number;
    minValueProps?: number;
    maxValueProps?: number;
    allowZero?: boolean;
}

/**
 * Create custom currency input
 * @param name string ;
 * @param label string ;
 * @param handleChange ( )=> void -> setState function that recieves a name and value;
 * @param required boolean ;
 * @param error Array<string>;
 * @param disabled boolean ;
 * @param maxLengthProps number -> optional
 * @param setErrorMessage ( )=> void -> setState function that recieves a name and value;
 * If the user sets a number with more than one period(.), the inputs should display a warning notice to the user
 * If the user can write a number with ',' and '.' separators, the input will be formatted in currency of type USD only for the user's view removing it by relocating the misplaced ',' separators
 * The value returned in handleChange is a number with a decimal point
 */
function ATSCurrencyInput(props: IATSCurrencyInput) {
    const { label, name, handleChange, required, error, setErrorMessage, disabled, value, maxLengthProps, minValueProps, maxValueProps, allowZero } = props;
    const [inputValue, setinputValue] = useState<string>(`$0.00`);
    const [warningMessage, setWarningMessage] = useState<string | null>();
    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    useEffect(() => {
        if (value) {
            setinputValue(currencyDollarUS.format(value ?? 0));
        }
    }, [value]);

    const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
        setErrorMessage && setErrorMessage(name, []);
        setWarningMessage(null);
        setinputValue(e.currentTarget.value);
    };

    const handleBlurInput = () => {
        //remove from value $ and , digits
        const modifiedValue = inputValue.replaceAll(",", "").replace("$", "");

        //count letters
        const countDots = stringCounter(modifiedValue);
        if (countDots["."] > 1)
            setErrorMessage &&
                setErrorMessage(name, [`The currency value can't contain more than one decimal separator. Please remove the extra dots.`]);

        //if the value isn't a number, set value in 0 and return without execute
        if (modifiedValue === "" || (!allowZero && Number(modifiedValue) < 0.01) || (allowZero && Number(modifiedValue) < 0)) {
            setinputValue(dollarUS.format(Number(0)));
            if (required) {
                setErrorMessage &&
                    setErrorMessage(name, [`This field is mandatory and you did not provide a value. Please set a value greater than${allowZero ? ' or equal to ' : ' '} 0`]);
                setinputValue(`${dollarUS.format(Number(modifiedValue))}`);
                handleChange(name, Number(modifiedValue));
            }
            return;
        }
        if (minValueProps && Number(modifiedValue) <= minValueProps) {
            setErrorMessage && setErrorMessage(name, [`This value can't be smaller than ${currencyDollarUS.format(minValueProps)}`]);
        }
        if (maxValueProps && Number(modifiedValue) > maxValueProps) {
            setErrorMessage && setErrorMessage(name, [`This value can't be greater than ${currencyDollarUS.format(maxValueProps)}`]);
        } else {
            if (Number(modifiedValue) >= 0) setinputValue(`${dollarUS.format(Number(modifiedValue))}`);
        }

        handleChange(name, Number(modifiedValue));
    };

    return (
        <div className={`form_item ${styles.wrapper} ${error && error?.length > 0 ? "danger" : ""}`}>
            <label className="form_label">{label}</label>
            <div className={styles.wrapper_input}>
                <input
                    type="text"
                    value={inputValue}
                    name={name}
                    onChange={e => handleInputChange(e)}
                    onBlur={handleBlurInput}
                    onKeyPress={(e: KeyboardEvent) => {
                        if (!/^(0|[1-9]|\.|\,\d*)/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    className={`${disabled ? styles.disabled : ""}`}
                    maxLength={maxLengthProps ?? 11}
                />
            </div>
            {error && <ATSErrorMsg errors={error} />}
            {warningMessage && <ATSWarningMsg warnings={[`${warningMessage}`]} setWarnings={setWarningMessage} />}
        </div>
    );
}

export default ATSCurrencyInput;
