import React from "react";
import { useEffect, useState } from "react";
import ATSSelect, { SelectOption } from "../../form/ATSSelect";
import ATSButton, { ATSTypeButton } from "../ATSButton";
import ATSDatePicker from "../../form/ATSDatePicker";
import ATSTextInput from "../../form/ATSTextInput";

export interface IATSFilterConstructor {
    type: string;
    columns?: string;
    placeholder: string;
    options?: SelectOption;
    key?: number;
}
interface IATSFilterProps {
    filtersApplied: any;
    handleFilter: (filtersNeeded) => void;
    handleClearFilter: () => void;
    filter?: any;
    resetValues?: boolean;
    errorDates?: Array<string>;
    setErrorMessage?: (name: string, value: string[]) => void;
}
export enum ATSFilterType {
    TEXT = "text",
    SELECT = "select",
    DATEPICKER = "datePicker",
}
export enum ATSSearchColumns {
    COURSENAME = "courseName",
    LOCATION = "location",
    COURSESTATUSNAME = "courseStatusName",
    STARTDATE = "startDate",
    DATE = "date",
    STUDENTNAME = "studentName",
    DEALERNAME = "dealerName",
}

/**
 * Set new ATS Filter component, a example-usage file was provided. See the component props for more information .
 * @param filtersApplied: IATSFilterConstructor[ ];
 * @param handleFilter: (filtersNeeded) => void;
 */

const ATSFilter = (props: IATSFilterProps) => {
    const [filtersUsed, setFiltersUsed] = useState<any>({});
    const [inputFilters, setInputFilters] = useState<any>();
    const [errorDatesState, setErrorDatesState] = useState<any>();

    useEffect(() => {
        let filters = {};
        props.filtersApplied.forEach(filter => {
            if (filter.type === "text") {
                filters[filter.column || filter.name] = filter.value;
            }
        });
        setInputFilters(filters);
    }, []);

    useEffect(() => {
        if (props.resetValues) {
            setFiltersUsed({});
            clearInputs();
        }
    }, [props.resetValues]);

    useEffect(() => {
        setErrorDatesState(props.errorDates);
    }, [props.errorDates]);

    function clearInputs() {
        const filters = { ...inputFilters };
        for (let index in filters) {
            filters[index] = "";
        }
        setInputFilters(filters);
    }

    function handleClearFilter() {
        clearInputs();
        props.handleClearFilter();
    }

    const handleInputChange = (name: string, value: string | number): void => {
        setFiltersUsed(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleTextInputChange = (name: string, value: string | number): void => {
        const filters = { ...inputFilters };
        filters[name] = value;
        setInputFilters(filters);
        setFiltersUsed(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const filterComponents = {
        text: function handler(props: any, data: any, index: number) {
            return (
                <>
                    <ATSTextInput
                        key={index}
                        name={props.column || props.name}
                        value={inputFilters ? inputFilters[props.column || props.name] : ""}
                        placeholder={props.placeholder}
                        handleChange={handleTextInputChange}
                        resetValues={data.resetValues}
                        maxLengthProps={100}
                    />
                </>
            );
        },
        select: function handler(props: any, data: any, index: number) {
            return (
                <ATSSelect
                    key={index}
                    name={props.name}
                    label={""}
                    value={props.value}
                    options={props.options}
                    placeholder={props.placeholder}
                    handleChange={handleInputChange}
                    resetValues={data.resetValues}
                />
            );
        },
        datePicker: function handler(props: any, data: any, index: number) {
            return (
                <ATSDatePicker
                    key={index}
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    handleChange={handleInputChange}
                    resetValues={data.resetValues}
                    error={errorDatesState}
                />
            );
        },
    };

    function filterHandler(filter, props, index) {
        return filterComponents[filter.type](filter, props, index);
    }

    return (
        <>
            {props.filtersApplied &&
                props.filtersApplied.length > 0 &&
                props.filtersApplied.map((filter, index) => {
                    return <React.Fragment key={index}>{filterHandler(filter, props, index)}</React.Fragment>;
                })}
            <ATSButton cta="" iconClass="icon-search" type={ATSTypeButton.POSITIVE} action={() => props.handleFilter(filtersUsed)} />
            <ATSButton cta="" iconClass="icon-cancel" type={ATSTypeButton.OUTTHIN} action={handleClearFilter} />
        </>
    );
};
export default ATSFilter;
