import ApiBaseService, { IResponse, IResponseForm } from "../apiServices/ApiBaseService";
import { IChangePasswordRequest } from "../interfaces/servicePostBody/user/IChangePasswordRequest";
import { IEditUserProfileRequest } from "../interfaces/servicePostBody/user/IEditUserProfileRequest";
import { IResetPasswordConfirmRequest } from "../interfaces/servicePostBody/user/IResetPasswordConfirmRequest";
import { IResetPasswordRequest } from "../interfaces/servicePostBody/user/IResetPasswordRequest";
import { IChangePasswordResponse } from "../interfaces/serviceResponses/user/IChangePasswordResponse";
import { IEditUserProfileResponse } from "../interfaces/serviceResponses/user/IEditUserProfileResponse";
import { IResetPasswordConfirmResponse } from "../interfaces/serviceResponses/user/IResetPasswordConfirmResponse";
import { IResetPasswordResponse } from "../interfaces/serviceResponses/user/IResetPasswordRequest";
import { IUserProfileResponse } from "../interfaces/serviceResponses/user/IUserProfileResponse";

export class UserService extends ApiBaseService {
    public static async getUserProfile(): Promise<IResponse<IUserProfileResponse>> {
        const response = await this.securedGet<IUserProfileResponse>(`user/profile`);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async editUserProfile(body: IEditUserProfileRequest): Promise<IResponseForm<IEditUserProfileResponse>> {
        const response = await this.securedPost<IUserProfileResponse>(`user/profile`, body);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async resetPassword(body: IResetPasswordRequest): Promise<IResponse<IResetPasswordResponse>> {
        const response = await this.securedPost<IResetPasswordResponse>(`user/resetPassword`, body);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async confirmResetPassword(body: IResetPasswordConfirmRequest): Promise<IResponse<IResetPasswordConfirmResponse>> {
        const response = await this.securedPost<IResetPasswordConfirmResponse>(`user/confirmResetPassword`, body);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }

    public static async changePassword(body: IChangePasswordRequest): Promise<IResponse<IChangePasswordResponse>> {
        const response = await this.securedPost<IResetPasswordConfirmResponse>(`user/changePassword`, body);
        if (response != null && response.success && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }
}
export default UserService;
