import { useState } from 'react';
import ATSBadge, { ATSTypeBadge } from '../ATSBadge';
import ATSRequestsStatusCard, { IATSRequestsStatusCardProps } from './ATSRequestsStatusCard';
import styles from './ATSRequestsStatusModule.module.scss';

interface IATSRequestsStatusProps {
    studentStatusCounts: Array<IATSRequestsStatusCardProps>
    collapse?: boolean;
}

/**
 * Set new ATS Requests Status component. This is a parent component that also contains ATS requests Status Card and need the status count array.
 * @param studentStatusCounts: Array<IATSRequestsStatusCardProps>;
 * @param collapse: boolean -> optional;
 */
function ATSRequestsStatus(props: IATSRequestsStatusProps) {
    const { studentStatusCounts, collapse } = props
    const [collapsed, setCollapsed] = useState<boolean>(false)
    return (
        <section className={`${styles.status_container} ${collapsed && styles.collapsed}`}>
            <h3 className={styles.status_title}>
                Requests 
                <ATSBadge type={ATSTypeBadge.DEFAULT} content={'Last 30 days'} />
                {collapse && <button onClick={() => setCollapsed(!collapsed)}>
                    <i className='icon-expand_more'></i>
                </button>}
            </h3>
            <div className={styles.status_content}>
                {
                    studentStatusCounts.map((request, i) => <ATSRequestsStatusCard key={i + request.status} status={request.status} quantity={request.quantity} id={request.id} />)
                }
            </div>
        </section>
    )
}

export default ATSRequestsStatus